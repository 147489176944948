import { query } from "@angular/animations";
import { isEmpty } from "lodash-es";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
  HttpParams,
} from "@angular/common/http";
import {
  Patient,
  InitialAssessment,
  AssignToDoctor,
  PinInfo,
} from "../models/patient";
import { PatientList } from "../models/patient-list";
import { Observable, throwError, pipe } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as fromUserReducer from "src/app/store/reducers/user/index";
import { Store, select } from "@ngrx/store";
import {
  SearchPatients,
  SearchPatientsPagination,
} from "../models/search.model";
import { BedData, PayorData } from "../models/hospital";
import { User } from "../models/user";
import { ApiResponse } from "../models/api-response.model";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

export enum Airway {
  INT = "isIntubated",
  Trach = "isTrach",
  HFNC = "isHFNC",
  NIV = "isNIV",
}

@Injectable({
  providedIn: "root",
})
export class PatientService {
  env = environment;
  public user;

  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));

  constructor(
    private http: HttpClient,
    private store: Store<{}>,
    private _route: ActivatedRoute
  ) {
    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
      }
    });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getAllPatients(email): Observable<PatientList> {
    return this.http
      .get<PatientList>(this.env.apiUrl + "patients/" + email)
      .pipe(catchError(this.errorHandler));
  }

  getAllAssignee(): Observable<any> {
    return this.http
      .get<any>(this.env.apiUrl + "users/physicians_to_assign_patients")
      .pipe(catchError(this.errorHandler));
  }

  submitAssignedPatientList(assigneeInfo: AssignToDoctor) {
    return this.http
      .patch<any>(
        this.env.apiUrl + "patients/assign_user",
        assigneeInfo,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  patientAssignToMe(patientCPMRNs: string[]) {
    return this.http
      .patch<any>(
        this.env.apiUrl + "patients/assign_to_me",
        { patientCPMRNs },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  submitUserPinnedPatientList(patientPinInfo: PinInfo[]) {
    return this.http
      .patch<any>(
        this.env.apiUrl + "patients/pin_unpin",
        { patients: patientPinInfo },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getDischargedPatientsByDate(
    from,
    to,
    page = 0,
    size = 60
  ): Observable<PatientList> {
    return this.http
      .get<PatientList>(
        this.env.apiUrl +
          `patients/discharged/${from}/${to}?page=${page}&size=${size}`
      )
      .pipe(catchError(this.errorHandler));
  }

  getAllDischargedPatients(query?: {
    hospitalName?: string[];
    covid?: string[];
  }): Observable<PatientList> {
    let params: { hospitalName?: string[]; covid?: string[] } = {};

    if (query && query.hospitalName) params.hospitalName = query.hospitalName;
    if (query && query.covid) params.covid = query.covid;

    return this.http
      .get<PatientList>(`${this.env.apiUrl}patients/discharged`, { params })
      .pipe(catchError(this.errorHandler));
  }

  getPatientChartData(snapshot: ActivatedRouteSnapshot, isFamilyUser = false) {
    const { paramMap } = snapshot;
    if (isFamilyUser) {
      const familyId = paramMap.get("familyId");
      return this.getFamilyPatient(familyId);
    } else {
      const CPMRN = paramMap.get("patientCPMRN");
      const encounters = paramMap.get("patientEncounters");
      return this.getPatient(CPMRN, encounters);
    }
  }

  getFamilyPatient(familyId): Observable<Patient> {
    let api = `${this.env.apiUrl}family/fetchFamilyPatient`;
    return this.http
      .post<ApiResponse>(api, {
        familyId: familyId,
        email: this.user?.email,
        phone: this.user?.phone,
      })
      .map((res: ApiResponse) => res.data)
      .pipe(catchError(this.errorHandler));
  }

  getPatient(CPMRN, encounters, isHeader = false): Observable<Patient> {
    let api = `${this.env.apiUrl}patients/${CPMRN}/${encounters}`;
    if (isHeader) {
      api += `?isHeader=true`;
    }

    return this.http.get<Patient>(api).pipe(catchError(this.errorHandler));
  }

  createPatient(newPatient) {
    return this.http
      .post<Patient>(
        this.env.apiUrl + "patients/",
        newPatient,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  editPatient(CPMRN, encounters, editProps) {
    return this.http
      .patch<Patient>(
        this.env.apiUrl + "patients/" + CPMRN + "/" + encounters,
        { patient: editProps },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  updateInitialAssessment(CPMRN, encounters, body) {
    return this.http
      .patch<InitialAssessment>(
        this.env.apiUrl +
          "patients/" +
          CPMRN +
          "/" +
          encounters +
          "/initial-assessment",
        { initialAssessment: body },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  updateSeverityLevel(patient): Observable<any> {
    return this.http
      .put<Patient>(
        this.env.apiUrl + "patients/update/severity",
        patient,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  searchPatient({
    searchTerm = "",
    page = 0,
    size = 8,
    sortBy = "",
    orderBy = "",
  } = {}): Observable<any> {
    const pageString = page?.toString() || null,
      sizeString = size?.toString() || null;
    return this.http
      .get<SearchPatientsPagination>(
        `${this.env.apiUrl}patients/search/${encodeURIComponent(searchTerm)}`,
        {
          params: {
            page: pageString,
            size: sizeString,
            sortBy,
            orderBy,
          },
          headers: this.httpOptions.headers,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  searchPatientByDateAndHospitalName({
    from = null,
    to = null,
    hospitalsAndUnits = [],
    content = "",
    page = 0,
    size = 60,
    sortBy = null,
    orderBy = null,
  } = {}): Observable<any> {
    return this.http
      .post<SearchPatientsPagination>(
        this.env.apiUrl + "patients/search/discharge",
        {
          from,
          to,
          page,
          size,
          content,
          hospitalsAndUnits,
          sortBy,
          orderBy,
        },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getPatientSummary(obj) {
    return this.http
      .get(
        this.env.apiUrl +
          "patients/" +
          "get_summary/" +
          obj.cpmrn +
          "/" +
          obj.encounters
      )
      .pipe(catchError(this.errorHandler));
  }

  getHospitalBilling(hospital): Observable<any> {
    return this.http
      .get(
        this.env.apiUrl +
          "patients/" +
          "getHospitalBilling/" +
          hospital.name.replace(/ /g, "_") +
          "/" +
          hospital.start +
          "/" +
          hospital.end
      )
      .pipe(catchError(this.errorHandler));
  }

  checkIfPatientExist(
    hospitalName: string,
    mrn: string,
    cpmrn?: string
  ): Observable<boolean> {
    return this.http
      .get<any>(
        `${this.env.apiUrl}patients/check/${hospitalName}/${encodeURIComponent(
          mrn
        )}`,
        {
          params: {
            cpmrn: cpmrn || "",
          },
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        catchError(this.errorHandler),
        map((data) => data.data)
      );
  }

  checkIfBedNoExist(
    hospitalName: string,
    unitName: string,
    bedNo: string
  ): Promise<any> {
    return this.http
      .get<any>(
        `${this.env.apiUrl}patients/check/${hospitalName}/${unitName}/${bedNo}`,
        {
          headers: this.httpOptions.headers,
        }
      )
      .toPromise();
    // .pipe(
    //   catchError(this.errorHandler),
    //   map(data => data.data)
    // )
  }

  getBeds(hospitalID: string, unitID: string) {
    return this.http
      .get<BedData>(`${this.env.apiUrl}patients/${hospitalID}/${unitID}/beds`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  getPayors() {
    return this.http
      .get<ApiResponse<PayorData[]>>(`${this.env.apiUrl}v1/patients/payors`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  getPrimaryMD(hospitalID: string, CPMRN, encounters) {
    return this.http
      .get<BedData>(`${this.env.apiUrl}patients/getPrimaryMD/${hospitalID}`, {
        params: {
          CPMRN,
          encounters,
        },
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.errorHandler));
  }

  readmitPatient(val) {
    let dataToSend = {
      CPMRN: val.CPMRN,
      encounters: val.encounters,
      data: val.formVal,
    };

    return this.http
      .put(
        this.env.apiUrl +
          "patients/" +
          dataToSend.CPMRN +
          "/" +
          dataToSend.encounters +
          "/readmit",
        dataToSend
      )
      .pipe(catchError(this.errorHandler));
  }

  getPatientObj(CPMRN, encounters) {
    return this.http
      .get<Patient>(this.env.apiUrl + "patients/" + CPMRN + "/" + encounters)
      .pipe(catchError(this.errorHandler));
  }

  /*
   * NAME: convertWeight
   * PURPOSE: Converts weight from (kg to lb) or (lb to kg)
   * DESCRIPTION:
   * PARAMS:
   *   - convertTo:string - weight unit (kg or lb)
   *   - weight:number - patient weight
   * RETURNS: number - converted weight
   * USED BY: many functions
   * CREATED DATE: 4 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  convertWeight(convertTo: string, weight: number): number {
    if (convertTo === "kg") {
      return weight * 2.2046;
    } else {
      return weight / 2.2046;
    }
  }

  addHours(currentdate: Date, h: number): Date {
    let date = new Date(currentdate);
    date.setTime(date.getTime() + h * 60 * 60 * 1000);
    return date;
  }

  checkIfDischargeTimeElapsed(patient: Patient | any): boolean {
    let elapsed = false;

    if (
      patient &&
      patient.isCurrentlyAdmitted != null &&
      patient.isCurrentlyAdmitted == false
    ) {
      const addSixHour = this.addHours(patient.ICUDischargeDate, 6);
      const addSevenDays = this.addHours(patient.ICUDischargeDate, 168);

      if (
        (!isEmpty(patient.covid) &&
          ["suspected", "positive"].includes(patient.covid)) ||
        (!isEmpty(patient.isolation?.precautionType) &&
          !isEmpty(patient.isolation?.reason) &&
          patient.isolation.precautionType === "Droplet" &&
          patient.isolation.reason.includes("COVID-19"))
      ) {
        if (new Date() > addSevenDays) elapsed = true;
      } else if (new Date() > addSixHour) {
        elapsed = true;
      }
    }
    return elapsed;
  }

  displayName(patient: Patient | any, limitChar: boolean = true): string {
    if (patient && (patient.name || patient.lastName)) {
      let name = patient.lastName
        ? `${patient.name} ${patient.lastName}`
        : patient.name;
      name = name.trim();

      // limitChar is used as flag to trim the length by 30 characters and add two dots at the end
      return name && name.length > 30 && limitChar
        ? name.substr(0, 30) + ".."
        : name;
    }
  }

  // filter Patients based on the form input

  filterPatients(formData, patientData) {
    let value = patientData.filter((data) => {
      if (
        !(
          (this.filterData(data?.name, formData?.name) ||
            this.filterData(data?.lastName, formData?.name) ||
            this.filterData(
              data?.name + " " + data?.lastName,
              formData?.name
            ) ||
            this.filterData(data?.name + data?.lastName, formData?.name)) && // temporary fix
          this.filterData(data?.CPMRN, formData?.cpmrn) &&
          this.isAirway(formData?.airway, data) &&
          this.filterData(data?.isolation?.reason, formData?.covidStatus) &&
          this.filterDataMatch(data?.severity, formData?.severity)
        )
      ) {
        return false;
      } else return true;
    });
    return value;
  }

  filterData(data, formValue) {
    let returnBool = false;
    if (formValue) {
      if (data) {
        if (data.toLowerCase().includes(formValue.toLowerCase())) {
          returnBool = true;
        } else returnBool = false;
      } else returnBool = false;
    } else returnBool = true;

    return returnBool;
  }

  filterDataMatch(data, formValue) {
    let returnBool = false;
    if (formValue) {
      if (data) {
        if (data.toLowerCase() === formValue.toLowerCase()) {
          returnBool = true;
        } else returnBool = false;
      } else returnBool = false;
    } else returnBool = true;

    return returnBool;
  }

  isAirway(airway, patientData) {
    let returnBool = false;
    let chk = [];
    if (airway && airway.length > 0) {
      airway.forEach((element) => {
        if (element) {
          if (patientData[element] && patientData[element].value)
            returnBool = true;
        }
      });
    } else returnBool = true;
    return returnBool;
  }
}
