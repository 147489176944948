import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./reactStore/reduxStore";
import ConfirmationModal from "./sharedComponents/ConfirmationModal";
import useApi from "./reactStore/Hooks/useApi";
import SearchDropdown from "./sharedComponents/SearchDropdown";
import { fetchUsers } from "./reactStore/reactSlice/usersSlice";
import { fetchTasks, appendTask, updateAssignee, updateState, selectTasks, updateTask } from './reactStore/reactSlice/taskSlice';
import Tooltip from "./sharedComponents/Tooltip";
import { socket } from "src/app/config/socket.js";
import AlertModal from "./sharedComponents/AlertModal";

type TaskData = {
  Task: string;
  Priority: string;
  "Task Type": string;
  "Patient Name": string;
  Patient: string;
  "Patient Severity": string;
  "Bed no"?: string;
  "Hospital-unit"?: string;
  "Created-At"?: string;
  "Due-Date": string;
  Assignee: string;
  AssigneeName: string,
  Creator?: string;
  State?: string;
}

interface TaskListPopUpProps {
  onClose: () => void;
  patientHeader$?: any;
}

const TaskListPopUp: React.FC<TaskListPopUpProps> = ({ onClose, patientHeader$ }) => {
  const dispatch = useDispatch<AppDispatch>();
  const tasks: any = useSelector(selectTasks);
  const [isOpen, setIsOpen] = useState(true);
  const [showAddTaskDetails, setShowAddTaskDetails] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { apiFn } = useApi();
  const [newTaskData, setNewTaskData] = useState<TaskData>({
    Task: "",
    Priority: "",
    "Task Type": "",
    "Patient Name": "",
    Patient: "",
    "Bed no": "",
    "Patient Severity": "",
    "Hospital-unit": "",
    "Created-At": "",
    "Due-Date": "",
    Assignee: "",
    AssigneeName: "",
    Creator: "",
  });

  const [validationTask, setValidationTask] = useState({
    Task: false,
    Priority: false,
    "Task Type": false,
    "Due-Date": false,
    AssigneeName: false,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [editing, setEditing] = useState<{
    taskId: string;
    columnName?: string;
    task: string;
    priority: string;
    type: string;
    assignee: string;
    dueDate: string;
    state: string;
  }>({
    taskId: "",
    task: "",
    priority: "",
    type: "",
    assignee: "",
    dueDate: "",
    state: "",
  });
  const [isEditingAssignee, setIsEditingAssignee] = useState(false)
  const [isEditingDueDate, setIsEditingDueDate] = useState(false)
  const [dueDate, setDueDate] = useState(newTaskData["Due-Date"] || "");
  const [isAssignee, setIsAssignee] = useState(true)
  const [isDueDate, setIsDueDate] = useState(true)

  const users = useSelector((state: any) => state.users.data);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = e.target.value;
    setNewTaskData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setValidationTask((prevValidation) => ({
      ...prevValidation,
      [fieldName]: false,
    }));
  };

  const handleEditClick = (taskData) => {
    setEditing({
      taskId: taskData._id,
      task: taskData.title,
      priority: taskData.priority,
      type: taskData.type,
      assignee: taskData.assignee?.name || "",
      dueDate: taskData.dueDate || "",
      state: taskData.state || "",
    });

    setIsEditing(true);
  };

  useEffect(() => {
    const payload = {
      page: 0,
      count: 1000,
      sortBy: 'state',
      orderBy: 'desc'
    };

    dispatch(fetchTasks(payload));
  }, []);

  useEffect(() => {
    socket.on('taskList-create', (data) => {
      dispatch(appendTask(data));
    });

    socket.on('taskList-change-assignee', (data) => {
      const { _id, assignee } = data;
      dispatch(updateAssignee({ _id, assignee }));
    });

    socket.on('taskList-complete', (data) => {
      const { _id } = data;
      dispatch(updateState({ _id }));
    });

    socket.on('task-list-update', (data) => {
      const { _id, ...updatedFields } = data;
      dispatch(updateTask({ _id, ...updatedFields }));
    });

    return () => {
      socket.off('taskList-create');
      socket.off('taskList-change-assignee');
      socket.off('taskList-complete');
      socket.off('task-list-update');
    };
  }, [dispatch, socket]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [newTaskData]);

  const togglePopup = () => setIsOpen(!isOpen);
  const handleAddTaskClick = () => {
    setShowAddTaskDetails(!showAddTaskDetails);
  };

  useEffect(() => {
    dispatch(fetchUsers(''));
  }, [dispatch]);

  const handleClose = () => onClose();
  const toggleTasksVisibility = () => setShowTasks(!showTasks);

  const priorityTypes = [
    { label: "High", value: "high" },
    { label: "Low", value: "low" },
  ];

  const taskTypes = [
    { label: "Follow up", value: "followUp" },
    { label: "Admission", value: "admission" },
    { label: "Round", value: "rounds" },
    { label: "Others", value: "other" },
  ];


  const handleRadioClick = (task: any) => {
    const storedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const currentUserEmail = storedUser?.email?.trim().toLowerCase();

    if (task && task._id && task.assignee && task.assignee._id) {
      const taskAssigneeEmail = task.assignee.email?.trim().toLowerCase();

      if (task.state === "completed") {
        setModalTitle("This task is already marked as completed.");
        setIsAlertModalOpen(true);
        return;
      }

      if (currentUserEmail !== taskAssigneeEmail) {
        setModalTitle("Only assignee can mark the task as complete");
        setIsAlertModalOpen(true);
        return;
      }

      setSelectedTask(task);
      setConfirmModalOpen(true);
    } else {
      console.error("Invalid task data:", task);
    }
  };

  const handleConfirm = async () => {
    if (selectedTask && selectedTask._id && selectedTask.assignee) {
      await handleCompleteTask(selectedTask);
    } else {
      console.error("Selected task data is invalid:", selectedTask);
    }
    setConfirmModalOpen(false);
  };

  const handleAssigneeClick = () => {
    setIsEditingAssignee(true);
    setIsEditing(false);
  };

  const handleAssigneeChange = async (selectedAssignee) => {
    setIsEditingAssignee(false);
    try {
      const payload = { assignee: selectedAssignee._id };
      const { response, error } = await apiFn({
        url: `v1/tasks/${editing.taskId}/reassign`,
        options: {
          method: "PATCH",
          body: payload,
        },
      });

      if (error) {
        console.error("Error updating assignee:", error);
        return;
      }

      setEditing((prev) => ({
        ...prev,
        assignee: selectedAssignee.name,
      }));
    } catch (err) {
      console.error("Error updating task:", err);
    }
    setIsEditing(false)
  };

  const handlePrioritySelect = async (priority: string) => {
    setEditing((prevState) => ({
      ...prevState,
      priority,
    }));

    try {
      await handleSubmit({
        ...editing,
        priority,
      });
    } catch (error) {
      console.error("Error updating priority:", error);
    }
    setIsEditing(false);
  };

  const handleTaskTypeSelect = async (type: string) => {
    setEditing((prevState) => ({
      ...prevState,
      type,
    }));

    try {
      await handleSubmit({
        ...editing,
        type,
      });
    } catch (error) {
      console.error("Error updating task type:", error);
    }
    setIsEditing(false);
  };

  const handleDueDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDueDate = e.target.value;
    setDueDate(updatedDueDate);
    handleChange(e, "Due-Date");
    const updatedTask = {
      ...editing,
      dueDate: updatedDueDate,
    };

    try {
      await handleSubmit(updatedTask);
    } catch (error) {
      console.error("Error updating due date:", error);
    }
    setIsEditing(false)
  };

  const handleTaskAssigneeSelect = (assignee) => {
    setNewTaskData((prevData) => ({
      ...prevData,
      Assignee: assignee._id,
      AssigneeName: assignee.name,
    }));
    setValidationTask((prevValidation) => ({
      ...prevValidation,
      AssigneeName: false,
    }));
    setIsAssignee(false)
  };

  const handleCompleteTask = async (taskdata: { _id: string; assignee: { _id: string; email: string }; state: string }) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
      const currentUserEmail = storedUser?.email?.trim().toLowerCase();

      const taskAssigneeEmail = taskdata?.assignee?.email?.trim().toLowerCase();

      if (currentUserEmail !== taskAssigneeEmail) {
        alert("Only the assignee can complete the task.");
        return;
      }

      if (taskdata.state === "completed") {
        alert("This task is already marked as completed.");
        return;
      }

      const taskId = taskdata._id;
      const assigneeId = taskdata.assignee._id;

      const payload = {
        assignee: assigneeId,
      };

      const { response, error } = await apiFn({
        url: `v1/tasks/${taskId}/complete`,
        options: {
          method: "PATCH",
          body: payload,
        },
      });

      if (error) {
        console.error("Error completing task:", error);
      }
    } catch (err) {
      console.error("Error completing task:", err);
    }
  };


  const handleSubmit = async (updatedTask: typeof editing) => {
    try {
      const payload: any = {};

      if (updatedTask.task !== editing.task) {
        payload.title = updatedTask.task.trim();
      }
      if (updatedTask.priority !== editing.priority) {
        payload.priority = updatedTask.priority;
      }
      if (updatedTask.type !== editing.type) {
        payload.type = updatedTask.type;
      }
      if (updatedTask.dueDate !== editing.dueDate) {
        payload["Due-Date"] = updatedTask.dueDate;
      }

      const { response, error } = await apiFn({
        url: `v1/tasks/${updatedTask.taskId}`,
        options: {
          method: "PATCH",
          body: payload,
        },
      });

      if (error) {
        console.error("Error submitting task update:", error);
        return;
      }

    } catch (error) {
      console.error("Error submitting task update:", error);
    }
  };

  let debounceTimer: NodeJS.Timeout;

  const handleSave = async () => {
    if (!editing.taskId || !editing.columnName || !editing.task) return;
    const updatedTask = { ...editing };
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSubmit(updatedTask);
    }, 3000);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      let isValid = true;
      const requiredFields = [
        "Task",
        "Priority",
        "Task Type",
        "Due-Date",
        "AssigneeName",
      ];

      requiredFields.forEach((field) => {
        if (!newTaskData[field] || newTaskData[field].trim() === "") {
          isValid = false;
          setValidationTask((prev) => ({
            ...prev,
            [field]: true,
          }));
        }
      });

      if (!isValid) {
        return;
      }
      handleCreateTask();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [newTaskData]);

  const handleCreateTask = async () => {
    try {
      const payload = {
        title: newTaskData.Task,
        type: newTaskData["Task Type"],
        severity: newTaskData["Patient Severity"],
        priority: newTaskData.Priority,
        assignee: newTaskData.Assignee,
        dueDate: newTaskData["Due-Date"],
        patient: patientHeader$._id,
      };

      const { response, error } = await apiFn({
        url: `v1/tasks`,
        options: {
          method: "POST",
          body: payload,
        },
      });

      if (error) {
        console.error("Failed to create task:", error);
        return;
      }

      setShowAddTaskDetails(false);
      setNewTaskData({
        Task: "",
        Priority: "",
        "Task Type": "",
        "Patient Name": "",
        Patient: "",
        "Bed no": "",
        "Patient Severity": "",
        "Hospital-unit": "",
        "Created-At": "",
        "Due-Date": "",
        Assignee: "",
        AssigneeName: "",
        Creator: "",
      });
      setIsAssignee(true);
      setIsDueDate(true);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const handleCloseModal = () => {
    setConfirmModalOpen(false);
  };

  const handleAlertClose = () => {
    setIsAlertModalOpen(false);
  };

  return (
    <div className="tw-relative">
      <div
        style={{ boxShadow: '2px -2px 8px rgba(0, 0, 0, 0.25)' }}
        className={`tw-fixed tw-z-[901] tw-top-[2.35rem] tw-right-[4.6rem] tw-h-[90%] tw-w-[650px] tw-border tw-border-content-300 tw-rounded tw-bg-white tw-shadow-lg tw-transform tw-flex`}>
        {/* Sidebar Section */}
        <div className="tw-h-[100%] tw-bg-cool-grey-100 tw-flex tw-items-center tw-justify-center tw-w-[15px] tw-border-r">
          <img
            src="assets/icons/slide.svg"
            alt="Slide"
            className="tw-w-1 tw-h-18 tw-flex tw-items-center tw-cursor-pointer"
            onClick={togglePopup}
          />
        </div>

        {/* Main Content */}
        <div className="tw-flex-grow tw-relative">
          {/* Header */}
          <div className="tw-bg-fades-200 tw-py-4 tw-px-6 tw-flex tw-items-center tw-justify-center tw-relative">
            <h2 className="tw-text-base tw-font-normal tw-mx-auto tw-text-cool-grey-500">{patientHeader$?.name}</h2>
            <img
              src="assets/icons/collapse.svg"
              alt="Collapse Icon"
              className="tw-absolute tw-right-4 tw-w-6 tw-h-6 tw-cursor-pointer"
              onClick={handleClose}
            />
          </div>

          {/* Task List Section */}
          <div className="tw-px-4 tw-bg-fades-100">
            <p className="tw-text-fades-600 tw-py-2 tw-tracking-widest tw-text-xs">TASKS</p>
            <div className="tw-flex tw-mb-2" onClick={toggleTasksVisibility}>
              <p className="tw-text-fades-600 tw-font-medium tw-px-1">All tasks</p>
              <img
                src="assets/icons/down_arrow.svg"
                alt="Toggle Task List"
                className="tw-cursor-pointer tw-h-5 tw-w-5"
              />
            </div>
            <hr className="tw-border-t tw-border-fades-350" />

            {/* Add Task Button */}
            <div
              className="tw-flex tw-items-center tw-cursor-pointer tw-bg-fades-100 tw-ml-3"
              onClick={handleAddTaskClick}
            >
              <img
                src="assets/icons/newtask.svg"
                alt="Add Task"
                className="tw-cursor-pointer tw-w-6 tw-h-6"
              />
              <p className="tw-text-fades-600 tw-font-normal tw-text-base tw-p-3">Add task</p>
            </div>

            {/* Add Task Details Form */}
            {showAddTaskDetails && (
              <div className="tw-mb-6 tw-bg-fades-000 tw-px-3 tw-py-1" onKeyDown={handleKeyDown}>
                <Tooltip title={validationTask.Task ? "Mandatory" : ""}>
                  <input
                    type="text"
                    name="Task"
                    className={`tw-w-[40.5rem] tw-p-1 tw-my-3 tw-text-base tw-rounded tw-text-content-600 ${validationTask.Task ? "tw-border tw-border-red-400" : ""}`}
                    placeholder={newTaskData.Task || "Add Task Details"}
                    value={newTaskData.Task}
                    onChange={(e) => handleChange(e, "Task")}
                  />
                </Tooltip>

                {/* Priority Selector */}
                <div className="tw-flex tw-items-center tw-pl-6">
                  <strong className="tw-mr-[2.3rem] tw-text-content-600 tw-text-sm">
                    Priority:
                  </strong>
                  {priorityTypes.map((priority) => (
                    <span
                      key={priority.value}
                      onClick={() => {
                        setNewTaskData((prevData) => ({
                          ...prevData,
                          Priority: priority.value,
                        }));
                        setValidationTask((prevValidation) => ({
                          ...prevValidation,
                          Priority: false,
                        }));
                      }}
                      className={`tw-px-2 tw-pt-0.5 tw-text-[0.625rem] tw-border tw-mr-1 tw-cursor-pointer tw-rounded-sm tw-flex tw-text-center ${newTaskData.Priority === priority.value
                        ? `${priority.value === "high"
                          ? "tw-bg-red-400 tw-border-red-400"
                          : "tw-bg-yellow-400 tw-border-yellow-400"
                        } tw-text-white`
                        : `${priority.value === "high"
                          ? "tw-text-red-400 tw-border-red-400"
                          : "tw-text-yellow-400 tw-border-yellow-400"
                        }`
                        }`}
                    >
                      {priority.label}
                    </span>
                  ))}
                  {validationTask.Priority && (
                    <span className="tw-text-red-400 tw-ml-2">Mandatory*</span>
                  )}
                </div>

                {/* Task Type Selector */}
                <div className="tw-flex tw-items-center tw-pl-6 tw-mt-1 tw-gap-2">
                  <strong className="tw-mr-[0.72rem] tw-text-content-600 tw-text-sm tw-py-2">Task Type:</strong>
                  {taskTypes.map((task) => (
                    <span
                      key={task.value}
                      onClick={() => {
                        setNewTaskData((prevData) => ({
                          ...prevData,
                          "Task Type": task.value,
                        }));
                        setValidationTask((prevValidation) => ({
                          ...prevValidation,
                          "Task Type": false,
                        }));
                      }}
                      className={`tw-text-fades-600 tw-text-sm tw-font-medium tw-border tw-border-fades-400 tw-px-2 tw-pt-0.5 tw-rounded-sm tw-cursor-pointer ${newTaskData["Task Type"] === task.value
                        ? "tw-bg-primary-100 tw-text-primary-400 tw-border tw-border-primary-400"
                        : ""
                        }`}
                    >
                      {task.label}
                    </span>
                  ))}

                  {validationTask["Task Type"] && (
                    <span className="tw-text-red-400 tw-ml-1">Mandatory*</span>
                  )}
                </div>

                {/* Assignee Selector */}
                <div className="tw-flex tw-items-center tw-pl-6 tw-gap-2">
                  <strong className=" tw-text-content-600 tw-text-sm tw-py-2">Assigned to:</strong>
                  {isAssignee ? (
                    <Tooltip title={validationTask.AssigneeName ? "Mandatory" : ""}>
                      <SearchDropdown
                        names={users}
                        uniqueKey={"assigneeName1"}
                        handleNameSelect={handleTaskAssigneeSelect}
                        placeholder={newTaskData.AssigneeName || "Select Assignee"}
                        className={`tw-w-[7.7rem] tw-border ${validationTask.AssigneeName ? "tw-border-red-400" : "tw-border-fades-400"}`}
                      />
                    </Tooltip>
                  ) : (
                    <span
                      onClick={() => setIsAssignee(true)}
                      className="tw-cursor-pointer"
                    >
                      {newTaskData.AssigneeName}
                    </span>
                  )}
                </div>

                {/* Due Date Input */}
                <div className="tw-text-fades-600 tw-flex tw-items-center tw-gap-2 tw-pl-6 tw-mt-1 tw-mb-3">
                  <strong className="tw-text-sm tw-mr-[0.563rem] tw-text-content-600">Due Date:</strong>
                  {isDueDate ? (
                    <Tooltip title={validationTask["Due-Date"] ? "Mandatory" : ""}>
                      <input
                        type="date"
                        name="Due-Date"
                        value={newTaskData["Due-Date"]}
                        onChange={(e) => {
                          setNewTaskData((prevData) => ({
                            ...prevData,
                            "Due-Date": e.target.value,
                          }));
                          setValidationTask((prevValidation) => ({
                            ...prevValidation,
                            "Due-Date": false,
                          }));
                          setIsDueDate(false)
                        }}
                        className={`tw-ml-2 tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded ${validationTask["Due-Date"] ? "tw-border-red-400" : "tw-border-fades-400"}`}
                      />
                    </Tooltip>
                  ) : (
                    <span onClick={() => setIsDueDate(true)}>{newTaskData["Due-Date"]}</span>
                  )}
                </div>
              </div>
            )}

            {/* Task List */}
            <div className={`tw-overflow-auto ${showAddTaskDetails ? 'tw-h-[43vh]' : 'tw-h-[71vh]'}`}>
              {error && <p>Error: {error}</p>}
              {showTasks && tasks?.list
                ?.filter(
                  (taskData) =>
                    patientHeader$?._id &&
                    taskData.patient?._id &&
                    taskData.patient._id === patientHeader$._id
                )
                .map((taskData, index) => (
                  <div id="patientTasks" key={index} className="tw-p-1 tw-flex tw-items-start" onClick={() => handleEditClick(taskData)}>
                    {isEditing && editing.taskId === taskData._id ? (
                      <div className="tw-bg-white tw-flex tw-w-[42rem]">
                        {/* Task State Icon */}
                        <div>
                          <img
                            src={
                              typeof editing?.state === "string" && editing.state.toLowerCase() === "pending"
                                ? "assets/icons/radio.svg"
                                : "assets/icons/verified.svg"
                            }
                            alt="Task State"
                            className="tw-cursor-pointer tw-p-2 tw-mt-1 tw-w-[2.5rem] tw-h-[2.5rem]"
                            onClick={() => handleRadioClick(taskData)}
                          />
                        </div>

                        {/* Editable Content */}
                        <div className="tw-px-2">
                          <input
                            name="task"
                            type="text"
                            value={editing.task}
                            onChange={(e) => {
                              setEditing({
                                ...editing,
                                task: e.target.value,
                                columnName: "title",
                              });
                            }}
                            onKeyDown={handleSave}
                            onBlur={() => handleSubmit(editing)}
                            className="tw-w-[34.063rem] tw-my-2 tw-py-1 tw-text-content-600"
                            placeholder={editing.task || "Add Task Details"}
                          />

                          {/* Handle Priority */}
                          <div className="tw-flex tw-items-center">
                            <strong className="tw-mr-6 tw-text-content-600 tw-text-sm">Priority:</strong>
                            {priorityTypes.map((priority) => (
                              <span
                                key={priority.value}
                                onClick={() => handlePrioritySelect(priority.value)}
                                className={`tw-px-2 tw-pt-0.5 tw-text-[0.625rem] tw-border tw-mr-1 tw-cursor-pointer tw-rounded-sm tw-flex tw-text-center 
        ${editing.priority === priority.value
                                    ? `${priority.value === "high" ? "tw-bg-red-400" : "tw-bg-yellow-400"} tw-text-white`
                                    : `${priority.value === "high" ? "tw-text-red-400" : "tw-text-yellow-400"} ${priority.value === "high" ? "tw-border-red-400" : "tw-border-yellow-400"}`
                                  }`}
                              >
                                {priority.label}
                              </span>
                            ))}
                          </div>

                          {/* Handle Task Type */}
                          <div className="tw-flex tw-items-center tw-mt-1 tw-gap-2">
                            <strong className="tw-mr-2 tw-text-content-600 tw-text-sm tw-py-2">Task Type:</strong>
                            {taskTypes.map((task) => (
                              <span
                                key={task.value}
                                onClick={() => handleTaskTypeSelect(task.value)}
                                className={`tw-text-fades-600 tw-text-sm tw-font-medium tw-border tw-border-fades-400 tw-px-2 tw-pt-0.5 tw-rounded-sm tw-cursor-pointer 
        ${editing.type === task.value
                                    ? "tw-bg-primary-100 tw-text-primary-400 tw-border tw-border-primary-400"
                                    : ""}`}
                              >
                                {task.label}
                              </span>
                            ))}
                          </div>

                          {/* Assigned to */}
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <strong className="tw-mr-1 tw-text-content-600 tw-text-sm tw-py-2">Assigned to:</strong>
                            {isEditingAssignee ? (
                              <SearchDropdown
                                names={users}
                                uniqueKey={"assigneeName2"}
                                handleNameSelect={handleAssigneeChange}
                                placeholder={selectedAssignee || editing.assignee || "Select Assignee"}
                              />
                            ) : (
                              <span onClick={handleAssigneeClick}>
                                {selectedAssignee || editing.assignee}
                              </span>
                            )}
                          </div>

                          {/* Due Date */}
                          <p className="tw-text-fades-600 tw-flex tw-items-center tw-gap-2 tw-mt-1 tw-mb-3">
                            <strong className="tw-text-sm tw-text-content-600">Due Date:</strong>
                            {isEditingDueDate ? (
                              <input
                                type="date"
                                name="dueDate"
                                value={editing.dueDate ? editing.dueDate.split("T")[0] : ""}
                                onChange={(e) => {
                                  const newDueDate = e.target.value;
                                  setEditing((prevEditing) => ({
                                    ...prevEditing,
                                    dueDate: newDueDate,
                                  }));
                                  setIsEditingDueDate(false);
                                  setIsEditing(false);
                                }}
                                className="tw-ml-2 tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded"
                              />
                            ) : (
                              <span onClick={() => setIsEditingDueDate(true)}>
                                {editing.dueDate
                                  ? new Date(editing.dueDate).toLocaleDateString()
                                  : "Not Assigned"}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      // Non-Editable
                      <div className="tw-p-1 tw-flex tw-items-start">
                        <img
                          src={taskData?.state.toLowerCase() === "pending" ? "assets/icons/radio.svg" : "assets/icons/verified.svg"}
                          alt="Task State"
                          className="tw-cursor-pointer tw-mr-3"
                          onClick={() => handleRadioClick(taskData)}
                        />
                        <div className="tw-flex-grow">
                          <div className="tw-w-[39rem] tw-flex tw-justify-between tw-items-center tw-mb-2">
                            <p className="tw-text-[16px] tw-text-gray-800">{taskData?.title}</p>
                            <p className="tw-whitespace-nowrap tw-text-[10px] tw-text-gray-500">
                              {new Date(taskData.dueDate).toLocaleDateString()}
                            </p>
                          </div>
                          <div className="tw-text-xs tw-flex tw-gap-3 tw-text-gray-600 tw-mb-2">
                            <p className="tw-text-red-400 tw-font-bold tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded-sm">
                              {taskData?.priority}
                            </p>
                            <p className="tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded-sm">
                              {taskData?.assignee?.name}
                            </p>
                            <p className="tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded-sm">
                              {taskData?.type}
                            </p>
                            <p
                              className={`tw-px-2 tw-py-1 ${taskData?.state.toLowerCase() === "pending"
                                ? "tw-text-red-400 tw-bg-yellow-200"
                                : taskData?.state.toLowerCase() === "completed"
                                  ? "tw-text-green-500 tw-bg-green-200"
                                  : ""
                                }`}
                            >
                              {taskData?.state}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              <ConfirmationModal
                isOpen={confirmModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirm} />

              {isAlertModalOpen && (
                <AlertModal onClose={handleAlertClose} title={modalTitle} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskListPopUp;
