import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "cp-tpnform-error-text",
  template:
    "<span class='error-text' *ngIf='control.touched && control.dirty'>(Exceeds TPN volume)</span>",
})
export class TpnformErrorTextComponent {
  @Input("control") control: FormControl;
}
