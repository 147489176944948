import { useState, useEffect } from "react";
import Dropdown from "./sharedComponents/DropDown";
import Avatar from "./sharedComponents/Avatar";
import React from "react";
import SearchDropdown from "./sharedComponents/SearchDropdown";
import useApi from "./reactStore/Hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "./reactStore/reactSlice/usersSlice";
import { AppDispatch, RootState } from "./reactStore/reduxStore";
import ConfirmationModal from "./sharedComponents/ConfirmationModal";
import { fetchTasks, selectTasks, appendTask, updateAssignee, updateState, updateTask } from './reactStore/reactSlice/taskSlice';
import { socket } from "src/app/config/socket.js";
import Pagination from "./sharedComponents/Pagination";
import Loader from "./sharedComponents/Loader";
import EllipsisTooltip from "./sharedComponents/EllipsisTooltip";
import Tooltip from "./sharedComponents/Tooltip";
import AlertModal from "./sharedComponents/AlertModal";

interface FilteredList {
  list: any[];
}

type TaskData = {
  Task: string;
  Priority: string;
  "Task Type": string;
  "Patient Name": string;
  Patient: string;
  "Patient Severity": string;
  "Bed no"?: string;
  "Hospital-unit"?: string;
  "Created-At"?: string;
  "Due-Date": string;
  Assignee: string;
  AssigneeName: string,
  Creator?: string;
}

export default function ReusableTable({ patients$, users$ }: any) {
  const borderRightClass = "tw-p-2 tw-border-r tw-border-fades-300";
  const [groupedByTaskData, setGroupedByTaskData] = useState<string>("");
  const [sortConfig, setSortConfig] = useState<{ column: string }>({
    column: "",
  });
  const [sortedAndGroupedData, setSortedAndGroupedData] = useState<any>({});
  const [activeGroupKey, setActiveGroupKey] = useState([]);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState<string | undefined>(undefined);
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchTableQuery, setSearchTableQuery] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [filteredTasks, setFilteredTasks] = useState<FilteredList | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const { apiFn } = useApi();
  const dispatch = useDispatch<AppDispatch>();
  const [newTaskData, setNewTaskData] = useState<TaskData>({
    Task: "",
    Priority: "",
    "Task Type": "",
    "Patient Name": "",
    Patient: "",
    "Bed no": "",
    "Patient Severity": "",
    "Hospital-unit": "",
    "Created-At": "",
    "Due-Date": "",
    Assignee: "",
    AssigneeName: "",
    Creator: "",
  });

  const [validationTask, setValidationTask] = useState({
    Task: false,
    Priority: false,
    "Task Type": false,
    "Patient Name": false,
    "Due-Date": false,
    AssigneeName: false,
  });

  const groupedColumns = [
    { label: "Task Type" },
    { label: "Patient Name" },
    { label: "Patient Severity" },
    { label: "Hospital-unit" },
  ];

  const taskTypes = [
    { label: "Follow up", value: "followUp" },
    { label: "Admission", value: "admission" },
    { label: "Round", value: "rounds" },
    { label: "Others", value: "other" },
  ];
  const priorityTypes = [
    { label: "High", value: "high" },
    { label: "Low", value: "low" },
  ];

  const users = useSelector((state: any) => state.users.data);

  const tasks = useSelector(selectTasks);

  useEffect(() => {
    const payload = {
      page: currentPage,
      count: 50,
    };

    dispatch(fetchTasks(payload));
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < tasks.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleGroupByTaskData = (value: string) => {
    if (groupedByTaskData === value) {
      // If the same value is selected again, remove the grouping
      setGroupedByTaskData('');
    } else {
      setGroupedByTaskData(value);
    }
  };

  const groupBy = (tasks: any[], groupByColumn: string) => {
    const groupByMapping: { [key: string]: (task: any) => string } = {
      "Task Type": (task) => task.type || "Uncategorized",
      "Patient Name": (task) => task.patient?.name || "Uncategorized",
      "Patient Severity": (task) => task.patient?.severity || "Uncategorized",
      "Hospital-unit": (task) => task.patient?.unitName || "Uncategorized",
    };

    const groupByKeyFunction = groupByMapping[groupByColumn];

    if (!groupByKeyFunction) return tasks;
    const groups = tasks.reduce((groupedTasks, task) => {
      const key = groupByKeyFunction(task);

      if (groupedTasks.hasOwnProperty(key)) {
        groupedTasks[key].push(task);
      } else {
        groupedTasks[key] = [task];
      }
      return groupedTasks;
    }, {} as Record<string, any[]>);

    const sortedKeys = Object.keys(groups).sort((a, b) => {
      if (a === "Uncategorized") return 1;
      if (b === "Uncategorized") return -1;
      return a.localeCompare(b);
    });

    const sortedGroups: Record<string, any[]> = {};
    sortedKeys.forEach((key) => {
      sortedGroups[key] = groups[key];
    });

    return sortedGroups;
  };

  const handleSort = (column: string) => {
    if (sortConfig.column === column) {
      setSortConfig({
        column: "",
      });
    } else {
      setSortConfig({
        column,
      });
    }
  };

  const getSortedTasks = (tasks: { list: any[] }, sortConfig: { column: string }) => {
    if (!tasks?.list || !Array.isArray(tasks.list)) {
      return [];
    }
    const sorted = [...tasks.list];
    switch (sortConfig.column) {
      case "Task Type":
        const taskTypeOrder = ["followUp", "admission", "rounds", "other"];
        sorted.sort((a, b) => {
          const taskA = a.type || "";
          const taskB = b.type || "";

          if (!taskA && taskB) return 1;
          if (taskA && !taskB) return -1;

          const aIndex = taskTypeOrder.indexOf(taskA);
          const bIndex = taskTypeOrder.indexOf(taskB);

          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          return aIndex - bIndex;
        });
        break;

      case "Patient Name":
        sorted.sort((a, b) => {
          const nameA = a.patient?.name?.toLowerCase() || "";
          const nameB = b.patient?.name?.toLowerCase() || "";

          if (!nameA && nameB) return 1;
          if (nameA && !nameB) return -1;
          return nameA.localeCompare(nameB);
        });
        break;

      case "Patient Severity":
        const severityOrder = ["Unstable", "Watcher", "Comfort Care", "Fair"];
        sorted.sort((a, b) => {
          const severityA = a.patient?.severity?.trim() || "";
          const severityB = b.patient?.severity?.trim() || "";

          if (!severityA && severityB) return 1;
          if (severityA && !severityB) return -1;

          const aIndex = severityOrder.indexOf(severityA);
          const bIndex = severityOrder.indexOf(severityB);

          if (aIndex === -1 && bIndex === -1) return 0;
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          if (severityA === "Fair") return 1;
          if (severityB === "Fair") return -1;

          return aIndex - bIndex;
        });
        break;

      case "Hospital Unit":
        sorted.sort((a, b) => {
          const unitA = a.patient?.unitName?.toLowerCase() || "";
          const unitB = b.patient?.unitName?.toLowerCase() || "";

          if (!unitA && unitB) return 1;
          if (unitA && !unitB) return -1;
          return unitA.localeCompare(unitB);
        });
        break;

      case "Created At":
        sorted.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        break;

      case "Due Date":
        sorted.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
        break;

      case "Assignee":
        sorted.sort((a, b) => {
          const assigneeA = a.assignee?.name?.toLowerCase() || "";
          const assigneeB = b.assignee?.name?.toLowerCase() || "";

          if (!assigneeA && assigneeB) return 1;
          if (assigneeA && !assigneeB) return -1;
          return assigneeA.localeCompare(assigneeB);
        });
        break;

      case "Creator":
        sorted.sort((a, b) => {
          const creatorA = a.reporter?.name?.toLowerCase() || "";
          const creatorB = b.reporter?.name?.toLowerCase() || "";

          if (!creatorA && creatorB) return 1;
          if (creatorA && !creatorB) return -1;
          return creatorA.localeCompare(creatorB);
        });
        break;

      default:
        break;
    }
    return sorted;
  };

  const handleAvatarClick = (name: string, id: string) => {
    if (selectedAssigneeId === id) {
      setSelectedAssigneeId('');
    } else {
      setSelectedAssigneeId(id);
    }
  };

  const itemsPerPage = 50;

  useEffect(() => {
    const fetchAssigneeTasks = async () => {
      if (!selectedAssigneeId) {
        setFilteredTasks({ list: [] });
        return;
      }

      try {
        const { response, error } = await apiFn({
          url: `v1/tasks/user/${selectedAssigneeId}`,
          options: {
            method: "GET",
          },
        });

        if (error) {
          console.error("Error fetching tasks:", error);
          setError(error);
          setFilteredTasks({ list: [] });
        } else {
          setFilteredTasks(response.data || []);
          setError(null);
        }
      } catch (err) {
        console.error("Unexpected error fetching tasks:", err);
        setError(err.message || "Error occurred");
        setFilteredTasks({ list: [] });
      }
    };

    fetchAssigneeTasks();
  }, [selectedAssigneeId]);

  useEffect(() => {
    // Reset logic for groupedByTaskData, selectedAssignee, or searchTableQuery
    if (groupedByTaskData) {
      setSelectedAssignee('');
      setSearchTableQuery('');
    }
    if (selectedAssignee) {
      setGroupedByTaskData('');
      setSearchTableQuery('');
    }
    if (searchTableQuery) {
      setGroupedByTaskData('');
      setSelectedAssignee('');
    }

    let data: { [key: string]: any[] } = {};
    // Check if filteredTasks has list and is not empty
    const taskList = filteredTasks?.list && filteredTasks?.list.length > 0
      ? filteredTasks?.list
      : tasks?.list || [];

    if (groupedByTaskData) {
      const grouped = groupBy(taskList, groupedByTaskData);
      data = Object.keys(grouped).reduce((acc: { [key: string]: any[] }, groupKey) => {
        const group = grouped[groupKey];
        acc[groupKey] = getSortedTasks({ list: group }, sortConfig);
        return acc;
      }, {});
    } else {
      data = { "": getSortedTasks({ list: taskList }, sortConfig) };
    }

    if (searchTableQuery) {
      const query = searchTableQuery.toLowerCase();
      data = Object.keys(data).reduce((acc, groupKey) => {
        const filteredGroup = data[groupKey].filter((task) => {
          return Object.values(task).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(query);
            }
            if (typeof value === 'object' && value !== null) {
              return Object.values(value).some((nestedValue) =>
                typeof nestedValue === 'string' && nestedValue.toLowerCase().includes(query)
              );
            }
            return false;
          });
        });

        acc[groupKey] = filteredGroup;
        return acc;
      }, {});
    }

    setSortedAndGroupedData(data);
    setNewTaskData((prevData) => ({
      ...prevData,
      Creator: users$?.name,
    }));
  }, [tasks, groupedByTaskData, sortConfig, searchTableQuery, filteredTasks]);

  useEffect(() => {
    socket.on('taskList-create', (data) => {
      dispatch(appendTask(data));
    });

    socket.on('taskList-change-assignee', (data) => {
      const { _id, assignee } = data;
      dispatch(updateAssignee({ _id, assignee }));
    });

    socket.on('taskList-complete', (data) => {
      const { _id } = data;
      dispatch(updateState({ _id }));
    });

    socket.on('task-list-update', (data) => {
      const { _id, ...updatedFields } = data;
      dispatch(updateTask({ _id, ...updatedFields }));
    });

    return () => {
      socket.off('taskList-create');
      socket.off('taskList-change-assignee');
      socket.off('taskList-complete');
      socket.off('task-list-update');
    };
  }, [dispatch, socket]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [newTaskData]);

  useEffect(() => {
    dispatch(fetchUsers(''));
  }, [dispatch]);

  const handleAddTask = (groupKey: any) => {
    setActiveGroupKey((prev) =>
      prev.includes(groupKey)
        ? prev.filter((key) => key !== groupKey)
        : [...prev, groupKey]
    );
  };

  const handleCellClick = (cellName: keyof TaskData) => {
    setEditMode((prevState) => ({
      ...prevState,
      [cellName]: true,
    }));
  };

  const handleBlur = (cellName: keyof TaskData) => {
    setEditMode((prevState) => ({
      ...prevState,
      [cellName]: false,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, cellName: string) => {
    const { name, value } = e.target;
    setNewTaskData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [editMode, setEditMode] = useState<{ [key in keyof TaskData]?: boolean }>({
    Task: true,
    Priority: false,
    "Task Type": false,
    "Patient Name": false,
    "Due-Date": false,
    Assignee: false,
  });

  const [editing, setEditing] = useState({
    rowId: null,
    columnName: null,
    value: ''
  });

  const handleEdit = (rowId: string, columnName: string, value: string) => {
    setIsEditing(true);
    setEditing({
      rowId,
      columnName,
      value,
    });
  };

  const handlePatientEdit = (taskId: string, patient: any) => {
    setNewTaskData((prevData) => ({
      ...prevData,
      [taskId]: {
        ...prevData[taskId],
        Patient: patient._id,
        "Patient Name": patient.name,
        "Patient Severity": patient.severity || "",
        "Bed no": patient.bedNo || "",
        "Hospital-unit": patient.unitName || "",
      },
    }));
  };

  const handlePatientSelect = (patient: any) => {
    setNewTaskData((prevValues) => ({
      ...prevValues,
      Patient: patient._id, // Backend value
      "Patient Name": patient.name, // Frontend display
      "Patient Severity": patient.severity || "",
      "Bed no": patient.bedNo || "",
      "Hospital-unit": patient.unitName || "",
    }));
  };

  const handleAssigneeSelect = (assignee: any) => {
    setNewTaskData((prevValues) => ({
      ...prevValues,
      Assignee: assignee._id, // Store the _id for the backend
      AssigneeName: assignee.name, // Store the name for frontend display
    }));

    setEditMode((prevState) => ({
      ...prevState,
      Assignee: false,
    }));
  };

  const handleEditAssignee = async (selectedAssignee: { _id: string }) => {
    try {
      const taskId = editing.rowId;
      const payload = { assignee: selectedAssignee._id };

      const { response, error } = await apiFn({
        url: `v1/tasks/${taskId}/reassign`,
        options: {
          method: "PATCH",
          body: payload,
        },
      });

      if (error) {
        console.error("Error reassigning task:", error);
      } else {
        setEditing({ rowId: null, columnName: null, value: "" });
      }
    } catch (err) {
      console.error("Error reassigning task:", err);
    }
  };

  const createNewTask = async () => {
    const newTaskPayload = {
      title: newTaskData.Task,
      type: newTaskData["Task Type"],
      severity: newTaskData["Patient Severity"],
      priority: newTaskData.Priority,
      assignee: newTaskData.Assignee,
      dueDate: newTaskData["Due-Date"],
      patient: newTaskData.Patient,
    };

    try {
      const { response, error } = await apiFn({
        url: `v1/tasks`,
        options: {
          method: "POST",
          body: newTaskPayload,
        },
      });

      if (error) {
        console.log("Error creating task:", error);
        return;
      }

      if (response) {
        setNewTaskData({
          Task: "",
          Priority: "",
          "Task Type": "",
          "Patient Name": "",
          Patient: "",
          "Bed no": "",
          "Patient Severity": "",
          "Hospital-unit": "",
          "Created-At": "",
          "Due-Date": "",
          Assignee: "",
          AssigneeName: "",
          Creator: "",
        });
        setIsEditing(false);
        setActiveGroupKey([]);
        setEditMode({
          Task: true,
          Priority: false,
          "Task Type": false,
          "Patient Name": false,
          "Due-Date": false,
          Assignee: false,
        });
        setValidationTask({
          Task: false,
          Priority: false,
          "Task Type": false,
          "Patient Name": false,
          "Due-Date": false,
          AssigneeName: false,
        });
      }
    } catch (error) {
      console.log("Error in creating task:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      let isValid = true;
      const requiredFields = [
        "Task",
        "Priority",
        "Task Type",
        "Patient Name",
        "Due-Date",
        "AssigneeName",
      ];

      requiredFields.forEach((field) => {
        if (!newTaskData[field] || newTaskData[field].trim() === "") {
          isValid = false;
          setValidationTask((prev) => ({
            ...prev,
            [field]: true,
          }));
        }
      });

      setEditMode({
        Task: true,
        Priority: true,
        "Task Type": true,
        "Patient Name": true,
        "Due-Date": true,
        Assignee: true,
      });

      if (!isValid) {
        return;
      }
      createNewTask();
    }
  };

  const handleSave = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (!editing.rowId || !editing.columnName || !editing.value) return;

    setIsEditing(true);
    await handleSubmit(editing.value);
  };

  const handleSubmit = async (newValue) => {
    const editingPayload = {
      [editing.columnName]: newValue,
    };
    try {
      const { response, error } = await apiFn({
        url: `v1/tasks/${editing.rowId}`,
        options: {
          method: 'PATCH',
          body: editingPayload,
        },
      });

      if (error) {
        console.error('Error updating task:', error);
        return;
      }

    } catch (error) {
      console.error('Error in submission:', error);
    } finally {
      setIsEditing(false);
      setEditing({
        rowId: null,
        columnName: null,
        value: '',
      });
    }
  };
  const storedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const currentUserEmail = storedUser?.email?.trim().toLowerCase();

  const handleRadioClick = (task: any) => {

    if (task && task._id && task.assignee && task.assignee._id) {
      const taskAssigneeEmail = task.assignee.email?.trim().toLowerCase();

      if (task.state === "completed") {
        setModalTitle("This task is already marked as completed.");
        setIsAlertModalOpen(true);
        return;
      }

      if (currentUserEmail !== taskAssigneeEmail) {
        setModalTitle("Only assignee can mark the task as complete");
        setIsAlertModalOpen(true);
        return;
      }

      setSelectedTask(task);
      setConfirmModalOpen(true);
    } else {
      console.error("Invalid task data:", task);
    }
  };

  const handleAlertClose = () => {
    setIsAlertModalOpen(false);
  };

  const isEditable = (reporterEmail: string | undefined) => {
    return (
      reporterEmail?.trim().toLowerCase() === currentUserEmail
    );
  };

  const handleConfirm = async () => {
    if (selectedTask && selectedTask._id && selectedTask.assignee) {
      await handleCompleteTask(selectedTask);
    } else {
      console.error("Selected task data is invalid:", selectedTask);
    }
    setConfirmModalOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmModalOpen(false);
  };

  const handleCompleteTask = async (taskdata: { _id: string; assignee: { _id: string; email: string }; state: string }) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
      const currentUserEmail = storedUser?.email?.trim().toLowerCase();

      const taskAssigneeEmail = taskdata?.assignee?.email?.trim().toLowerCase();

      if (currentUserEmail !== taskAssigneeEmail) {
        alert("Only the assignee can complete the task.");
        return;
      }

      if (taskdata.state === "completed") {
        alert("This task is already marked as completed.");
        return;
      }

      const taskId = taskdata._id;
      const assigneeId = taskdata.assignee._id;

      const payload = {
        assignee: assigneeId,
      };

      const { response, error } = await apiFn({
        url: `v1/tasks/${taskId}/complete`,
        options: {
          method: "PATCH",
          body: payload,
        },
      });

      if (error) {
        console.error("Error completing task:", error);
      }
    } catch (err) {
      console.error("Error completing task:", err);
    }
  };

  const renderRows = (groupedData: any) => {
    return groupedData.map((taskdata: any) => {
      const priorityClass =
        taskdata?.priority === "high" && taskdata?.state === "completed"
          ? "tw-bg-red-100"
          : taskdata?.priority === "high"
            ? "tw-bg-red-100"
            : "tw-bg-white";

      const stateStyle =
        taskdata?.state === "completed" && taskdata?.priority !== "high"
          ? { backgroundColor: "#f5f7f7" }
          : {};

      return (
        <tr
          key={taskdata?._id}
          className={`tw-text-[0.875rem] tw-text-others-100 tw-border tw-border-fades-300 ${priorityClass} ${borderRightClass}`}
          style={stateStyle}>
          <td className="tw-pl-2 tw-w-[2.2rem]">
            <img
              src={
                taskdata?.state === 'pending'
                  ? 'assets/icons/radio.svg'
                  : 'assets/icons/verified.svg'
              }
              alt={taskdata?.state}
              className="tw-w-5 tw-h-5"
              onClick={() => handleRadioClick(taskdata)}
            />
          </td>
          <td
            className={`tw-w-[19rem] ${borderRightClass}`}
            onClick={() =>
              isEditable(taskdata?.reporter?.email) && handleEdit(taskdata._id, "title", taskdata?.title)
            }
          >
            {editing.rowId === taskdata._id && editing.columnName === "title" && isEditable(taskdata?.reporter?.email) ? (
              <input
                type="text"
                value={editing.value}
                onChange={(e) => setEditing({ ...editing, value: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSave(e);
                }}
                className="tw-w-[19rem] tw-p-1 tw-border tw-border-fades-400 tw-rounded-md"
              />
            ) : (
              <EllipsisTooltip text={taskdata?.title} maxWidthClass="tw-w-[19rem]" />
            )}
          </td>

          <td
            className={`tw-w-[5rem] ${borderRightClass}`}
            onClick={() =>
              isEditable(taskdata?.reporter?.email) && handleEdit(taskdata._id, "priority", taskdata?.priority)
            }
          >
            {editing.rowId === taskdata._id && editing.columnName === "priority" && isEditable(taskdata?.reporter?.email) ? (
              <Dropdown
                options={priorityTypes}
                selectedValue={
                  priorityTypes.find((type) => type.value === newTaskData.Priority)?.value || ""
                }
                onChange={(value: string) => {
                  setEditing({ ...editing, value });
                  handleSubmit(value);
                }}
                placeholder={taskdata?.priority || "Priority"}
                className={`tw-w-full tw-border ${validationTask.Priority ? "tw-border-red-400" : "tw-border-fades-400"
                  }`}
              />
            ) : (
              <p>{taskdata?.priority || "-"}</p>
            )}
          </td>

          <td
            className={`tw-w-[7rem] ${borderRightClass}`}
            onClick={() =>
              isEditable(taskdata?.reporter?.email) && handleEdit(taskdata._id, "type", taskdata?.type)
            }
          >
            {editing.rowId === taskdata._id && editing.columnName === "type" && isEditable(taskdata?.reporter?.email) ? (
              <Dropdown
                options={taskTypes}
                selectedValue={
                  taskTypes.find((type) => type.value === newTaskData["Task Type"])?.value || ""
                }
                onChange={(value) => {
                  setEditing((prevEditing) => ({
                    ...prevEditing,
                    value,
                  }));
                  handleSubmit(value);
                }}
                placeholder={taskdata?.type || "Select Task"}
                className={`tw-w-full tw-border ${validationTask["Task Type"] ? "tw-border-red-400" : "tw-border-fades-400"
                  }`}
              />
            ) : (
              <p>{taskdata?.type || "-"}</p>
            )}
          </td>

          <td
            className={`tw-w-[7rem] ${borderRightClass}`}
            onClick={() =>
              isEditable(taskdata?.reporter?.email) && handleEdit(taskdata._id, "patient", taskdata?.patient?.name)
            }
          >
            {editing.rowId === taskdata._id && editing.columnName === "patient" && isEditable(taskdata?.reporter?.email) ? (
              <SearchDropdown
                names={patients$}
                uniqueKey={"patientsName1"}
                value={taskdata?.patient?.name}
                handleNameSelect={(patient) => {
                  handlePatientEdit(taskdata._id, patient);
                  setEditing((prevEditing) => ({
                    ...prevEditing,
                    value: patient._id,
                  }));
                  handleSubmit(patient._id);
                }}
                placeholder={taskdata?.patient?.name || "Select patient"}
                className={`tw-w-full ${validationTask["Patient Name"]
                  ? "tw-border-red-400"
                  : "tw-border-fades-400"
                  }`}
              />
            ) : (
              <EllipsisTooltip text={taskdata?.patient?.name} maxWidthClass="tw-w-[7rem]" />
            )}
          </td>
          <td
            className={`${borderRightClass}`}>
            <div className="tw-flex tw-items-center tw-gap-2">
              {taskdata?.patient?.severity === "Unstable" && (
                <img
                  src="assets/icons/unstable.svg"
                  alt="Unstable"
                  className="tw-w-4 tw-h-4"
                />
              )}
              <p className={taskdata?.patient?.severity === "Unstable" ? "tw-text-red-400" : ""}>
                {taskdata?.patient?.severity || '-'}
              </p>
            </div>
          </td>
          <td className={`tw-w-[4rem] ${borderRightClass}`}>
            <p>{taskdata?.patient?.bedNo || '-'}</p>
          </td>
          <td className={`tw-w-[8rem] ${borderRightClass}`} onClick={() => handleEdit(taskdata._id, 'hospitalName', taskdata?.patient?.hospitalName)}>
            <EllipsisTooltip text={`${taskdata?.patient?.hospitalName} | ${taskdata?.patient?.unitName}`} maxWidthClass="tw-w-[12rem]" />
          </td>
          <td className={`tw-w-[8rem] ${borderRightClass}`} onClick={() => handleEdit(taskdata._id, 'createdAt', taskdata?.createdAt)}>
            <p className="tw-whitespace-nowrap">
              {taskdata?.createdAt
                ? `${new Date(taskdata.createdAt).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                })}, ${new Date(taskdata.createdAt).toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })}` : "-"}
            </p>
          </td>
          <td className={`tw-w-[8rem] ${borderRightClass}`} onClick={() => handleEdit(taskdata._id, 'dueDate', taskdata?.dueDate)}>
            {editing.rowId === taskdata._id && editing.columnName === 'dueDate' ? (
              <input
                type="date"
                name="Due-Date"
                value={editing.value || taskdata?.dueDate}
                className={`tw-w-[6.7rem] tw-text-xs tw-p-1 tw-border tw-rounded tw-outline-none`}
                onChange={(e) => {
                  const newDate = e.target.value;
                  setEditing((prevEditing) => ({
                    ...prevEditing,
                    value: newDate,
                  }));
                  handleSubmit(newDate);
                }}
              />
            ) : (
              <p className="tw-whitespace-nowrap">
                {taskdata?.dueDate
                  ? `${new Date(taskdata.dueDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                  })}, ${new Date(taskdata.dueDate).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}`
                  : '-'}
              </p>
            )}
          </td>
          <td className={`tw-w-[6rem] ${borderRightClass}`} onClick={() => handleEdit(taskdata._id, 'assignee', taskdata?.assignee?.name)}>
            {editing.rowId === taskdata._id && editing.columnName === 'assignee' ? (
              <SearchDropdown
                names={users}
                uniqueKey={"assigneeName1"}
                handleNameSelect={handleEditAssignee}
                placeholder={taskdata?.assignee?.name || "Select Assignee"}
              />
            ) : (
              <EllipsisTooltip text={taskdata?.assignee?.name} maxWidthClass="tw-w-[8rem]" />
            )}
          </td>
          <td className={`tw-w-[8rem] ${borderRightClass}`} onClick={() => handleEdit(taskdata._id, 'reporter', taskdata?.reporter?.name)}>
            <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-w-[8rem]">
              {taskdata?.reporter?.name || '-'}
            </p>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="tw-h-[83vh] tw-overflow-auto">
      <div className="tw-flex tw-gap-6 tw-my-6">
        <div>
          <input
            type="text"
            placeholder="Search"
            value={searchTableQuery}
            onChange={(e) => setSearchTableQuery(e.target.value)}
            className="tw-w-[28rem] tw-p-2 tw-border tw-border-fades-400 tw-rounded tw-outline-none"
          />
        </div>

        <Avatar
          data={users.map((user: { name: string, _id: string }) => ({
            name: user.name,
            id: user._id,
          }))}
          onClick={handleAvatarClick}
          placeholder="Search for Assignees"
        />

        <div className="tw-ml-[16rem] tw-flex tw-items-center">
          <h1 className="tw-mr-2">Group By:</h1>
          <div className="tw-flex tw-items-center">
            <Dropdown
              options={groupedColumns}
              selectedValue={groupedByTaskData}
              onChange={handleGroupByTaskData}
              placeholder="None"
              className="tw-w-[9rem]"
              placeholdertext="tw-text-sm"
            />
          </div>
        </div>
      </div>
      <div className="tw-w-full">
        {Object.keys(sortedAndGroupedData).map((groupKey) => (
          <div key={groupKey} className="tw-my-1">
            {groupKey !== 'all' && (
              <h3 className="tw-text-[1rem] tw-font-medium tw-mb-2 tw-px-4">{groupKey}</h3>
            )}
            <table className="tw-w-full tw-border-collapse">
              <thead className="tw-bg-white tw-h-[50px] tw-text-left tw-text-[0.875rem] tw-text-others-100 tw-border tw-border-fades-300 tw-border-l">
                <tr>
                  <th className="tw-w-[2.2rem]">
                    <p>{""}</p>
                  </th>
                  <th className={`tw-w-[19rem] ${borderRightClass}`}>
                    <p>Task</p>
                  </th>
                  <th className={`tw-w-[5rem] ${borderRightClass}`}>
                    <p>Priority</p>
                  </th>
                  <th
                    className={`tw-w-[7rem] ${borderRightClass}`}
                    onClick={() => handleSort("Task Type")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Task Type</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer" />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[7rem] ${borderRightClass}`}
                    onClick={() => handleSort("Patient Name")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Patient Name</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer" />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[10rem] ${borderRightClass}`}
                    onClick={() => handleSort("Patient Severity")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Patient Severity</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer" />
                    </div>
                  </th>
                  <th className={`tw-w-[4rem] ${borderRightClass}`}>
                    <span className="tw-text-nowrap">Bed no</span>
                  </th>
                  <th
                    className={`tw-w-[8rem] ${borderRightClass}`}
                    onClick={() => handleSort("Hospital Unit")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Hospital | Unit</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
                      />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[8rem] ${borderRightClass}`}
                    onClick={() => handleSort("Created At")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Created At</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
                      />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[8rem] ${borderRightClass}`}
                    onClick={() => handleSort("Due Date")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Due Date</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
                      />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[6rem] ${borderRightClass}`}
                    onClick={() => handleSort("Assignee")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Assignee</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
                      />
                    </div>
                  </th>
                  <th
                    className={`tw-w-[7rem] ${borderRightClass}`}
                    onClick={() => handleSort("Creator")}
                  >
                    <div className="tw-flex tw-items-center">
                      <span className="tw-text-nowrap">Creator</span>
                      <img
                        src="assets/icons/arrow_upward.svg"
                        alt="Sort"
                        className="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
                      />
                    </div>
                  </th>
                </tr>

                {activeGroupKey.includes(groupKey) && (
                  <tr className="tw-border tw-border-fades-300" onKeyDown={(e) => handleKeyDown(e)}>
                    <td className="tw-pl-2 tw-w-[2.2rem]">
                      <img
                        src="assets/icons/newtask.svg"
                        alt="New Task"
                        className="tw-w-5 tw-h-5"
                      />
                    </td>
                    <td
                      className={`tw-p-2 tw-w-[19rem] ${borderRightClass} tw-cursor-pointer`}
                      onClick={() => handleCellClick("Task")}
                    >
                      {editMode.Task ? (
                        <div className="tw-relative">
                          <Tooltip title={validationTask.Task ? "Mandatory" : ""}>
                            <input
                              type="text"
                              name="Task"
                              className={`tw-w-[19rem] tw-p-1 tw-border tw-rounded tw-outline-none ${validationTask.Task ? "tw-border-red-400" : "tw-border-fades-400"}`}
                              value={newTaskData.Task}
                              onChange={(e) => handleChange(e, "Task")}
                              onBlur={() => handleBlur("Task")}
                              autoFocus
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        <span>
                          {newTaskData.Task || (
                            <img
                              src="/assets/icons/edit.svg"
                              alt="Edit icon"
                              className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                            />
                          )}
                        </span>
                      )}
                    </td>

                    {/* Priority Cell */}
                    <td
                      className={`tw-p-2 tw-w-[5rem] ${borderRightClass} tw-group tw-relative tw-cursor-pointer`}
                      onClick={() => handleCellClick("Priority")}
                    >
                      {editMode.Priority ? (
                        <Tooltip title={validationTask.Priority ? "Mandatory" : ""}>
                          <Dropdown
                            options={priorityTypes}
                            selectedValue={
                              priorityTypes.find((type) => type.value === newTaskData.Priority)?.value || ""
                            }
                            onChange={(value) => {
                              setNewTaskData((prevData) => ({
                                ...prevData,
                                Priority: value,
                              }));

                              setEditMode((prevState) => ({
                                ...prevState,
                                "Priority": false,
                              }));
                            }}
                            placeholder={newTaskData.Priority || "Priority"}
                            className={`tw-w-[3.8rem] tw-border ${validationTask.Priority ? "tw-border-red-400" : "tw-border-fades-400"
                              }`}
                            placeholdertext="tw-text-[10px]"
                          />
                        </Tooltip>
                      ) : (
                        <span className="tw-text-sm tw-text-others-100">
                          {
                            priorityTypes.find((type) => type.value === newTaskData.Priority)?.label || (
                              <img
                                src="/assets/icons/edit.svg"
                                alt="Edit icon"
                                className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                              />
                            )
                          }
                        </span>
                      )}
                    </td>

                    {/* Task Type Cell */}
                    <td
                      className={`tw-p-2 tw-w-[7rem] ${borderRightClass} tw-group tw-relative tw-cursor-pointer`}
                      onClick={() => handleCellClick("Task Type")}
                    >
                      {editMode["Task Type"] ? (
                        <Tooltip title={validationTask["Task Type"] ? "Mandatory" : ""}>
                          <Dropdown
                            options={taskTypes}
                            selectedValue={
                              taskTypes.find((type) => type.value === newTaskData["Task Type"])?.value || ""
                            }
                            onChange={(value) => {
                              setNewTaskData((prevData) => ({
                                ...prevData,
                                ["Task Type"]: value,
                              }));

                              setEditMode((prevState) => ({
                                ...prevState,
                                "Task Type": false,
                              }));
                            }}
                            placeholder={newTaskData["Task Type"] || "Select Task"}
                            className={`tw-w-[5.8rem] tw-border ${validationTask["Task Type"] ? "tw-border-red-400" : "tw-border-fades-400"
                              }`}
                            placeholdertext="tw-text-xs"
                          />
                        </Tooltip>
                      ) : (
                        <span className="tw-text-sm tw-text-others-100">
                          {
                            taskTypes.find((type) => type.value === newTaskData["Task Type"])?.label ||
                            <img
                              src="/assets/icons/edit.svg"
                              alt="Edit icon"
                              className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                            />
                          }
                        </span>
                      )}
                    </td>

                    {/* Patient Name Cell */}
                    <td
                      className={`tw-p-2 tw-w-[7rem] ${borderRightClass} tw-cursor-pointer`}
                      onClick={() => handleCellClick("Patient Name")}
                    >
                      {editMode["Patient Name"] ? (
                        <Tooltip title={validationTask["Patient Name"] ? "Mandatory" : ""}>
                          <SearchDropdown
                            names={patients$}
                            uniqueKey={"patientsName2"}
                            value={newTaskData["Patient Name"]} // Pre-fill the dropdown with the current value
                            handleNameSelect={(patient) => {
                              handlePatientSelect(patient);
                              setEditMode((prev) => ({ ...prev, "Patient Name": false })); // Exit edit mode
                            }}
                            placeholder={newTaskData["Patient Name"] || "Select Patient"}
                            className={`tw-w-[7rem] tw-border ${validationTask["Patient Name"] ? "tw-border-red-400" : "tw-border-fades-400"
                              }`}
                          />
                        </Tooltip>
                      ) : (
                        <span className="tw-text-sm tw-text-others-100 tw-inline-block tw-overflow-hidden tw-text-ellipsis">
                          {newTaskData["Patient Name"] || (
                            <img
                              src="/assets/icons/edit.svg"
                              alt="Edit icon"
                              className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                            />
                          )}
                        </span>
                      )}
                    </td>

                    {/* Severity Cell */}
                    <td className={`tw-w-[10rem] tw-p-2 ${borderRightClass}`} onClick={() => handleCellClick("Patient Severity")}>
                      <span>{newTaskData["Patient Severity"] || "-"}</span>
                    </td>

                    {/* Bed No Cell */}
                    <td className={`tw-p-2 tw-w-[4rem] ${borderRightClass}`} onClick={() => handleCellClick("Bed no")}>
                      <span>{newTaskData["Bed no"] || "-"}</span>
                    </td>

                    {/* Hospital Unit Cell */}
                    <td className={`tw-p-2 tw-w-[8rem] ${borderRightClass}`} onClick={() => handleCellClick("Hospital-unit")}>
                      <span>{newTaskData["Hospital-unit"] || "-"}</span>
                    </td>

                    {/* Created At Cell */}
                    <td
                      className={`tw-p-2 tw-w-[8rem] ${borderRightClass}`}
                      onClick={() => handleCellClick("Created-At")}
                    >
                      {editMode["Created-At"] ? (
                        <input
                          type="date"
                          name="Created-At"
                          className="tw-w-full tw-p-1 tw-border tw-border-fades-400 tw-rounded tw-outline-none"
                          value={newTaskData["Created-At"] || new Date().toISOString().split('T')[0]}
                          onChange={(e) => handleChange(e, "Created-At")}
                          onBlur={() => handleBlur("Created-At")}
                          autoFocus
                          readOnly
                        />
                      ) : (
                        <span>{new Date(newTaskData["Created-At"] || new Date().toISOString())
                          .toLocaleDateString("en-GB")}</span>
                      )}
                    </td>

                    {/* Due Date Cell */}
                    <td className={`tw-p-2 tw-w-[7rem] ${borderRightClass} tw-cursor-pointer`} onClick={() => handleCellClick("Due-Date")}>
                      {editMode["Due-Date"] ? (
                        <Tooltip title={validationTask["Due-Date"] ? "Mandatory" : ""}>
                          <input
                            type="date"
                            name="Due-Date"
                            className={`tw-w-[6.7rem] tw-text-xs tw-p-1 tw-border ${validationTask["Due-Date"] ? "tw-border-red-400" : "tw-border-fades-400"} tw-rounded tw-outline-none`}
                            value={newTaskData["Due-Date"]}
                            onChange={(e) => handleChange(e, "Due-Date")}
                            onBlur={() => handleBlur("Due-Date")}
                          />
                        </Tooltip>
                      ) : (
                        <span>{newTaskData["Due-Date"] || (
                          <img
                            src="/assets/icons/edit.svg"
                            alt="Edit icon"
                            className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                          />
                        )}</span>
                      )}
                    </td>

                    {/* Assignee Cell */}
                    <td className={`tw-p-2 tw-w-[8rem] ${borderRightClass} tw-cursor-pointer`} onClick={() => handleCellClick("Assignee")}>
                      {editMode.Assignee ? (
                        <Tooltip title={validationTask.AssigneeName ? "Mandatory" : ""}>
                          <SearchDropdown
                            names={users}
                            uniqueKey={"assigneeName2"}
                            handleNameSelect={handleAssigneeSelect}
                            placeholder={newTaskData.AssigneeName || "Select Assignee"}
                            className={`tw-w-[8rem] tw-border ${validationTask.AssigneeName ? "tw-border-red-400" : "tw-border-fades-400"}`}
                          />
                        </Tooltip>
                      ) : (
                        <span>{newTaskData.AssigneeName || (
                          <img
                            src="/assets/icons/edit.svg"
                            alt="Edit icon"
                            className="tw-inline tw-w-4 tw-h-4 tw-cursor-pointer"
                          />
                        )}</span>
                      )}
                    </td>

                    {/* Creator Cell */}
                    <td className={`tw-p-2 tw-w-[7rem] ${borderRightClass}`}>
                      <span>{newTaskData.Creator || ""}</span>
                    </td>
                  </tr>
                )}
              </thead>
              <thead className="tw-w-full tw-bg-white tw-border tw-border-fades-300">
                <tr className="tw-w-full tw-bg-white"  >
                  <td className="tw-border-b tw-border-fades-400 tw-pl-2">
                    <img
                      src="assets/icons/newtask.svg"
                      alt="New Task"
                      className="tw-w-5 tw-h-5 tw-cursor-pointer"
                    />
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                    <div key={groupKey}>
                      <button className="tw-text-primary-400 tw-font-medium tw-cursor-pointer"
                        onClick={(e) => handleAddTask(groupKey)}>Add Task</button>
                    </div>
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                  <td
                    className="tw-border-b tw-border-fades-400 tw-p-2"
                  >
                  </td>
                </tr>
              </thead>
              <tbody className="tw-text-[14px] tw-font-medium">
                {!tasks?.list ? (
                  <tr>
                    <td colSpan={12} className="tw-h-[57vh] tw-flex tw-justify-center tw-items-center tw-bg-white tw-w-full">
                      <Loader />
                    </td>
                  </tr>
                ) : filteredTasks?.list?.length === 0 && selectedAssigneeId ? (
                  <tr>
                    <td colSpan={12} className="tw-h-[57vh] tw-bg-white">
                      <div className="tw-w-full tw-flex tw-justify-center tw-items-center">NO DATA</div>
                    </td>
                  </tr>
                ) : (
                  renderRows(sortedAndGroupedData[groupKey])
                )}
              </tbody>
            </table>

            <div className="tw-mt-3">
              <Pagination
                currentPage={currentPage}
                totalPages={tasks.totalPages}
                itemsPerPage={itemsPerPage}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
              />
            </div>

            <ConfirmationModal
              isOpen={confirmModalOpen}
              onClose={handleCloseModal}
              onConfirm={handleConfirm} />

            {isAlertModalOpen && (
              <AlertModal onClose={handleAlertClose} title={modalTitle} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}