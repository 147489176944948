import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NutrFieldCalInterface,
  NutritionCalculationType,
  NutritionInterface,
  TPNFinalCalculationInterface,
  TPNFormInterface,
  TPNStep1Interface,
  TPNStep2Interface,
  TPNStep3Interface,
  TPNStep4Interface,
} from "../models";
import { TPNValueExceededValidator } from "../validators";
import { getTotalAA, getTotalLipids, getTpnFluidConsumedInfo } from "../utils";
import { UtilService } from "src/app/services/util.service";
import {
  FeedTypeEnum,
  FeedingSupplementsEnum,
  FinalCalcFieldEnum,
  feedTypeSugarMap,
  nutritionCalcMap,
} from "../data";

@Injectable({
  providedIn: "root",
})
export class TpnCalculatorService {
  constructor(private _fb: FormBuilder, private _utilService: UtilService) {}

  public createTPNForm(formData: TPNFormInterface) {
    return this._fb.group(
      {
        step1: this._createStep1(formData.step1),
        step2: this._createStep2(formData.step2),
        step3: this._createStep3(formData.step3),
        step4: this._createStep4(formData.step4),
      },
      {
        validators: [TPNValueExceededValidator],
      }
    );
  }

  private _createStep1(data: TPNStep1Interface) {
    return this._fb.group({
      weight: this._fb.control(data.weight, [
        Validators.required,
        Validators.min(0),
      ]),
      requiredTFI: this._fb.control(data.requiredTFI, [
        Validators.required,
        Validators.min(0),
      ]),
      feeds: this._fb.control(data.feeds, [Validators.min(0)]),
      feedType: this._fb.control(data.feedType, [Validators.min(0)]),
      drugs: this._fb.control(data.drugs, [Validators.min(0)]),
      infusions: this._fb.control(data.infusions, [Validators.min(0)]),
      transfusions: this._fb.control(data.transfusions, [Validators.min(0)]),
      others: this._fb.control(data.others, [Validators.min(0)]),
    });
  }

  private _createStep2(data: TPNStep2Interface) {
    return this._fb.group({
      aminoAcid: this._fb.control(data.aminoAcid, [Validators.min(0)]),
      lipids: this._fb.control(data.lipids, [Validators.min(0)]),
      hmf: this._fb.control(data.hmf, [Validators.max(20), Validators.min(0)]),
    });
  }

  private _createStep3(data: TPNStep3Interface) {
    return this._fb.group({
      sodium: this._fb.control(data.sodium, [Validators.min(0)]),
      potassium: this._fb.control(data.potassium, [Validators.min(0)]),
      calcium: this._fb.control(data.calcium, [Validators.min(0)]),
      phosphate: this._fb.control(data.phosphate, [Validators.min(0)]),
      multivitamin: this._fb.control(data.multivitamin, [Validators.min(0)]),
      minerals: this._fb.control(data.minerals, [Validators.min(0)]),
    });
  }

  private _createStep4(data: TPNStep4Interface) {
    return this._fb.group({
      totalFluidDextrose: this._fb.control({
        value: data.totalFluidDextrose,
        disabled: true,
      }),
      dextroseInfusionPct: this._fb.control(data.dextroseInfusionPct, [
        Validators.min(0),
      ]),
      dextroseIsoPct: this._fb.control(data.dextroseIsoPct),
      dextrose25Pct: this._fb.control({
        value: data.dextrose25Pct,
        disabled: true,
      }),
      dextroseAAAdditives: this._fb.control({
        value: data.dextroseAAAdditives,
        disabled: true,
      }),
      lipidsVitamins: this._fb.control({
        value: data.lipidsVitamins,
        disabled: true,
      }),
      gir: this._fb.group({
        fluid: this._fb.control({
          value: data.gir.fluid,
          disabled: true,
        }),
        feeds: this._fb.control({
          value: data.gir.feeds,
          disabled: true,
        }),
        medicineInfusion: this._fb.control({
          value: data.gir.medicineInfusion,
          disabled: true,
        }),
      }),
      nutritions: this._fb.group({
        totalCalories: this._fb.control({
          value: data.nutritions.totalCalories,
          disabled: true,
        }),
        protienIntake: this._fb.control({
          value: data.nutritions.protienIntake,
          disabled: true,
        }),
        lipids: this._fb.control({
          value: data.nutritions.lipids,
          disabled: true,
        }),
        calcium: this._fb.control({
          value: data.nutritions.calcium,
          disabled: true,
        }),
        phosphorus: this._fb.control({
          value: data.nutritions.phosphorus,
          disabled: true,
        }),
        vitaminA: this._fb.control({
          value: data.nutritions.vitaminA,
          disabled: true,
        }),
        vitaminD: this._fb.control({
          value: data.nutritions.vitaminD,
          disabled: true,
        }),
        totalNPC: this._fb.control({
          value: data.nutritions.totalNPC,
          disabled: true,
        }),
        npcRatio: this._fb.control({
          value: data.nutritions.npcRatio,
          disabled: true,
        }),
      }),
    });
  }

  public gettotalDrugAndInfusion(formData: TPNStep1Interface): number {
    return (
      formData.drugs +
      formData.infusions +
      formData.transfusions +
      formData.others
    );
  }

  public getTotalDInGrams(
    totalFluidDextrose: number,
    dextroseIsoPctVal
  ): number {
    return (dextroseIsoPctVal * totalFluidDextrose) / 100;
  }

  public getDextrose25Pct(
    totalFluidDextrose: number,
    totalDInGrams: number,
    dextroseIsoPctVal
  ): number {
    return this._utilService.getDecimalsNum(
      (totalFluidDextrose * dextroseIsoPctVal) / (dextroseIsoPctVal - 0.25) +
        totalDInGrams / (0.25 - dextroseIsoPctVal)
    );
  }

  public getDextroseIsoVol(
    totalFluidDextrose: number,
    totalDInGrams: number,
    dextroseIsoPctVal
  ): number {
    return this._utilService.getDecimalsNum(
      (totalFluidDextrose * 0.25) / (0.25 - dextroseIsoPctVal) +
        totalDInGrams / (dextroseIsoPctVal - 0.25)
    );
  }

  public setFinalCaclculation(
    tpnForm: FormGroup,
    totalFluidDextrose: number
  ): TPNFinalCalculationInterface {
    const dextroseIsoPct = tpnForm.get("step4").get("dextroseIsoPct").value;
    const dextroseInfusionPct = tpnForm
      .get("step4")
      .get("dextroseInfusionPct").value;
    const dextroseIsoPctVal = dextroseIsoPct * 0.01;
    const totalDInGrams = this.getTotalDInGrams(
        totalFluidDextrose,
        dextroseInfusionPct
      ),
      dextrose25Pct = this.getDextrose25Pct(
        totalFluidDextrose,
        totalDInGrams,
        dextroseIsoPctVal
      ),
      dextroseIsoVol = this.getDextroseIsoVol(
        totalFluidDextrose,
        totalDInGrams,
        dextroseIsoPctVal
      ),
      dextroseAAAdditives = this.getDextroseAAAdditives(
        tpnForm,
        totalFluidDextrose
      ),
      lipidsVitamins = this.getLipidsVitamins(tpnForm),
      gir = this.getGIRfluid(tpnForm, totalDInGrams),
      nutritions = this.getNutrition(tpnForm.value, totalDInGrams);
    tpnForm.patchValue(
      {
        step4: {
          totalFluidDextrose,
          dextrose25Pct,
          dextroseAAAdditives,
          lipidsVitamins,
          gir,
          nutritions,
        },
      },
      { emitEvent: false, onlySelf: true }
    );
    return {
      dextroseIsoVol: `${dextroseIsoVol} ml/day`,
      dextroseRate: `${this._utilService.getDecimalsNum(
        (dextrose25Pct + dextroseIsoVol) / 24
      )} ml/hr`,
      dextroseAAAdditivesRate: `${this._utilService.getDecimalsNum(
        dextroseAAAdditives / 24
      )} ml/hr`,
      lipidsVitaminsRate: `${this._utilService.getDecimalsNum(
        lipidsVitamins / 24
      )} ml/hr`,
    };
  }

  getLipidsVitamins(tpnForm: FormGroup) {
    const tpnFormValue: TPNFormInterface = tpnForm.value;
    const lipidsVolume = getTotalLipids(tpnFormValue);
    const {
      step3: { multivitamin },
    } = tpnFormValue;
    return this._utilService.getDecimalsNum(lipidsVolume + multivitamin);
  }

  getDextroseAAAdditives(tpnForm: FormGroup, totalFluidDextrose: number) {
    const tpnFormValue: TPNFormInterface = tpnForm.value;
    const aminoAcidVolume = getTotalAA(tpnFormValue);
    const {
      step3: { sodium, potassium, calcium },
    } = tpnFormValue;
    return this._utilService.getDecimalsNum(
      totalFluidDextrose + aminoAcidVolume + sodium + potassium + calcium
    );
  }

  getGIRfluid(tpnForm: FormGroup, totalDInGrams: number) {
    const tpnFormValue: TPNFormInterface = tpnForm.value;
    const {
      step1: { weight, feeds, feedType },
    } = tpnFormValue;
    const totSugarGiven = (+feeds * feedTypeSugarMap[feedType]) / 100;
    return {
      fluid: this._utilService.getDecimalsNum(
        (totalDInGrams * 1000) / 1440 / +weight
      ),
      feeds: this._utilService.getDecimalsNum(
        (totSugarGiven * 1000) / 1440 / +weight
      ),
      medicineInfusion: 0,
    };
  }

  getNutrition(
    tpnFormValue: TPNFormInterface,
    totalDInGrams: number
  ): NutritionInterface {
    const {
      step1: { feedType, feeds, weight },
      step2: { hmf, lipids, aminoAcid },
    } = tpnFormValue;

    const totalNPCExternal =
      ((nutritionCalcMap[feedType][FinalCalcFieldEnum.TOTAL_CALORIES] -
        nutritionCalcMap[feedType][FinalCalcFieldEnum.PROTIEN] * 4) *
        feeds) /
      100;
    const hmfProtien =
      nutritionCalcMap[FeedingSupplementsEnum.HMF][FinalCalcFieldEnum.PROTIEN] *
      hmf;
    const calorieProtienHMF =
      nutritionCalcMap[FeedingSupplementsEnum.HMF][
        FinalCalcFieldEnum.TOTAL_CALORIES
      ] * hmf;
    const totalNPCFortification = calorieProtienHMF - hmfProtien * 4;
    const gmOfDextrose = totalDInGrams * 3.4;
    const totalNPCFromLipids =
      9 *
      (this.calculateNutritionalField({
        field: FinalCalcFieldEnum.LIPIDS,
        feeds,
        feedType,
        hmf,
      }) +
        lipids * weight * 5 * 0.2);
    const totalNPC =
      totalNPCExternal +
      totalNPCFortification +
      gmOfDextrose +
      totalNPCFromLipids;
    const N =
      ((feeds * nutritionCalcMap[feedType][FinalCalcFieldEnum.PROTIEN]) / 100 +
        hmfProtien +
        aminoAcid * weight) /
      6.25;
    const NPCNRatio = N == 0 ? null : totalNPC / N;
    return {
      ...this.nutrionCalculation(tpnFormValue, gmOfDextrose),
      lipids: this._utilService.getDecimalsNum(
        totalNPCFromLipids / (0.9 * weight)
      ),
      totalNPC: this._utilService.getDecimalsNum(totalNPC),
      npcRatio: this._utilService.getDecimalsNum(NPCNRatio),
    };
  }

  public nutrionCalculation(
    tpnFormValue: TPNFormInterface,
    gmOfDextrose: number
  ): NutritionCalculationType {
    const {
      step1: { feedType, feeds, weight },
      step2: { hmf, aminoAcid },
    } = tpnFormValue;
    const {
      step3: { phosphate },
    } = getTpnFluidConsumedInfo(tpnFormValue);
    return {
      totalCalories: this._utilService.getDecimalsNum(
        (this.calculateNutritionalField({
          field: FinalCalcFieldEnum.TOTAL_CALORIES,
          feeds,
          feedType,
          hmf,
        }) +
          gmOfDextrose) /
          weight
      ),
      protienIntake: this._utilService.getDecimalsNum(
        (this.calculateNutritionalField({
          field: FinalCalcFieldEnum.PROTIEN,
          feeds,
          feedType,
          hmf,
        }) +
          aminoAcid * weight) /
          weight
      ),
      calcium: this._utilService.getDecimalsNum(
        this.calculateNutritionalField({
          field: FinalCalcFieldEnum.CALCIUM,
          feeds,
          feedType,
          hmf,
        }) / weight
      ),
      phosphorus: this._utilService.getDecimalsNum(
        (93 * phosphate +
          this.calculateNutritionalField({
            field: FinalCalcFieldEnum.PHOSPHORUS,
            feeds,
            feedType,
            hmf,
          })) /
          weight
      ),
      vitaminA: this._utilService.getDecimalsNum(
        this.calculateNutritionalField({
          field: FinalCalcFieldEnum.VITAMIN_A,
          feeds,
          feedType,
          hmf,
        }) / weight
      ),
      vitaminD: this._utilService.getDecimalsNum(
        this.calculateNutritionalField({
          field: FinalCalcFieldEnum.VITAMIN_D,
          feeds,
          feedType,
          hmf,
        }) / weight
      ),
    };
  }

  calculateNutritionalField({
    field,
    feeds,
    feedType,
    hmf,
  }: NutrFieldCalInterface): number {
    const hmfCalc = nutritionCalcMap[FeedingSupplementsEnum.HMF][field] * hmf;
    const isDivide100Arr = [
      FinalCalcFieldEnum.TOTAL_CALORIES,
      FinalCalcFieldEnum.PROTIEN,
      FinalCalcFieldEnum.LIPIDS,
    ];
    return (
      (feeds * nutritionCalcMap[feedType][field]) / 100 +
      (isDivide100Arr.includes(field) ? hmfCalc : hmfCalc / 100)
    );
  }
}
