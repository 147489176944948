import React from "react";
import { Provider } from "react-redux";
import store from "./reactStore/reduxStore";
import ShowIcdModal from "./showIcdModal/showIcdModal";
import RefreshModal from "./refreshModal/refresh-modal";
import TaskListTable from "./TaskListTable";
import TaskListPopUp from "./TaskListPopUp";
import AddTaskPopUp from "./AddTaskPopUp";

interface AppProps {
  onClose?: () => void;
  showModalType: "ICD" | "Refresh" | "TaskListTable" | "TaskListPopUp" | "AddTaskPopUp"; // Add a prop to determine which modal to show
  version?: string;
  patients$?: any;
  users$?: any;
  patientHeader$?: any;
}

const App: React.FC<AppProps> = ({ onClose, showModalType, version, patients$, users$, patientHeader$ }) => (
  <Provider store={store}>
    <div>
      {/* Conditionally render based on the modal type */}
      {showModalType === "ICD" && <ShowIcdModal onClose={onClose} />}
      {showModalType === "Refresh" && version && <RefreshModal version={version} />}
      {showModalType === "TaskListTable" && patients$ && users$ && <TaskListTable patients$={patients$} users$={users$} />}
      {showModalType === "TaskListPopUp" && patientHeader$ && <TaskListPopUp onClose={onClose} patientHeader$={patientHeader$} />}
      {showModalType === "AddTaskPopUp" && <AddTaskPopUp onClose={onClose} patientHeader$={patientHeader$} />}
    </div>
  </Provider>
);

export default App;
