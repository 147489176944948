<form [formGroup]="patientNotesForm" class="cp-flex-container">
  <div class="note-main__right-sec">
    <app-aira-btn
      class="note-main__right-sec--item"
      [patient]="{
        cpmrn: CPMRN,
        encounters: encounters,
        note: {
          noteType: patientNotesForm.get('noteType').value,
          noteSubType: patientNotesForm.get('noteSubType').value
        }
      }"
    >
    </app-aira-btn>
    <div class="note-main__right-sec--item">
      <button mat-flat-button (click)="disclaimer()" class="button-secondary">
        Disclaimer
      </button>
    </div>

    <div title="Press Ctrl+Alt to turn on mic">
      <button
        [ngClass]="{
          'button-secondary white': !!augnitoService.augnitoBtnActive,
          'button-primary': !augnitoService.augnitoBtnActive
        }"
        cpAugnitoButton
        (micOffDialog)="augnitoService.micStatus($event)"
        (augnitoText)="augnitoService.augnitoText($event)"
        (partialText)="augnitoService.partialTextAugnitochk($event)"
        mat-flat-button
      >
        <img
          [src]="
            !augnitoService.augnitoBtnActive
              ? '../../../../assets/icons/swara.svg'
              : '../../../../assets/images/google-mic-seeklogo.com.svg'
          "
          alt="mic"
        />swaᴙa
      </button>
    </div>
    <div class="note-main__right-sec--item cp-ml-auto">
      <mat-checkbox
        class="mr-3"
        *ngIf="!!showImpactCases"
        formControlName="impactCase"
        >Impact case</mat-checkbox
      ><ng-template [ngxPermissionsOnly]="['chargeble_note']"
        ><mat-checkbox
          *ngIf="checkProgressOrEventNote && isHospitalNoteChargeable"
          formControlName="chargeable"
          >Chargeable</mat-checkbox
        ></ng-template
      >
    </div>
  </div>
  <div class="note-main--hyper-text">
    <div *ngIf="augnitoService.hyperText.show" id="hyperText" class="col-sm-12">
      {{ augnitoService.hyperText.val }}
    </div>
    <div
      *ngIf="augnitoService.cursorIndex"
      class="note-main__right-sec__branding"
    >
      swaᴙa<mat-divider vertical></mat-divider>Powered by Augnito.
    </div>
  </div>

  <ng-template [ngIf]="(noteCTA$ | async) != CTAType.EditAttest">
    <div [formGroup]="patientNotesForm" class="cp-flex-container">
      <ng-template [ngIf]="componentsFormArray?.length">
        <div
          class="note-edit cp-overflow-auto"
          formArrayName="components"
          (scroll)="scrollContainer()"
        >
          <ng-container
            *ngFor="let componentControl of componentsFormArray; let i = index"
            ><div [formGroupName]="i" class="editor" tabindex="i">
              <div class="editor__heading">
                {{ componentControl.get("displayName").value }}
                <div class="display-flex align-items-center gap-1">
                  <div
                    *ngIf="
                      componentControl.get('displayName').value == 'Diagnosis'
                    "
                    (click)="showReviewICD()"
                    class="showReviewICD-text"
                  >
                    {{ showText }}
                  </div>
                  <cp-save-template
                    [template]="
                      highlight || componentControl.get('value').value
                    "
                    [macros]="macros$ | async"
                  ></cp-save-template>
                </div>
              </div>
              <div
                id="quillSummary"
                [ngClass]="{
                  'one-quill': editorClass
                }"
                class="editor__quill"
              >
                <div
                  [ngClass]="
                    getTemplateHeightClass(
                      componentControl.get('displayName').value
                    )
                  "
                >
                  <cp-quill-editor
                    (input)="dispatchAutosave()"
                    #editor
                    formControlName="value"
                    (onSelectionChanged)="editorFocussed = i"
                    (onHighlight)="highlight = $event"
                    [macros]="macros$ | async"
                  ></cp-quill-editor>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template [ngTemplateOutlet]="addendumArray"></ng-template>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="(noteCTA$ | async) == CTAType.EditAttest">
    <div [formGroup]="patientNotesForm" class="cp-flex-container">
      <ng-template [ngIf]="componentsFormArray?.length">
        <div class="note-edit cp-overflow-auto" formArrayName="components">
          <ng-container
            *ngFor="let componentControl of componentsFormArray; let i = index"
          >
            <div [innerHtml]="componentControl.value.value"></div>
          </ng-container>
          <ng-template [ngTemplateOutlet]="addendumArray"></ng-template>
          <ng-template [ngTemplateOutlet]="attestQuill"></ng-template>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <cp-note-action
    [attestationStatusMsg]="attestationStatusMsg"
    [patientNotesForm]="patientNotesForm"
    [disableActionButtons]="disableActionButtons"
  ></cp-note-action>

  <!-- Addendum Note Editor -->
  <ng-template #addendumArray>
    <form class="note" [formGroup]="patientNotesForm">
      <div class="editor" tabindex="8" *ngIf="addendumArrays.length > 0">
        <div class="editor__heading">
          <div>Addendum notes</div>
          <!-- <div>Copy</div> -->
        </div>
        <div id="quillAddendum" class="editor__quill">
          <div formArrayName="addendum">
            <ng-container
              *ngFor="let addendumnNote of addendumArrays; let i = index"
            >
              <div [formGroupName]="i">
                <cp-quill-editor formControlName="note"></cp-quill-editor>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <!-- Attest Quill -->
  <ng-template #attestQuill>
    <div class="note-edit justify-content-end">
      <div class="editor__heading">
        <div>Physician note</div>
        <!-- <div>Copy</div> -->
      </div>
      <div class="editor">
        <div id="quillAddendum" class="editor__quill">
          <cp-quill-editor
            [formControl]="attestationStatusMsg"
          ></cp-quill-editor>
        </div>
      </div>
    </div>
  </ng-template>
</form>

<div #icdModalPopUp></div>
