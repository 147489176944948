import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromMacrosActions from "../actions/macros.actions";
import { MacroInterface } from "@shared-modules/components/quill-editor";

export interface MacrosState extends EntityState<MacroInterface> {
  success: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  updateLoading: boolean;
  deleteOrUpdateError: string;
  createError: string;
}

export const macrosAdaptor = createEntityAdapter<MacroInterface>({
  selectId: selectNoteId,
});

export const initialState: MacrosState = macrosAdaptor.getInitialState({
  success: false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
  deleteOrUpdateError: "",
  createError: "",
});

export function selectNoteId(a: MacroInterface): string {
  return a._id;
}

const _macrosReducer = createReducer(
  initialState,
  on(FromMacrosActions.loadNoteMacrosSuccess, (state, { macros }) =>
    macrosAdaptor.setAll(macros, state)
  ),
  on(FromMacrosActions.createNoteMacros, (state) => ({
    ...state,
    success: false,
    createLoading: true,
  })),
  on(FromMacrosActions.createNoteMacrosSuccess, (state, { macro }) =>
    macrosAdaptor.addOne(macro, {
      ...state,
      success: true,
      createLoading: false,
    })
  ),
  on(FromMacrosActions.createNoteMacrosError, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
  })),
  on(FromMacrosActions.updateNoteMacros, (state) => ({
    ...state,
    success: false,
    updateLoading: true,
  })),
  on(FromMacrosActions.updateNoteMacrosSuccess, (state, { macro }) =>
    macrosAdaptor.setOne(macro, {
      ...state,
      success: true,
      updateLoading: false,
    })
  ),
  on(FromMacrosActions.updateNoteMacrosError, (state, { error }) => ({
    ...state,
    updateLoading: false,
    deleteOrUpdateError: error,
  })),
  on(FromMacrosActions.deleteNoteMacros, (state) => ({
    ...state,
    success: false,
    deleteLoading: true,
  })),
  on(FromMacrosActions.deleteNoteMacrosSuccess, (state) => ({
    ...state,
    success: true,
    deleteLoading: false,
  })),
  on(FromMacrosActions.deleteNoteMacrosSuccess, (state, { id }) =>
    macrosAdaptor.removeOne(id, {
      ...state,
      success: true,
      createLoading: false,
    })
  ),
  on(FromMacrosActions.deleteNoteMacrosError, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteOrUpdateError: error,
  }))
);

export function reducer(state: MacrosState, action: Action) {
  return _macrosReducer(state, action);
}
