import { configureStore } from "@reduxjs/toolkit";
import healthcheckReducer from "./reactSlice/healthcheckSlice";
import icdNotesReducer from "./reactSlice/icdNotesSlice";
import userReducer from "./reactSlice/usersSlice";
import addTaskReducer from "./reactSlice/usersSlice";
import taskReducer from "./reactSlice/taskSlice";

const store = configureStore({
  reducer: {
    healthcheck: healthcheckReducer,
    icdCodes: icdNotesReducer,
    users: userReducer,
    addTask: addTaskReducer,
    tasks: taskReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
