import { Component } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { patientTypeLabels } from "src/app/data/patientType-data";

@Component({
  selector: "cp-patient-type",
  template: `
    <div class="mb" [formGroup]="form">
      <cp-choice-list
        [options]="patientTypes"
        formControlName="patientType"
        label="Patient type*:"
        labelClass="cp-field-label"
        type="radio"
      ></cp-choice-list>
      <div *ngIf="patientTypeError" class="error-text">
        Invalid patient type
      </div>
    </div>
  `,
})
export class PatientTypeComponent {
  public patientTypes = patientTypeLabels;

  constructor(public controlContainer: ControlContainer) {}

  get form() {
    return this.controlContainer.control;
  }

  get patientTypeError() {
    return (
      this.form?.parent?.hasError("patientTypeMismatch") &&
      this.form.get("patientType").dirty
    );
  }
}
