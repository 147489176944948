<div class="stepper-wrapper">
  <div
    class="stepper-item"
    [class.completed]="currentStep > i"
    *ngFor="let step of steps; let i = index"
  >
    <div class="step-counter" (click)="onStepChange(i + 1)">{{ i + 1 }}</div>
    <div class="step-name cp-text-normal" (click)="onStepChange(i + 1)">
      {{ step }}
    </div>
  </div>
</div>
