import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  handlePreviousPage,
  handleNextPage,
}) => {
  const isPreviousDisabled = currentPage === 0;
  const isNextDisabled = currentPage === totalPages;

  return (
    <div className="tw-flex tw-justify-end tw-items-center">
      <div className="tw-mr-4">
        <span className="tw-text-sm">Tasks per page: {itemsPerPage}</span>
      </div>
      <div className="tw-mr-4 tw-text-center">
        <span className="tw-text-sm">
          {currentPage + 1} of {totalPages + 1}
        </span>
      </div>
      <div>
        <button
          onClick={handlePreviousPage}
          disabled={isPreviousDisabled}
          className="tw-px-2 tw-py-1 tw-rounded-md tw-disabled:tw-opacity-50"
        >
          <img
            src={isPreviousDisabled ? "assets/icons/chevron_left_grey.svg" : "assets/icons/chevron_left.svg"}
            alt="Previous"
            className="tw-w-5 tw-h-5"
          />
        </button>
        <button
          onClick={handleNextPage}
          disabled={isNextDisabled}
          className="tw-px-2 tw-py-1 tw-rounded-md tw-disabled:tw-opacity-50"
        >
          <img
            src={isNextDisabled ? "assets/icons/chevron_right_grey.svg" : "assets/icons/chevron_right.svg"}
            alt="Next"
            className="tw-w-5 tw-h-5"
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;