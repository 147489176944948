<div class="blood-form-comp" [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>

  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>

  <div class="blood-form-specific-inputs">
    <div class="title-cont" *ngIf="config.title.display">
      <label class="cp-field-label">Title*</label>
      <mat-select formControlName="title" class="order-select">
        <mat-option *ngFor="let bOption of titles" [value]="bOption">
          {{ bOption }}
        </mat-option>
      </mat-select>
      <span
        class="error-text"
        *ngIf="
          utilService.hasError(form, 'title', 'required') &&
          (title.touched || title.dirty)
        "
      >
        Title is required
      </span>
    </div>

    <div class="quantity-unit-cont">
      <div class="quantity">
        <label class="cp-field-label">Quantity*</label>
        <input
          matInput
          type="text"
          class="cp-input-field"
          formControlName="quantity"
          placeholder="unit"
        />
      </div>
      <div class="unit">
        <label class="cp-field-label">Unit*</label>
        <mat-select formControlName="quantityUnit" class="order-select">
          <mat-option *ngFor="let unit of units" [value]="unit">{{
            unit
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div
      class="error-text"
      *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)"
    >
      <span *ngIf="quantity.errors.required">Quantity is required.</span>
      <span *ngIf="quantity.errors.maxLength">Max 20 digits.</span>
      <span *ngIf="quantity.errors.pattern">Only numbers are allowed.</span>
      <span *ngIf="quantity.errors?.negativeError"
        >Quantity should be greater than 0.</span
      >
    </div>
    <div
      class="error-text"
      *ngIf="
        quantityUnit.invalid && (quantityUnit.dirty || quantityUnit.touched)
      "
    >
      <span *ngIf="quantityUnit.errors.required">Unit is required.</span>
    </div>
  </div>
  <div style="margin-bottom: 0.8rem" class="bedside-order">
    <button
      *ngIf="showBedsideForm"
      mat-flat-button
      tabindex="-1"
      type="button"
      onclick="this.blur()"
      (click)="onBedsideChange()"
      [ngClass]="
        form.get('bedsideOrder').value ? 'button-primary' : 'button-secondary'
      "
    >
      Bedside Order
    </button>
  </div>
  <div class="freq-cont">
    <app-order-frequency
      [config]="config"
      [formType]="formType"
    ></app-order-frequency>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>
