<div class="procedure-form-comp" [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>
  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
  <div class="name-type-cont">
    <div class="type" *ngIf="config.pType.display">
      <label class="cp-field-label">Type*</label>
      <mat-select
        class="order-select"
        (selectionChange)="foleySelected($event.target.value)"
        formControlName="pType"
      >
        <mat-option *ngFor="let type of types" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
      <span
        class="error-text"
        *ngIf="
          utilService.hasError(form, 'pType', 'required') &&
          (pType.touched || pType.dirty)
        "
      >
        Type is required
      </span>
    </div>

    <div class="name" *ngIf="config.name.present">
      <label class="cp-field-label">Name</label>
      <input
        matInput
        class="cp-input-field"
        type="text"
        formControlName="name"
      />

      <span
        class="error-text"
        *ngIf="
          utilService.hasError(form, 'name', 'maxlength') &&
          (name.touched || name.dirty)
        "
      >
        Max 100 characters
      </span>
    </div>
    <div
      class="bedside-order"
      *ngIf="
        config.bedsideOrder.present && formType === 'new' && showBedsideForm
      "
    >
      <button
        mat-flat-button
        tabindex="-1"
        type="button"
        onclick="this.blur()"
        (click)="onBedsideChange()"
        [ngClass]="
          form.get('bedsideOrder').value ? 'button-primary' : 'button-secondary'
        "
      >
        Bedside Order
      </button>
    </div>
  </div>

  <div class="site-laterality-cont">
    <div class="site-laterality">
      <div class="site">
        <label class="cp-field-label">Site*</label>
        <input
          matInput
          formControlName="site"
          class="cp-input-field"
          type="text"
        />
      </div>
      <div class="laterality">
        <label class="cp-field-label">Laterality</label>
        <mat-select class="order-select" formControlName="laterality">
          <mat-option *ngFor="let side of sides" [value]="side">{{
            side
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="site-error">
      <span
        class="error-text"
        *ngIf="
          utilService.hasError(form, 'site', 'required') &&
          (site.touched || site.dirty)
        "
      >
        Site is required
      </span>
    </div>
  </div>

  <div class="freq-cont">
    <app-order-frequency
      [config]="config"
      [formType]="formType"
    ></app-order-frequency>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>
