import { Component, Input, inject } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import { dextroseIsoPctOptions } from "src/app/tpn-calculator/data";
import { TPNFinalCalculationInterface } from "src/app/tpn-calculator/models";

@Component({
  selector: "cp-tpn-step4",
  templateUrl: "./tpn-step4.component.html",
})
export class TpnStep4Component {
  private _controlContainer = inject(ControlContainer);

  @Input() finalCalculationInfo: TPNFinalCalculationInterface;

  public dextroseIsoPctOptions = dextroseIsoPctOptions;

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this._controlContainer.control;
  }

  // totalFluidDextrose
  get totalFluidDextrose(): AbstractControl {
    return this.form.get("totalFluidDextrose");
  }

  // dextroseInfusionPct
  get dextroseInfusionPct(): AbstractControl {
    return this.form.get("dextroseInfusionPct");
  }

  // dextroseIsoPct
  get dextroseIsoPct(): AbstractControl {
    return this.form.get("dextroseIsoPct");
  }

  // dextrose25Pct
  get dextrose25Pct(): AbstractControl {
    return this.form.get("dextrose25Pct");
  }

  // dextroseAAAdditives
  get dextroseAAAdditives(): AbstractControl {
    return this.form.get("dextroseAAAdditives");
  }

  // lipidsVitamins
  get lipidsVitamins(): AbstractControl {
    return this.form.get("lipidsVitamins");
  }

  // gir
  get gir(): AbstractControl {
    return this.form.get("gir");
  }

  // fluid
  get fluid(): AbstractControl {
    return this.gir.get("fluid");
  }

  // feeds
  get feeds(): AbstractControl {
    return this.gir.get("feeds");
  }

  // medicineInfusion
  get medicineInfusion(): AbstractControl {
    return this.gir.get("medicineInfusion");
  }

  // nutritions
  get nutritions(): AbstractControl {
    return this.form.get("nutritions");
  }

  get totalCalories(): AbstractControl {
    return this.nutritions.get("totalCalories");
  }

  get protienIntake(): AbstractControl {
    return this.nutritions.get("protienIntake");
  }

  get lipids(): AbstractControl {
    return this.nutritions.get("lipids");
  }

  get calcium(): AbstractControl {
    return this.nutritions.get("calcium");
  }

  get phosphorus(): AbstractControl {
    return this.nutritions.get("phosphorus");
  }

  get vitaminA(): AbstractControl {
    return this.nutritions.get("vitaminA");
  }

  get vitaminD(): AbstractControl {
    return this.nutritions.get("vitaminD");
  }

  get totalNPC(): AbstractControl {
    return this.nutritions.get("totalNPC");
  }

  get npcRatio(): AbstractControl {
    return this.nutritions.get("npcRatio");
  }
}
