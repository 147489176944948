import { Component, inject, Input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TemplateFormComponent } from "../template-form/template-form.component";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { MacroInterface } from "@shared-modules/components/quill-editor";
import { Subject } from "rxjs";

@Component({
  selector: "cp-save-template",
  templateUrl: "./save-template.component.html",
})
export class SaveTemplateComponent implements OnDestroy {
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  readonly dialog = inject(MatDialog);

  public unsubscribe$ = new Subject();

  @Input() template: string = "";

  @Input() macros: MacroInterface[];

  openDialog(isUpdate: boolean = false): void {
    this.dialog.open(TemplateFormComponent, {
      width: "40vw",
      data: {
        template: this.template,
        macros: this.macros,
        isUpdate,
      },
    });
  }
}
