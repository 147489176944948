const Loader = () => {
  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  const loaderStyle = {
    width: '30px',
    height: '30px',
    padding: '2px',
    aspectRatio: '1',
    borderRadius: '50%',
    background: '#25b09b',
    WebkitMask: 'conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box',
    mask: 'conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box',
    WebkitMaskComposite: 'source-out',
    maskComposite: 'subtract',
    animation: 'rotate 1s infinite linear',
  };

  const keyframesStyle = `
    @keyframes rotate {
      to {
        transform: rotate(1turn);
      }
    }
  `;

  return (
    <div style={loaderContainerStyle}>
      <style>{keyframesStyle}</style>
      <div style={loaderStyle}></div>
    </div>
  );
};

export default Loader;
