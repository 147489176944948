import React, { useRef, useState, useEffect } from "react";

const EllipsisTooltip = ({ text, maxWidthClass }) => {
  const textRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsEllipsisActive(element.scrollWidth > element.offsetWidth);
    }
  }, [text]);

  return (
    <div className="tw-relative tw-inline-block tw-group">
      {/* Truncated Text */}
      <p
        ref={textRef}
        className={`tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap ${maxWidthClass}`}
      >
        {text || "-"}
      </p>

      {/* Tooltip - Shown on hover if text is truncated */}
      {isEllipsisActive && (
        <div className="tw-absolute tw-left-0 tw-z-10 tw-top-full tw-mt-1 tw-px-2 tw-py-1 tw-text-xs tw-text-white tw-bg-others-300 tw-rounded tw-shadow-lg tw-whitespace-normal tw-hidden group-hover:tw-block">
          <span className="tw-whitespace-normal tw-break-words">
            {text || "-"}
          </span>
        </div>
      )}
    </div>
  );
};

export default EllipsisTooltip;