import { Component, OnInit, inject } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import { Subject, merge } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UtilService } from "src/app/services/util.service";
import { feedTypes } from "src/app/tpn-calculator/data/form.data";

@Component({
  selector: "cp-tpn-step1",
  templateUrl: "./tpn-step1.component.html",
})
export class TpnStep1Component implements OnInit {
  totTFI: number = 0;
  private _utils: UtilService = inject(UtilService);
  ngOnInit(): void {
    merge(this.weight.valueChanges, this.requiredTFI.valueChanges)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.setTotalTFI();
      });
    this.setTotalTFI();
  }
  setTotalTFI() {
    this.totTFI = this._utils.getDecimalsNum(
      this.weight.value * this.requiredTFI.value
    );
  }
  public feedTypes: string[] = feedTypes;
  public _controlContainer = inject(ControlContainer);

  private unsubscribe$ = new Subject();

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this._controlContainer.control;
  }

  get weight() {
    return this.form.get("weight");
  }

  get requiredTFI() {
    return this.form.get("requiredTFI");
  }

  get feeds() {
    return this.form.get("feeds");
  }

  get drugs() {
    return this.form.get("drugs");
  }

  get feedType() {
    return this.form.get("feedType");
  }

  get infusions() {
    return this.form.get("infusions");
  }

  get transfusions() {
    return this.form.get("transfusions");
  }

  get others() {
    return this.form.get("others");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
