<div class="modal-popup">
  <div class="header">
    <div class="title" id="title">Make Request</div>
    <img
      src="assets/icons/close.svg"
      type="button"
      aria-describedby="title"
      matDialogClose
    />
  </div>
  <div class="body">
    <form [formGroup]="form">
      <div class="form-group">
        <label class="col-2 cp-field-label">Type</label>
        <div class="col-10">
          <mat-button-toggle-group formControlName="type">
            <mat-button-toggle [value]="requestType.Blood">
              Transfusion
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Comm">
              Comm
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Diet">
              Diet
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Lab">
              Lab
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Med">
              Med
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Procedure">
              Procedure
            </mat-button-toggle>
            <mat-button-toggle [value]="requestType.Vent">
              Vent
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="form-group">
        <label for="name" class="col-2 cp-field-label">Name</label>
        <div class="col-10">
          <input
            matInput
            type="text"
            class="cp-input-field"
            id="name"
            formControlName="name"
          />
        </div>
      </div>

      <div *ngIf="form.value.type == 'med'" class="form-group">
        <label for="brand" class="col-4 cp-field-label">Brand name</label>
        <div class="col-10">
          <input
            matInput
            type="text"
            class="cp-input-field"
            id="brand"
            formControlName="brand"
          />
        </div>
      </div>
    </form>
    <div class="error mb-2" *ngIf="orderableRequestFormError$ | async as error">
      <span *ngIf="name">{{ error }}</span>
    </div>
  </div>
  <div class="footer">
    <button
      mat-flat-button
      type="submit"
      class="button-primary"
      (click)="onSubmit()"
      matDialogClose
      [disabled]="!form.valid || (orderableRequestFormLoading$ | async)"
    >
      Submit
    </button>
    <button
      mat-flat-button
      class="button-secondary"
      type="button"
      matDialogClose
    >
      Cancel
    </button>
  </div>
</div>
