<div [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>
  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
  <div class="form-row">
    <div
      class="form-group col-md-6 d-md-flex flex-md-column justify-content-md-end"
      *ngIf="config.investigation.display"
    >
      <div *ngIf="!config.investigation.readOnly; else invBlock">
        <label class="cp-field-label">Investigation*</label>
        <input
          matInput
          formControlName="investigation"
          class="cp-input-field"
          type="text"
        />
        <span
          class="error"
          *ngIf="
            utilService.hasError(form, 'investigation', 'required') &&
            (investigation.touched || investigation.dirty)
          "
        >
          Investigation is required
        </span>
        <span
          class="error"
          *ngIf="
            utilService.hasError(form, 'investigation', 'maxlength') &&
            (investigation.touched || investigation.dirty)
          "
        >
          Max 500 characters
        </span>
      </div>
      <ng-template #invBlock>
        {{ investigation.value }}
      </ng-template>
    </div>
    <div
      class="form-group col-md-3 d-md-flex flex-md-column justify-content-md-end"
      *ngIf="
        config.bedsideOrder.present && formType === 'new' && showBedsideForm
      "
    >
      <button
        mat-flat-button
        tabindex="-1"
        type="button"
        onclick="this.blur()"
        (click)="onBedsideChange()"
        [ngClass]="
          form.get('bedsideOrder').value ? 'button-primary' : 'button-secondary'
        "
      >
        Bedside Order
      </button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-row col-md-12">
      <div
        class="form-group col-md-6 d-md-flex flex-md-column justify-content-md-end mb-0"
      >
        <label class="cp-field-label">Discipline</label>
        <input
          formControlName="discipline"
          class="cp-input-field"
          type="text"
        />
      </div>
      <div
        class="form-group col-md-6 d-md-flex flex-md-column justify-content-md-end mb-0"
      >
        <label class="cp-field-label">Specimen Type</label>
        <mat-select class="order-select" formControlName="specimenType">
          <mat-option *ngFor="let type of specimenTypes" [value]="type">
            {{ type }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div class="form-row col-md-12">
      <div class="form-group col-md-6">
        <span
          class="error"
          *ngIf="
            utilService.hasError(form, 'discipline', 'maxlength') &&
            (discipline.touched || discipline.dirty)
          "
        >
          Max 100 characters
        </span>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <app-order-frequency
        [config]="config"
        [formType]="formType"
      ></app-order-frequency>
    </div>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>
