<div [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>
  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
  <div class="form-row">
    <div
      class="form-group col-md-5 d-md-flex flex-md-column justify-content-md-end"
      *ngIf="config.title.display"
    >
      <label class="cp-field-label">Title*</label>
      <input
        matInput
        type="text"
        formControlName="title"
        class="cp-input-field"
        required
      />
    </div>
    <div
      class="form-group col-md-3 d-md-flex flex-md-column justify-content-md-end"
      *ngIf="
        config.bedsideOrder.present && formType === 'new' && showBedsideForm
      "
    >
      <button
        mat-flat-button
        tabindex="-1"
        type="button"
        onclick="this.blur()"
        (click)="toggleStatus('bedsideOrder')"
        [ngClass]="
          form.get('bedsideOrder').value ? 'button-primary' : 'button-secondary'
        "
      >
        Bedside Order
      </button>
    </div>
    <div
      class="form-group col-md-2 d-md-flex flex-md-column justify-content-md-end"
    >
      <button
        mat-flat-button
        tabindex="-1"
        type="button"
        onclick="this.blur()"
        (click)="toggleStatus('gcs')"
        [ngClass]="
          form.get('gcs').value ? 'button-primary' : 'button-secondary'
        "
      >
        GCS
      </button>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <app-order-frequency
        [config]="config"
        [formType]="formType"
      ></app-order-frequency>
    </div>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>
