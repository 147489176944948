import { Pipe, PipeTransform } from "@angular/core";
import { DropdownInputInterface } from "@iris/dropdown";
import { MacroInterface } from "@shared-modules/components/quill-editor";

@Pipe({
  name: "macrosDropdown",
})
export class MacrosDropdownPipe implements PipeTransform {
  transform(macros: MacroInterface[]): string[] {
    return macros.map((macro) => macro.key);
  }
}
