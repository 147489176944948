import { FormGroup } from "@angular/forms";
import {
  TPNFluidInterface,
  TPNFormInterface,
  TPNStep1Interface,
} from "../models";

export function getpnValue(formData: TPNStep1Interface): number {
  return (
    formData.weight * formData.requiredTFI -
    gettotalDrugAndInfusion(formData) -
    formData.feeds
  );
}

export function gettotalDrugAndInfusion(formData: TPNStep1Interface) {
  return (
    formData.drugs +
    formData.infusions +
    formData.transfusions +
    formData.others
  );
}

export function getTotalLipids({
  step1: { weight },
  step2: { lipids },
}: TPNFormInterface): number {
  return +weight * +lipids * 5;
}

export function getTotalAA({
  step1: { weight },
  step2: { aminoAcid },
}: TPNFormInterface): number {
  return +weight * +aminoAcid * 10;
}

export function getTotalSodium({
  step1: { weight },
  step3: { sodium },
}: TPNFormInterface): number {
  return +weight * +sodium * 2;
}

export function getTotalPotassium({
  step1: { weight },
  step3: { potassium },
}: TPNFormInterface): number {
  return (+weight * +potassium) / 2;
}

export function getTotalCalcium({
  step1: { weight },
  step3: { calcium },
}: TPNFormInterface): number {
  return +weight * +calcium;
}

export function getTotalPhosphate({
  step1: { weight },
  step3: { phosphate },
}: TPNFormInterface): number {
  return +weight * +phosphate;
}

export function getTotalMultivitamin({
  step1: { weight },
  step3: { multivitamin },
}: TPNFormInterface): number {
  return +weight * +multivitamin;
}

export function getTotalMinerals({
  step1: { weight },
  step3: { minerals },
}: TPNFormInterface): number {
  return +weight * +minerals;
}

export function getTpnFluidConsumedInfo(
  tpnFormValue: TPNFormInterface
): TPNFluidInterface {
  return {
    step2: {
      aminoAcid: getTotalAA(tpnFormValue),
      lipids: getTotalLipids(tpnFormValue),
    },
    step3: {
      sodium: getTotalSodium(tpnFormValue),
      potassium: getTotalPotassium(tpnFormValue),
      calcium: getTotalCalcium(tpnFormValue),
      phosphate: getTotalPhosphate(tpnFormValue),
      multivitamin: getTotalMultivitamin(tpnFormValue),
      minerals: getTotalMinerals(tpnFormValue),
    },
  };
}

export function getTotalTpnConsumed(parentForm: FormGroup): number {
  const { step2, step3 } = getTpnFluidConsumedInfo(parentForm.value);
  let totalConsumedTPN = 0;
  Object.entries({ ...step2, ...step3 }).forEach(([_, value]) => {
    if (typeof value === "string")
      throw new Error("The volume value cant be string");
    totalConsumedTPN += value;
  });
  return totalConsumedTPN;
}
