import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { QuillEditorComponent } from "./components/quill-editor/quill-editor.component";
import { QuillInitDirective } from "./directives/quill-init.directive";
import { AutocompleteSelectionComponent } from "./components/autocomplete-selection/autocomplete-selection.component";
import { OverlayModule } from "@angular/cdk/overlay";

@NgModule({
  declarations: [
    QuillEditorComponent,
    QuillInitDirective,
    AutocompleteSelectionComponent,
  ],
  exports: [QuillEditorComponent],
  imports: [CommonModule, FormsModule, OverlayModule],
})
export class QuillEditorModule {}
