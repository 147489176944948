export interface Vitals {
  _id?: string;
  dayNum?: number;
  timestamp?: Date;
  image?: any;
  isVerified?: boolean;
  verifiedTime?: Date;
  dataBy?: string;

  //vitals
  daysTemperature?: number;
  daysTemperatureUnit?: string;
  daysAVPU?: string;
  daysPatPosition?: string;
  daysHR?: string;
  daysRR?: string;
  daysBP?: string;
  daysReasonBP?: string;
  daysMAP?: number;
  daysCVP?: number;
  daysSpO2?: string;
  daysFiO2?: string;

  //rass
  daysRass?: number;
  daysRassAssessDeliriumYN?: string;
  daysRassCAMICU?: boolean;
  daysRassAcuteYN?: string;
  daysRassFluctYN?: string;
  daysRassInattentionYN?: string;
  daysRassDisorganizedYN?: string;

  //pain
  daysPain?: number;

  //fall
  daysFalls?: number;
  daysFallsFallHistory?: number;
  daysFallsSecDiagnoses?: number;
  daysFallsAmbAssist?: number;
  daysFallsivTherapy?: number;
  daysFallsGait?: number;
  daysFallsMentalStatus?: number;

  //pupillary
  daysLeftPupillarySize?: number;
  daysRightPupillarySize?: number;
  daysLeftPupilaryCondition?: string;
  daysRightPupilaryCondition?: string;

  //gcs
  daysGCSverbal?: number;
  daysGCSmotor?: number;
  daysGCS?: number;
  daysGCSeyes?: number;

  //seizure
  daysSeizuresComment?: string;
  daysSeizuresPresent?: string;
  daysSeizuresFrequency?: string;
  seizures: { daysSeizuresFrequency?: string; daysSeizuresPresent?: string }; //temporarys

  //braden
  daysBraden?: number;
  daysBradenSensory?: number;
  daysBradenMoisture?: number;
  daysBradenActivity?: number;
  daysBradenMobility?: number;
  daysBradenNutrition?: number;
  daysBradenFriction?: number;

  // CRIB II
  daysCrib: {
    daysScore: number;
    daysGestation: number;
    daysWeight: number;
    daysGender: string;
    daysTemperature: number;
    daysBaseExcess: number;
  };

  // APGAR
  apgar: {
    apgarScore: number;
    activity: number;
    pulse: number;
    grimace: number;
    appearance: number;
    respiration: number;
  };

  // SNAPP II
  snap2: {
    meanBP: number;
    lowestTemp: number;
    po2Fio2Ratio: number;
    lowestSerumPh: number;
    multipleSeizures: Boolean;
    urineOutput: number;
    snap2Score: number;
    apgarScore: number;
    birthWeight: number;
    gestationalAge: number;
    smallForGestationalAge: string;
    expectedDeathPercentage: number;
    snappe2Score: number;
  };
  //thompson
  thompson: {
    tone: Number;
    conciousness: Number;
    fits: Number;
    posture: Number;
    moroReflex: Number;
    grasping: Number;
    sucking: Number;
    breathing: Number;
    fontanelle: Number;
    thompsonScore: Number;
  };
  //airway
  daysVentAirway?: string[];
  nasopharyngealAirway?: {
    daysVentTubeSize?: number;
  };
  oralAirway?: {
    daysVentTubeSize?: number;
  };
  biteBlock?: {
    daysVentTubeSize?: number;
  };
  cooksAirway?: {
    daysVentTubeSize?: number;
  };
  supraglotticAirway?: {
    daysSupraglotticAirway?: string;
    daysVentTubeSize?: number;
  };
  oralEndotrachealTube?: {
    daysVentCuff?: boolean;
    daysEttTieLevel?: number;
    daysVentTubeSize?: number;
    daysVentCuffPressure?: number;
    isClosedSuction?: boolean;
  };
  nasalEndotrachealTube?: {
    daysVentCuff?: boolean;
    daysEttTieLevel?: number;
    daysVentTubeSize?: number;
    daysVentCuffPressure?: number;
    isClosedSuction?: boolean;
  };
  tracheostomyTube?: {
    daysVentCuff?: boolean;
    daysVentTubeSize?: number;
    daysVentCuffPressure?: number;
    isClosedSuction?: boolean;
  };

  //therapy device
  daysTherapyDevice?: string[];
  daysOxygenFlow?: number;
  daysInvasiveMode?: string;
  daysVentBreathSequence?: string;
  daysVentI?: number;
  daysVentE?: number;
  daysVentMV?: number;
  daysVentMode?: string;
  daysVentPinsp?: number;
  daysVentPsupport?: number;
  daysVentPEEP?: number;
  daysVentPmean?: number;
  daysVentPpeak?: number;
  daysVentPplat?: number;
  daysVentRRset?: number;
  daysVentVt?: number;

  //invasive ventilation @sheethalkhan  11-05-2023
  //vent settings

  daysVentTi?: number;
  daysVentPip?: number;
  daysVentIFR?: number;
  daysVentTidalVolume?: number;
  daysVentOI?: number;
  daysVentOSI?: number;
  daysVentMeanMAP?: number;
  daysVentFrequency?: number;
  daysVentDeltaP?: number;

  // deprecated
  daysVentOralAirway?: string;
  daysIntType?: string;
  daysVentType?: string;
  daysVentSubMode?: string;
  daysVentTubeSize?: number;
  daysVentMAP?: number;
  daysVentTube?: string;
  daysFlowRate?: number;
  daysEttTieLevel?: number;
  daysVentCuff?: boolean;
  daysVentCuffPressure?: number;
}

export enum VitalRoutes {
  Vitals = "vitals",
  Assessment = "assessment",
  VitalPrint = "vital-print",
}
