<div [formGroup]="form">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Weight (kg)*:" [control]="weight">
        <cp-tpn-input formControlName="weight"> </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Required TFI (ml/kg/day)*:"
        [control]="requiredTFI"
      >
        <cp-tpn-input formControlName="requiredTFI" placeholder="0">
          <span inputInfo class="cp-ml-2"> {{ totTFI }} ml/day</span>
        </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Feeds (ml/day):" [control]="feeds">
        <cp-tpn-input formControlName="feeds" placeholder="0"></cp-tpn-input>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Feed type:" [control]="feedType">
        <cp-tpn-input
          [options]="feedTypes"
          formControlName="feedType"
          placeholder="0"
        >
        </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Drugs (ml/day):" [control]="drugs">
        <cp-tpn-input formControlName="drugs" placeholder="0"> </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Infusions (ml/day):" [control]="infusions">
        <cp-tpn-input
          formControlName="infusions"
          placeholder="0"
        ></cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Transfusions (ml/day):"
        [control]="transfusions"
      >
        <cp-tpn-input formControlName="transfusions" placeholder="0">
        </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Others (ml/day):" [control]="others">
        <cp-tpn-input formControlName="others" placeholder="0"> </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <ng-content></ng-content>
</div>
