import React from "react";

interface ConfirmationModalProps {
  onClose: () => void;
  title: string;
}

const AlertModal: React.FC<ConfirmationModalProps> = ({
  onClose,
  title,
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-inset-0 tw-bg-opacity-50 tw-bg-black tw-fixed tw-z-50">
      <div className="tw-bg-white tw-p-6 tw-w-[25rem] tw-rounded-sm">
        <p className="tw-text-sm">{title}</p>
        <div className="modal-actions tw-mt-4 tw-flex tw-justify-end">
          <button
            className="tw-bg-primary-400 tw-text-white tw-text-sm tw-px-3 tw-py-1 tw-rounded-sm hover:tw-bg-primary-500 transition-all"
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;