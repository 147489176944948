import React, { useState } from "react";

const Tooltip = ({ title, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="tw-relative tw-inline-block"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}

      {/* Tooltip content */}
      {showTooltip && title && (
        <div className="tw-absolute tw-z-10 tw-left-0 tw-top-full tw-mt-1 tw-px-2 tw-py-1 tw-text-xs tw-text-white tw-bg-others-300 tw-rounded tw-shadow-lg tw-max-w-xs tw-whitespace-normal">
          {title}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
