import { Component, Input, OnInit, inject } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import {
  IsTPNExceedErrorInterface,
  TPNFluidStep2Interface,
  TotalVolumeType,
} from "src/app/tpn-calculator/models";
import { limitExceedErrorState } from "src/app/tpn-calculator/utils";

@Component({
  selector: "cp-tpn-step2",
  templateUrl: "./tpn-step2.component.html",
})
export class TpnStep2Component implements OnInit {
  @Input("tpnVolumeInfo") tpnVolume: TPNFluidStep2Interface;
  @Input() isTPNConsumed: boolean = false;
  @Input() isTPNExcededErrorObj!: IsTPNExceedErrorInterface;

  lipidsValue: number = 0;
  aaValue: number;
  ngOnInit(): void {}

  public limitExceedErrorState = limitExceedErrorState;

  public get aminoAcidVolume(): TotalVolumeType {
    return this.tpnVolume.aminoAcid;
  }

  public get lipidsVolume(): TotalVolumeType {
    return this.tpnVolume.lipids;
  }

  private _controlContainer = inject(ControlContainer);

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this._controlContainer.control;
  }

  // aminoAcid
  get aminoAcid(): AbstractControl {
    return this.form.get("aminoAcid");
  }

  // aminoAcid
  get lipids(): AbstractControl {
    return this.form.get("lipids");
  }

  get hmf(): AbstractControl {
    return this.form.get("hmf");
  }

  get parentForm(): AbstractControl {
    return this.form.parent;
  }
}
