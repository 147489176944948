import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reduxStore";
import { environment } from "src/environments/environment";

interface TaskData {
  _id: string;
  Task: string;
  Priority: string;
  "Task Type": string;
  "Patient Name": string;
  Patient: string;
  "Patient Severity": string;
  "Bed no"?: string;
  "Hospital-unit"?: string;
  "Created-At"?: string;
  "Due-Date": string;
  Assignee: string;
  AssigneeName: string;
  Creator?: string;
  State?: string;
}

interface TaskState {
  list: TaskData[];
  totalPages: number;
  totalResults: number;
  error: string | null;
  isLoading: boolean;
}

interface FetchTasksPayload {
  page?: number;
  count?: number;
  sortBy?: string;
  orderBy?: string;
  filters?: Record<string, any>;
}

const initialState: TaskState = {
  list: [],
  totalPages: 0,
  totalResults: 0,
  error: null,
  isLoading: false,
};

const createTaskRequestBody = (options: FetchTasksPayload = {}) => ({
  opts: {
    page: options.page || 0,
    count: options.count || 50,
    sortBy: options.sortBy,
    orderBy: options.orderBy,
    ...options.filters,
  },
});

export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (payload: FetchTasksPayload, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const body = createTaskRequestBody(payload);

    try {
      const response = await fetch(`${environment.apiUrl}v1/tasks/getList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch tasks: ${response.statusText}`);
      }

      const data = await response.json();
      return data.data;
    } catch (err: any) {
      return rejectWithValue(
        err.message || "An unknown error occurred while fetching tasks"
      );
    }
  }
);

// Create the slice
const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    appendTask: (state, { payload }) => {
      console.log(state);
      state.list = [payload, ...state.list];
    },
    updateAssignee: (state, { payload }) => {
      const { _id, assignee } = payload;
      const updatedList = state.list.map((task) =>
        task._id === _id
          ? {
              ...task,
              assignee: {
                _id: assignee._id,
                name: assignee.name,
                email: assignee.email,
              },
            }
          : task
      );
      state.list = updatedList;
    },
    updateState: (state, { payload }) => {
      const { _id } = payload;
      const updatedList = state.list.map((task) =>
        task._id === _id
          ? {
              ...task,
              state,
            }
          : task
      );
      state.list = updatedList;
    },
    updateTask: (state, { payload }) => {
      const { _id, ...updatedFields } = payload;
      const updatedList = state.list.map((task) =>
        task._id === _id
          ? {
              ...task,
              ...updatedFields,
            }
          : task
      );
      state.list = updatedList;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.totalResults = payload.totalResult;
        state.totalPages = payload.totalResult
          ? Math.ceil(payload.totalResult / 50)
          : 1;
      })

      .addCase(fetchTasks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as string;
      });
  },
});

export const {
  updateAssignee,
  updateTask,
  updateState,
  appendTask,
  setError,
  setLoading,
} = tasksSlice.actions;

export const selectTasks = (state: RootState) => state.tasks;

export default tasksSlice.reducer;
