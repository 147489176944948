<div class="cp-h-100 cp-tpn-calculator cp-flex-container">
  <div class="cp-tpn-calculator__header cp-my-1 cp-mx-1">
    <div>
      <cp-stepper
        [steps]="steps"
        [currentStep]="tpnPage"
        (stepChange)="changePage($event)"
      >
      </cp-stepper>
    </div>
    <div class="cp-text-bold cp-color-dark-2 cp-mt-1">
      <span class="cp-big-font-2">Step {{ tpnPage }}</span>
      <span *ngIf="tpnPage > 1" class="cp-normal-font"
        >/Remaining TPN vol {{ totalTpnAvaiable }} ml/day</span
      >
    </div>
    <div class="cp-mt-1">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div
    class="cp-tpn-calculator__body cp-flex-container cp-mx-1"
    [ngSwitch]="tpnPage"
  >
    <form [formGroup]="tpnCalculatorForm" class="cp-mb-1">
      <cp-tpn-step1 *ngSwitchCase="1" formGroupName="step1">
        <div class="cp-mt-1">
          <span class="cp-color-dark"
            >The TPN volume is {{ totalTPN }} ml/day</span
          >
        </div>
        <div class="cp-mt-1" *ngIf="totalTPN < 0">
          <span class="error-text">The TPN value can't be negative.</span>
        </div>
      </cp-tpn-step1>
      <cp-tpn-step2
        *ngSwitchCase="2"
        formGroupName="step2"
        [tpnVolumeInfo]="getTpnVolumeStep2Info"
        [isTPNExcededErrorObj]="isTPNExcededErrorObj"
      ></cp-tpn-step2>
      <cp-tpn-step3
        *ngSwitchCase="3"
        formGroupName="step3"
        [tpnVolumeInfo]="getTpnVolumeStep3Info"
        [isTPNExcededErrorObj]="isTPNExcededErrorObj"
      ></cp-tpn-step3>
      <cp-tpn-step4
        *ngSwitchCase="4"
        [finalCalculationInfo]="finalCalculationInfo"
        formGroupName="step4"
      ></cp-tpn-step4>
      <div *ngSwitchDefault>Unable to load page</div>
    </form>
  </div>
  <div
    class="cp-tpn-calculator__footer display-flex justify-content-between cp-bg-white cp-px-1 cp-py-2"
  >
    <div>
      <button
        mat-flat-button
        class="button-secondary"
        *ngIf="tpnPage != 1"
        (click)="prevPage()"
      >
        Back
      </button>
    </div>
    <div>
      <button
        [disabled]="isGoToPage(tpnPage + 1)"
        mat-flat-button
        class="button-primary"
        *ngIf="tpnPage != 4"
        (click)="nextPage()"
      >
        Next
      </button>
    </div>
  </div>
</div>
