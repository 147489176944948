<div class="meta-freq-comp" [formGroup]="form">
  <div
    class="schedule-cont"
    [ngClass]="{
      disabledDiv: frequencyType !== 'every' || !freqHourSelected
    }"
  >
    <label>Schedule:</label>

    <mat-select
      formControlName="scheduleSelector"
      class="order-select"
      placeholder="Select"
      (selectionChange)="onScheduleChange($event)"
    >
      <mat-option
        *ngFor="let option of schedules[freqHourSelected]"
        [value]="option.value"
      >
        <span style="white-space: pre">
          {{ option.label }}
        </span>
      </mat-option>
    </mat-select>
  </div>

  <div
    class="doses-days-cont"
    [ngClass]="{ disabledDiv: frequencyType !== 'every' }"
  >
    <div class="doses-cont">
      <label class=""
        >{{
          config && config.dose && config.dose.label
            ? config.dose.label
            : "Doses"
        }}:
      </label>
      <input
        matInput
        class="cp-input-field"
        formControlName="numberOfDoses"
        placeholder=""
        (change)="onDoseChange(numberOfDoses.value || 0)"
      />
    </div>

    <div class="days-cont" *ngIf="config?.noOfDays?.present">
      <label>Days:</label>
      <input
        matInput
        class="cp-input-field"
        placeholder=""
        formControlName="noOfDays"
        (change)="onDaysChange(noOfDays.value || 0)"
      />
    </div>
  </div>

  <div
    class="before-after"
    formGroupName="timePeriodDetail"
    [ngClass]="{ disabledDiv: frequencyType === 'continuous' }"
  >
    <mat-button-toggle-group
      formControlName="period"
      class="button-toggle-secondary border"
    >
      <mat-button-toggle
        (change)="onTimePeriodChange(period)"
        [value]="period"
        *ngFor="let period of ['Before', 'After']"
      >
        {{ period }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="before-after-options" *ngIf="period.value">
      <mat-select
        formControlName="periodOption"
        class="order-select"
        placeholder="Select"
      >
        <mat-option
          *ngFor="
            let option of periodOptions | timePeriodOptions : period?.value
          "
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div
    class="urgency-cont"
    [ngClass]="{
      disabledDiv: formType === 'edit' && form?.get('startTime')?.disabled
    }"
  >
    <label>Urgency:</label>
    <mat-button-toggle-group
      class="button-toggle-secondary border"
      formControlName="urgency"
    >
      <mat-button-toggle
        [value]="uType"
        *ngFor="let uType of ['STAT', 'ASAP']"
        (change)="selectUrgency($event)"
      >
        {{ uType }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
