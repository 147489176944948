import { createAction, props } from "@ngrx/store";
import { PatientsButtonsEnum } from "src/app/data/patients-buttons.data";

export const setActiveButtons = createAction(
  "[Patient View] set active buttons",
  props<{ activeButtons: PatientsButtonsEnum }>()
);

export const setOtherPatientsCheckbox = createAction(
  "[Patient View] set other patients checkbox",
  props<{ id: string }>()
);

export const setAllOtherPatientsCheckbox = createAction(
  "[Patient View] set all other patients checkbox",
  props<{ ids: string[] }>()
);

export const clearView = createAction("[Patient View] clear view");
