import { Component } from "@angular/core";

@Component({
  selector: "cp-range-info",
  template:
    "<span class='cp-text-bold'>Range</span>: <span class='cp-color-light cp-text-normal'><ng-content></ng-content> </span>",
})
export class RangeInfoComponent {
  constructor() {}
}
