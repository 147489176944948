import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
  map,
  catchError,
  switchMap,
  concatMap,
  mergeMap,
  tap,
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as patientListActions from "../actions/patients/patient-list.action";
import * as unitActions from "../actions/unit.actions";
import * as dischargedPatientListActions from "../actions/patients/dischargedPatientList.action";
import * as patientAssignMeActions from "../actions/patients/patient-assign-me.action";

import { PatientService } from "src/app/services/patient.service";
import { ShiftService } from "src/app/services/shift.service";
import { AlertService } from "@iris/service/alert.service";

@Injectable()
export class PatientListEffects {
  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private shiftService: ShiftService,
    private _alertService: AlertService
  ) {}

  // getPatients$ = createEffect( () => this.actions$.pipe(
  //     ofType(patientListActions.getPatients),
  //     switchMap(({email}) => this.patientService.getAllPatients(email).pipe(
  //         map(patients => {
  //             return patientListActions.setPatients({patients: patients['patients']});
  //         }),
  //         catchError(() => EMPTY)
  //     ))
  // ));

  assignUnitToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignShift),
      concatMap(({ shift }) => {
        return this.shiftService.assignShifts(shift).pipe(
          mergeMap(({ message, data }) => {
            return [
              patientListActions.assignShiftSuccess({ unitId: shift?.unit }),
              // unitActions.updateUnitInfo({ unit: data.units[0] }),
            ];
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  assignAllUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignAllShift),
      switchMap((data) =>
        this.shiftService.assignAllShifts(data).pipe(
          map((response) => {
            return patientListActions.assignAllShiftSuccess({
              name: data?.name,
            });
          }),
          catchError((error) => EMPTY)
        )
      )
    )
  );

  assignAllWorkSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignAllWorkspace),
      switchMap((data) =>
        this.shiftService.assignAllWorkspace(data.workspace).pipe(
          map((response) => {
            return patientListActions.assignAllWorkspaceSuccess({
              workspaceId: data?.workspace?.workspaceId,
            });
          }),
          catchError((error) => EMPTY)
        )
      )
    )
  );

  getAllDischargedPatients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dischargedPatientListActions.getAllDischargedPatients),
      switchMap(({ query }) =>
        this.patientService.getAllDischargedPatients(query).pipe(
          map((patients) => {
            if (patients && patients["data"])
              return dischargedPatientListActions.setAllDischargedPatients({
                patients: patients["data"],
              });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  patientAssignMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(patientAssignMeActions.patientAssignMeAction),
      switchMap(({ patientCPMRNs }) =>
        this.patientService.patientAssignToMe(patientCPMRNs).pipe(
          map(() =>
            patientAssignMeActions.patientAssignMeSuccessAction({
              message: `${patientCPMRNs?.length} patient${
                patientCPMRNs?.length > 1 ? "s" : ""
              } assigned successfully`,
            })
          ),
          catchError((error) =>
            of(
              patientAssignMeActions.patientAssignMeFailureAction({
                error: error?.error?.message || "Server Error",
              })
            )
          )
        )
      )
    );
  });

  patientAssignMeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientAssignMeActions.patientAssignMeSuccessAction),
        tap(({ message }) =>
          this._alertService.showNotification(
            {
              type: "Success",
              message,
            },
            "center",
            "bottom",
            3000
          )
        )
      ),
    { dispatch: false }
  );

  patientAssignMeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientAssignMeActions.patientAssignMeFailureAction),
        tap(({ error }) =>
          this._alertService.showNotification(
            {
              type: "Error",
              message: error,
            },
            "center",
            "bottom",
            3000
          )
        )
      ),
    { dispatch: false }
  );
}
