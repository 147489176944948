import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "cp-tpn-form-field",
  templateUrl: "./tpn-form-field.component.html",
  styleUrls: ["./tpn-form-field.component.scss"],
})
export class TpnFormFieldComponent {
  @Input() label: string = "";
  @Input() control!: AbstractControl;

  constructor() {}
  get errorKey() {
    return this.control?.errors && Object.keys(this.control.errors)[0];
  }
}
