<!-- Custom Toolbar -->
<div #toolbar>
  <button class="ql-bold">Bold</button>
  <button class="ql-italic">Italic</button>
  <button class="ql-underline">Italic</button>
  <button class="ql-strike">Strike</button>
  <button class="ql-list" value="ordered">List</button>
  <button class="ql-list" value="bullet">List</button>
  <button class="ql-script" value="sub">Script</button>
  <button class="ql-script" value="super">Script</button>
  <button class="ql-list" value="check">List</button>
  <select class="ql-color">
    <option value="#000000"></option>
    <option value="#e60000"></option>
    <option value="#ff9900"></option>
    <option value="#ffff00"></option>
    <option value="#008a00"></option>
    <option value="#0066cc"></option>
    <option value="#9933ff"></option>
    <option value="#ffffff"></option>
    <option value="#facccc"></option>
    <option value="#ffebcc"></option>
    <option value="#ffffcc"></option>
    <option value="#cce8cc"></option>
    <option value="#cce0f5"></option>
    <option value="#ebd6ff"></option>
    <option value="#bbbbbb"></option>
    <option value="#f06666"></option>
    <option value="#ffc266"></option>
    <option value="#ffff66"></option>
    <option value="#66b966"></option>
    <option value="#66a3e0"></option>
    <option value="#c285ff"></option>
    <option value="#888888"></option>
    <option value="#a10000"></option>
    <option value="#b26b00"></option>
    <option value="#b2b200"></option>
    <option value="#006100"></option>
    <option value="#0047b2"></option>
    <option value="#6b24b2"></option>
    <option value="#444444"></option>
    <option value="#5c0000"></option>
    <option value="#663d00"></option>
    <option value="#666600"></option>
    <option value="#003700"></option>
    <option value="#002966"></option>
    <option value="#3d1466"></option>
  </select>
  <select class="ql-header">
    <option value="1"></option>
    <option value="2"></option>
    <option value="3"></option>
    <option value="4"></option>
    <option value="5"></option>
    <option value="6"></option>
  </select>
  <button class="ql-link"></button>
  <button class="ql-clean"></button>

  <!-- Add more toolbar options as needed -->
</div>

<div class="text-editor" #textEditor>
  <div
    #quillEditor
    cpQuillInit
    [hideToolBar]="hideToolBar"
    [toolbar]="toolbar"
    (onEditorCreated)="initializeQuill($event)"
    (onContentChanged)="onContentChanged($event)"
  ></div>
  <cp-autocomplete-selection
    [data]="autocompleteData"
    [macros]="macros"
    [textEditor]="textEditor"
    (setTemplate)="setTemplate($event)"
  ></cp-autocomplete-selection>
</div>
