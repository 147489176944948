import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { CheckedListPipe } from "./pipes/checked-list.pipe";
import { DropdownLabelsPipe } from "./pipes/dropdown-labels.pipe";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SearchFilterPipe } from "./pipes/search-filter.pipe";

import { DropdownHeightPipe } from "./pipes/dropdown-height.pipe";
import { AutofocusModule } from "@shared-modules/directives/autofocus";

@NgModule({
  declarations: [
    DropdownComponent,
    CheckedListPipe,
    DropdownLabelsPipe,
    SearchFilterPipe,
    DropdownHeightPipe,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    OverlayModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ScrollingModule,
    AutofocusModule,
    MatInputModule,
  ],
  exports: [DropdownComponent],
})
export class DropdownModule {}
