<div>
  <mat-drawer-container class="sidenav-container">
    <mat-drawer mode="side" opened>
      <div class="sideNav">
        <div class="input-cont">
          <img class="search-icon" src="assets/icons/search.svg" />
          <input
            matInput
            class="cp-input-field"
            placeholder="Search"
            autocomplete="off"
            aria-describedby="search-icon"
            [(ngModel)]="searchDashboard"
          />
        </div>
        <hr />
        <div class="sidenav_items">
          <a routerLink="/audit/favorites" routerLinkActive="primary-bg-table"
            ><img src="../../assets/icons/star.svg" alt="" /> Favorites</a
          >
          <a routerLink="/audit/recents" routerLinkActive="primary-bg-table"
            ><img src="../../assets/icons/recent.svg" alt="recent" /> Recent</a
          >
          <a routerLink="/audit/dashboards" routerLinkActive="primary-bg-table"
            ><img src="../../assets/icons/dashboard.svg" alt="dashboard" />
            Dashboards</a
          >
          <a routerLink="/audit/help" routerLinkActive="primary-bg-table"
            ><img src="../../assets/icons/help.svg" alt="" /> Help</a
          >
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="sidenav_content bg-white">
        <!-- Show Dashboard list -->
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
