<div [formGroup]="form">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Sodium (mEq/kg/day):" [control]="sodium">
        <cp-tpn-input
          formControlName="sodium"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.sodium"
        >
          <ng-template #info>
            <cp-range-info>3-5meq/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="sodiumVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.sodium"
            [control]="sodium"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Potassium (mEq/kg/day):" [control]="potassium">
        <cp-tpn-input
          formControlName="potassium"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.potassium"
        >
          <ng-template #info>
            <cp-range-info>1-2meq/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="potassiumVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.potassium"
            [control]="potassium"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Calcium (ml/kg/day):" [control]="calcium">
        <cp-tpn-input
          formControlName="calcium"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.calcium"
        >
          <ng-template #info>
            <cp-range-info>4-8ml/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="calciumVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.calcium"
            [control]="calcium"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Phosphate (ml/kg/day):" [control]="phosphate">
        <cp-tpn-input
          formControlName="phosphate"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.phosphate"
        >
          <ng-template #info>
            <cp-range-info>0.5ml/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="phosphateVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.phosphate"
            [control]="phosphate"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Multivitamin (ml/kg/day):"
        [control]="multivitamin"
      >
        <cp-tpn-input
          formControlName="multivitamin"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.multivitamin"
        >
          <ng-template #info>
            <cp-range-info>1-1.5ml/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="multivitaminVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.multivitamin"
            [control]="multivitamin"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Minerals (ml/kg/day):" [control]="minerals">
        <cp-tpn-input
          formControlName="minerals"
          placeholder="0"
          [class.cp-form-invalid]="isTPNExcededErrorObj.minerals"
        >
          <ng-template #info>
            <cp-range-info>0.2ml/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="mineralsVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.minerals"
            [control]="minerals"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
  </div>
</div>
