import { HttpClient } from "@angular/common/http";
import { ElementRef, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import Quill, { Bounds } from "quill";
import {
  AutocompleteDataInterface,
  MacroInterface,
  QuillType,
} from "../models/quill-editor.model";
import { autocompleteInitialValue, maxMacros } from "../utils";

@Injectable({
  providedIn: "root",
})
export class AutocompleteService {
  private _resetAutocompleteSubject = new Subject();
  public resetAutocomplete$ = this._resetAutocompleteSubject.asObservable();

  public resetAutocomplete() {
    this._resetAutocompleteSubject.next();
  }

  getAutocompleteData(
    quill: Quill,
    editorContainer: ElementRef
  ): AutocompleteDataInterface {
    const index = this.getSelectionIndex(quill);
    const bounds = quill.getBounds(index);
    if (!bounds) return autocompleteInitialValue;
    const { top, left, height } = bounds;
    const element = editorContainer.nativeElement;
    const { offsetTop, offsetLeft } = element;
    const { left: clientX, top: clientY } = element.getBoundingClientRect();
    const sug = this.getLastWord(quill, index) || "";

    return {
      offsetTop,
      offsetLeft,
      top,
      left,
      height,
      clientX,
      clientY,
      sug,
      index,
    };
  }

  public getLastWord(quill: QuillType, index: number) {
    const str = quill.getText(0, index) || "";
    const regexLastWord = /[a-zA-Z_]+?(?=\s*?[^\w]*?$)/;
    const match = str.match(regexLastWord);

    return match ? match[0] : null;
  }

  setHtmlAutocomplete(
    quill: QuillType,
    autocompleteData: AutocompleteDataInterface,
    suggestion: MacroInterface
  ) {
    if (!quill) return;
    const { sug, index } = autocompleteData;
    if (!sug?.length) return;
    let currentIndex = (index || 0) - sug.length;

    quill.clipboard.dangerouslyPasteHTML(currentIndex, suggestion.value);

    quill.deleteText(quill?.getSelection().index, sug.length + 1);
  }

  public checkAndRemoveTab(quill: QuillType): boolean {
    const index = this.getSelectionIndex(quill);
    const isTab = quill?.getText(index, 1) === "\t";
    if (isTab) {
      quill?.deleteText(index, 1);
      return true;
    }
    return false;
  }

  public setSwaraText(
    swara: { id: string; index: number; swara: string },
    quill: Quill
  ) {
    const { index, swara: setText } = swara;
    quill.insertText(quill.getSelection().index, setText);
  }

  public getSelectionIndex(quill: QuillType): number {
    return quill?.getSelection()?.index ?? 0;
  }

  public filterAutocompleteSelection(
    macros: MacroInterface[],
    macro: string
  ): MacroInterface[] {
    {
      if (!macro?.length) return [];
      return macros
        .filter((el) =>
          el.key.toLocaleLowerCase().startsWith(macro.toLocaleLowerCase())
        )
        .slice(0, maxMacros);
    }
  }
}
