import { Pipe, PipeTransform } from "@angular/core";
import { DropdownInputInterface } from "../models/dropdown.model";

@Pipe({
  name: "dropdownLabels",
})
export class DropdownLabelsPipe implements PipeTransform {
  transform(
    value: string | string[] | null,
    dropdownOptions: DropdownInputInterface[]
  ): string {
    const valueToLabelMap = dropdownOptions.reduce((final, option) => {
      final.set(option.value, option.label);
      return final;
    }, new Map());
    if (Array.isArray(value))
      return value.map((el) => valueToLabelMap.get(el)).join(", ");
    return (typeof value === "number" && value === 0) || value
      ? valueToLabelMap.get(value)
      : null;
  }
}
