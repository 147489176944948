import React, { useState, useEffect, useRef } from "react";
import SearchDropdown from "./SearchDropdown";

interface AvatarProps {
  data: { name: string; id: string }[]; // Expecting an array of objects with 'name' and 'id'
  onClick: (name: string, id: string) => void; // Modify onClick to accept both name and id
  placeholder: string;
}

const Avatar: React.FC<AvatarProps> = ({ data = [], onClick, placeholder }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null); // Track selected ID
  const [extraSelected, setExtraSelected] = useState<boolean>(false);

  // Ref for the dropdown container
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleNameClick = (name: string, id: string) => {
    if (selectedName === name) {
      setSelectedName(null);
      setSelectedId(null);
      setExtraSelected(false);
    } else {
      setSelectedName(name);
      setSelectedId(id);
      if (data.slice(4).some((item) => item.name === name)) {
        setExtraSelected(true); // Mark if the selection is from extra items
      }
    }
    onClick(name, id);
    setDropdownVisible(false);
  };

  const formattedData = data.slice(4).map((item, index) => ({
    ...item,
    number: index + 1, // Assign a number
  }));

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validData = Array.isArray(data) ? data : [];
  const extraItemsCount = validData.length - 4;

  const displayData = [
    ...validData.slice(0, 4).reverse(),
    ...(selectedName && !validData.slice(0, 4).some((item) => item.name === selectedName)
      ? [{ name: selectedName, id: selectedId || "" }]
      : []),
  ];

  return (
    <div className="tw-relative tw-flex tw-items-center" ref={dropdownRef}>
      {extraItemsCount > 0 && (
        <div
          onClick={handleToggleDropdown}
          className={`tw-absolute tw-text-xs tw-items-center tw-justify-center tw-left-[10.4rem] tw-cursor-pointer tw-px-2 tw-py-1 ${extraSelected
            ? "tw-border tw-border-primary-400 tw-rounded-full tw-flex tw-items-center tw-justify-center"
            : ""
            }`}
        >
          +{extraItemsCount}
        </div>
      )}

      {isDropdownVisible && (
        <div className="tw-absolute tw-top-12 tw-left-[4rem] tw-z-50"
          ref={dropdownRef}
        >
          <SearchDropdown
            names={formattedData}
            uniqueKey={"avatar"}
            handleNameSelect={(item) => handleNameClick(item.name, item.id)}
            placeholder={placeholder}
            className="tw-text-sm tw-w-[9rem] tw-bg-white tw-border tw-border-gray-300 tw-rounded tw-shadow-lg"
            showSelectContainer={false}
          />
        </div>
      )}

      {displayData.map((item, index) => (
        <div
          key={index}
          onClick={() => handleNameClick(item.name, item.id)}
          className={`tw-absolute tw-w-10 tw-h-10 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-[#34A2B1] tw-font-bold tw-cursor-pointer ${selectedName === item.name
            ? "tw-bg-primary-400 tw-text-white tw-border-none tw-z-10"
            : "tw-bg-primary-200 tw-border-[3px] tw-border-white"
            }`}
          style={{
            left: `${(4 - index) * 2}rem`,
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            zIndex: selectedName === item.name ? 10 : 1,
          }}
        >
          {getInitials(item.name)}
        </div>
      ))}
    </div>
  );
};

const getInitials = (name: string): string => {
  if (!name) return "";
  const names = name.split(" ");
  const initials = names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
  return initials.toUpperCase();
};

export default Avatar;
