<form class="vent-form-comp" autocomplete="off" [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>
  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
  <div class="airway-cont">
    <div class="airway">
      <label class="cp-field-label" for="patVentAirway"> Airway * </label>
      <mat-select
        class="order-select"
        formControlName="patVentAirway"
        id="patVentAirway"
      >
        <!-- <option disabled selected value="">Select one</option> -->
        <mat-option *ngFor="let airway of airwayOptions" [value]="airway.value">
          {{ airway.text }}
        </mat-option>
      </mat-select>
    </div>
    <div class="tube-size" *ngIf="canViewTube">
      <label class="cp-field-label" for="patVentTube">Tube Size</label>
      <mat-select
        class="order-select"
        formControlName="patVentTube"
        id="patVentTube"
      >
        <!-- <mat-option disabled selected value="">Select one</option> -->
        <mat-option *ngFor="let tube of tubeOptions" [value]="tube.value">
          {{ tube.text }}
        </mat-option>
      </mat-select>
    </div>
    <div class="Intubation-type" *ngIf="canViewIntType">
      <label class="cp-field-label">
        Intubation Type
        <span *ngIf="intTypeRequired" class="ml-1">*</span>
      </label>
      <mat-button-toggle-group formControlName="patIntType">
        <mat-button-toggle [value]="'nasal'"> Nasal </mat-button-toggle>
        <mat-button-toggle [value]="'oral'"> Oral </mat-button-toggle>
        <mat-button-toggle [value]="'NA'"> NA </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="tube-size" *ngIf="canViewTubeSize">
      <label class="cp-field-label" for="patVentTubeSize">Tube Size</label>
      <input
        matInput
        type="text"
        class="cp-input-field"
        formControlName="patVentTubeSize"
        placeholder="mm"
        id="patVentTubeSize"
      />
    </div>
    <div class="oral-airway">
      <label class="cp-field-label" for="patVentOralAirway">Oral Airway</label>
      <mat-select
        class="order-select"
        formControlName="patVentOralAirway"
        id="patVentOralAirway"
      >
        <mat-option value="None">None</mat-option>
        <mat-option value="Naso">Nasopharyngeal</mat-option>
        <mat-option value="Oro">Oropharyngeal</mat-option>
        <mat-option value="BB">Bite-Block</mat-option>
      </mat-select>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="ett-tie-cont" *ngIf="canViewEttTieLevel">
    <div class="ett-tie-level">
      <label class="cp-field-label" for="patEttTieLevel"
        >ETT Tie Level (cm)</label
      >
      <div>
        <input
          matInput
          type="text"
          class="cp-input-field"
          formControlName="patEttTieLevel"
          id="patEttTieLevel"
        />
      </div>
    </div>
  </div>

  <div class="vent-cont">
    <div class="vent-type">
      <label class="cp-field-label">
        Ventilation Type<span class="ml-1">*</span>
      </label>
      <div>
        <mat-button-toggle-group formControlName="patVentType">
          <mat-button-toggle *ngIf="canViewMechVent" [value]="'Mech'">
            Mech. Invasive
          </mat-button-toggle>
          <mat-button-toggle *ngIf="canViewNivVent" [value]="'NIV'">
            NIV
          </mat-button-toggle>
          <mat-button-toggle [value]="'Heated High Flow'">
            Heated high flow
          </mat-button-toggle>
          <mat-button-toggle [value]="'Spont'"> Spont. </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="mode" *ngIf="canViewBasicForm">
      <label class="cp-field-label">Mode</label>
      <div>
        <mat-button-toggle-group formControlName="patVentMode">
          <mat-button-toggle [value]="'VC'"> VC </mat-button-toggle>
          <mat-button-toggle [value]="'PC'"> PC </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="breath-seq" *ngIf="canViewBasicForm">
      <label class="cp-field-label">Breath Sequence</label>
      <mat-select class="order-select" formControlName="patVentSubMode">
        <!-- <option value="none">None</option> -->
        <mat-option [value]="prop" *ngFor="let prop of modeSelectValues">{{
          prop
        }}</mat-option>
      </mat-select>
    </div>
  </div>

  <div class="basic-form-part1">
    <div class="tidal-vol" *ngIf="canViewBasicForm && viewTidalFirst">
      <label class="cp-field-label" for="patVentVtAbove"
        >Tidal Volume (V<sub>t</sub>)</label
      >
      <input
        matInput
        class="cp-input-field"
        type="text"
        formControlName="patVentVt"
        placeholder="ml"
        id="patVentVtAbove"
      />
    </div>
    <div class="pinsp" *ngIf="canViewBasicForm && !viewTidalFirst">
      <label class="cp-field-label" for="patVentPinspAbove"
        >P<sub>insp</sub> /IPAP</label
      >
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPinsp"
        placeholder="cm H2O"
        id="patVentPinspAbove"
      />
    </div>
    <div class="rr-set" *ngIf="canViewBasicForm">
      <label class="cp-field-label" for="patVentRRset">RR<sub>set</sub></label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentRRset"
        placeholder="Breaths/min"
        id="patVentRRset"
      />
    </div>
    <div class="peep-label" *ngIf="canViewBasicForm">
      <label class="cp-field-label" for="patVentPEEP">{{ PEEPLabel }}</label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPEEP"
        placeholder="cm H2O"
        id="patVentPEEP"
      />
    </div>
    <div class="fio2" *ngIf="canViewFio2">
      <label class="cp-field-label" for="patVentFiO2">FiO<sub>2</sub></label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentFiO2"
        placeholder="%"
        id="patVentFiO2"
      />
    </div>
    <div class="flow-rate" *ngIf="canViewFlowRate">
      <label class="cp-field-label" for="patFlowRate">Flow Rate</label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patFlowRate"
        placeholder="l / min"
        id="patFlowRate"
      />
    </div>
  </div>

  <div class="basic-form-part2" *ngIf="canViewBasicForm">
    <div class="tidal-vol" *ngIf="!viewTidalFirst">
      <label class="cp-field-label" for="patVentVtBelow"
        >Tidal Volume (V<sub>t</sub>)</label
      >
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentVt"
        placeholder="ml"
        id="patVentVtBelow"
      />
    </div>
    <div class="pinsp" *ngIf="viewTidalFirst">
      <label class="cp-field-label" for="patVentPinspBelow"
        >P<sub>insp</sub> /IPAP</label
      >
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPinsp"
        placeholder="cm H2O"
        id="patVentPinspBelow"
      />
    </div>
    <div class="ps">
      <label class="cp-field-label" for="patVentPsupport">PS</label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPsupport"
        placeholder="cm H2O"
        id="patVentPsupport"
      />
    </div>
    <div class="plat">
      <label class="cp-field-label" for="patVentPplat">P<sub>plat</sub></label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPplat"
        placeholder="cm H2O"
        id="patVentPplat"
      />
    </div>
    <div class="map">
      <label class="cp-field-label" for="patVentMAP">MAP (Airway)</label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentMAP"
        placeholder="cm H2O"
        id="patVentMAP"
      />
    </div>
  </div>

  <div class="basic-form-part3" *ngIf="canViewBasicForm">
    <div class="mv">
      <label class="cp-field-label" for="patVentMV">MV</label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentMV"
        placeholder="l/min"
        id="patVentMV"
      />
    </div>
    <div class="rr-total">
      <label class="cp-field-label" for="patVentRRtot"
        >RR<sub>total</sub></label
      >
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentRRTot"
        placeholder="Breaths/min"
        id="patVentRRtot"
      />
    </div>
    <div class="p-peak">
      <label class="cp-field-label" for="patVentPpeak">P<sub>peak</sub></label>
      <input
        type="text"
        matInput
        class="cp-input-field"
        formControlName="patVentPpeak"
        placeholder="cm H2O"
        id="patVentPpeak"
      />
    </div>
    <div class="ie">
      <label class="cp-field-label">I:E</label>
      <div class="i-e-inputs">
        <input
          type="text"
          matInput
          class="cp-input-field"
          formControlName="patVentI"
          placeholder="I"
        />
        <input
          type="text"
          matInput
          class="cp-input-field"
          formControlName="patVentE"
          placeholder="E"
        />
      </div>
    </div>
  </div>
  <div class="mt">
    <app-order-detail></app-order-detail>
  </div>
  <mat-divider></mat-divider>
  <div
    class="rounded"
    *ngIf="config.defaultDate.present"
    style="
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    "
  >
    <div class="">
      <app-vital-time
        [minDate]="minDate"
        [maxDate]="maxDate"
        (timeDateChange)="onTimeDateChanged()"
        formControlDate="patVentDate"
        formControlTime="patVentTime"
        style="width: 85%"
      >
      </app-vital-time>
    </div>
  </div>

  <app-order-time
    [config]="config"
    [setTime]="startTime"
    [formType]="formType"
    (onStartNowChange)="startNowChangeHandler($event)"
    *ngIf="config.startTime.present || config.endTime.present"
  ></app-order-time>
</form>
