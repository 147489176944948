import { Component, Input, inject } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import {
  IsTPNExceedErrorInterface,
  TPNFluidStep3Interface,
  TotalVolumeType,
} from "src/app/tpn-calculator/models";

@Component({
  selector: "cp-tpn-step3",
  templateUrl: "./tpn-step3.component.html",
})
export class TpnStep3Component {
  @Input("tpnVolumeInfo") tpnVolume: TPNFluidStep3Interface;
  @Input() isTPNConsumed: boolean = false;
  @Input() isTPNExcededErrorObj!: IsTPNExceedErrorInterface;

  private _controlContainer = inject(ControlContainer);

  public get sodiumVolume(): TotalVolumeType {
    return this.tpnVolume.sodium;
  }

  public get potassiumVolume(): TotalVolumeType {
    return this.tpnVolume.potassium;
  }

  public get calciumVolume(): TotalVolumeType {
    return this.tpnVolume.calcium;
  }

  public get phosphateVolume(): TotalVolumeType {
    return this.tpnVolume.phosphate;
  }

  public get multivitaminVolume(): TotalVolumeType {
    return this.tpnVolume.multivitamin;
  }

  public get mineralsVolume(): TotalVolumeType {
    return this.tpnVolume.minerals;
  }

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this._controlContainer.control;
  }

  get parentForm(): AbstractControl {
    return this.form.parent;
  }

  // sodium
  get sodium(): AbstractControl {
    return this.form.get("sodium");
  }

  // potassium
  get potassium(): AbstractControl {
    return this.form.get("potassium");
  }

  // sodium
  get calcium(): AbstractControl {
    return this.form.get("calcium");
  }

  // potassium
  get phosphate(): AbstractControl {
    return this.form.get("phosphate");
  }

  // sodium
  get multivitamin(): AbstractControl {
    return this.form.get("multivitamin");
  }

  // potassium
  get minerals(): AbstractControl {
    return this.form.get("minerals");
  }
}
