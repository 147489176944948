import { createReducer, on } from "@ngrx/store";
import { Action } from "@ngrx/store";

import * as fromPatientData from "src/app/store/actions/patients/patient-list.action";
import * as fromPatientViewActions from "src/app/store/actions/patients/patient-view.action";
import * as hospitalActions from "src/app/store/actions/hospitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { PatientsButtonsEnum } from "src/app/data/patients-buttons.data";

export interface State {
  loading: boolean;
  activeButtons: PatientsButtonsEnum | null;
  otherPatientsCheckbox: string[];
}

export const initialState: State = {
  loading: false,
  activeButtons: null,
  otherPatientsCheckbox: [],
};

const patientViewReducer = createReducer(
  initialState,
  // on(fromPatientData.getPatients, (state) => ({...state, loading: true})),
  on(hospitalActions.getNewHospitalsPatients, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromPatientData.setPatients, (state) => ({ ...state, loading: false })),
  on(fromPatientViewActions.clearView, (state) => ({ ...initialState })),
  on(fromPatientViewActions.setActiveButtons, (state, { activeButtons }) => ({
    ...state,
    activeButtons,
  })),
  on(fromPatientViewActions.setOtherPatientsCheckbox, (state, { id }) => ({
    ...state,
    otherPatientsCheckbox: state.otherPatientsCheckbox.find(
      (patId) => patId == id
    )
      ? state.otherPatientsCheckbox.filter((patId) => patId != id)
      : [...state.otherPatientsCheckbox, id],
  })),
  on(fromPatientViewActions.setAllOtherPatientsCheckbox, (state, { ids }) => ({
    ...state,
    otherPatientsCheckbox: state.otherPatientsCheckbox.length ? [] : ids,
  })),
  on(rootActions.logout, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return patientViewReducer(state, action);
}
