<div class="template-form">
  <div class="template-form__header">
    <h1 *ngIf="isUpdate; else addTemplateHeader">Macro templates</h1>
    <ng-template #addTemplateHeader> <h1>Add macro template</h1> </ng-template>

    <button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
  <div class="template-form__body">
    <ng-container>
      <ng-container
        [ngTemplateOutlet]="formTemplate"
        [ngTemplateOutletContext]="{ form: form }"
      >
      </ng-container>
    </ng-container>
  </div>

  <ng-template #formTemplate let-form="form">
    <form [formGroup]="form">
      <mat-form-field *ngIf="isUpdate; else inputText">
        <mat-label>Select template</mat-label>
        <cp-dropdown
          [data]="templates | macrosDropdown"
          placeholder="Select a template"
          [multiple]="false"
          formControlName="key"
        ></cp-dropdown>
      </mat-form-field>
      <ng-template #inputText>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            (input)="avoidSpaces($event)"
            formControlName="key"
          />
        </mat-form-field>
      </ng-template>
      <cp-quill-editor
        class="template-form__body"
        formControlName="value"
        [autocompleteType]="autocompleteType"
      ></cp-quill-editor>
      <p class="cp-mt-1" *ngIf="!isUpdate">
        Note: you can't have spaces in macro name
      </p>
    </form>
  </ng-template>
  <div>
    <span *ngIf="(createError$ | async) && !isUpdate" class="error-text">{{
      createError$ | async
    }}</span>

    <span
      *ngIf="(udpateOrDeleteError$ | async) && isUpdate"
      class="error-text"
      >{{ udpateOrDeleteError$ | async }}</span
    >
  </div>
  <div class="template-form__buttons gap-1">
    <button
      *ngIf="isUpdate"
      mat-raised-button
      class="button-error"
      [disabled]="(deleteLoader$ | async) || form.invalid"
      (click)="deleteTemplate()"
    >
      <app-loader
        [buttonLoader]="true"
        *ngIf="deleteLoader$ | async"
      ></app-loader>
      Delete
    </button>
    <button
      mat-raised-button
      (click)="save()"
      [disabled]="
        ((createLoader$ | async) && !isUpdate) ||
        ((updateLoader$ | async) && isUpdate) ||
        form.invalid
      "
    >
      <app-loader
        [buttonLoader]="true"
        *ngIf="
          ((createLoader$ | async) && !isUpdate) ||
          ((updateLoader$ | async) && isUpdate)
        "
      ></app-loader>
      Save
    </button>
  </div>
</div>
