import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
@Component({
  selector: "cp-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  constructor() {}

  @Input("currentStep") currentStep: number = 1;

  @Input("steps") steps = [];

  @Output() stepChange: EventEmitter<number> = new EventEmitter<number>();

  public onStepChange(step: number) {
    this.stepChange.emit(step);
  }
}
