<div class="popup-text" [ngStyle]="{ top: top, left: left }">
  <li>
    <ul
      [ngClass]="{ active: i === selection }"
      *ngFor="let sug of filteredList; let i = index"
      (click)="emitTemplate(sug)"
    >
      <div>
        {{ sug.key }}
      </div>
    </ul>
  </li>
</div>
