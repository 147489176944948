import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TpnCalculatorComponent } from "./components/tpn-calculator/tpn-calculator.component";
import { StepperComponent } from "./components/stepper/stepper.component";
import { TpnInputComponent } from "./components/tpn-input/tpn-input.component";
import { IrisComponentsModule } from "@iris/iris-components.module";
import { TpnStep1Component } from "./components/steps/tpn-step1/tpn-step1.component";
import { TpnStep2Component } from "./components/steps/tpn-step2/tpn-step2.component";
import { TpnStep3Component } from "./components/steps/tpn-step3/tpn-step3.component";
import { TpnStep4Component } from "./components/steps/tpn-step4/tpn-step4.component";
import { DropdownModule } from "@iris/dropdown/dropdown.module";
import { TpnformErrorTextComponent } from "./components/tpnform-error-text/tpnform-error-text.component";
import { RangeInfoComponent } from "./components/range-info/range-info.component";
import { TpnMessageComponent } from "./components/tpn-message/tpn-message.component";
import { TpnFormFieldComponent } from "./components/tpn-form-field/tpn-form-field.component";

@NgModule({
  declarations: [
    TpnCalculatorComponent,
    StepperComponent,
    TpnInputComponent,
    TpnStep1Component,
    TpnStep2Component,
    TpnStep3Component,
    TpnStep4Component,
    TpnformErrorTextComponent,
    RangeInfoComponent,
    TpnMessageComponent,
    TpnFormFieldComponent,
  ],
  imports: [CommonModule, IrisComponentsModule, DropdownModule],
  exports: [TpnCalculatorComponent],
})
export class TpnCalculatorModule {}
