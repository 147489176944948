import React from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay tw-flex tw-items-center tw-justify-center tw-bg-opacity-50 tw-bg-black fixed inset-0 z-50">
      <div className="tw-bg-white tw-p-6 tw-w-[25rem] tw-rounded-sm">
        <p className="tw-text-sm">
          This task will be assigned to you and will be marked as completed.
        </p>
        <div className="modal-actions tw-mt-4 tw-flex tw-justify-end">
          <button
            className="tw-border tw-border-primary-400 tw-text-sm tw-text-primary-400 tw-px-3 tw-py-1 tw-rounded-sm tw-mr-2 hover:tw-bg-gray-100 transition-all"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="tw-bg-primary-400 tw-text-white tw-text-sm tw-px-3 tw-py-1 tw-rounded-sm hover:tw-bg-primary-500 transition-all"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
