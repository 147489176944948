<div class="diet-form-comp" [formGroup]="form">
  <preset-fields
    *ngIf="config?.presetName?.present"
    [config]="config"
  ></preset-fields>
  <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
  <div class="">
    <div *ngIf="config.name.display">
      <label class="cp-field-label">Diet*</label>
      <mat-select formControlName="name" class="order-select">
        <mat-option *ngFor="let diet of diet" [value]="diet">{{
          diet
        }}</mat-option>
        <mat-option *ngFor="let diet of otherDiet" [value]="diet">
          {{ diet }}
        </mat-option>
      </mat-select>
      <span
        class="error-text"
        *ngIf="
          utilService.hasError(form, 'name', 'required') &&
          (name.touched || name.dirty)
        "
      >
        Diet is required
      </span>
    </div>
    <div
      class="rate-cont"
      formGroupName="rate"
      *ngIf="fType.value === 'continuous'"
    >
      <div class="rate">
        <label class="cp-field-label">Rate*</label>
        <div class="rate-input">
          <input
            matInput
            class="cp-input-field"
            formControlName="value"
            placeholder="ml"
          />
          <mat-button-toggle-group
            name="unitRadio"
            formControlName="unit"
            value="hr"
          >
            <mat-button-toggle
              value="{{ type }}"
              *ngFor="let type of ['hr', 'min']"
            >
              per {{ type }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <span
        class="error-text"
        *ngIf="
          rateValueHasError('required') &&
          (rateValue.touched || rateValue.dirty)
        "
      >
        Rate is required
      </span>
      <span
        class="error-text"
        *ngIf="rateValueHasError('negativeError') && rateValue.value"
      >
        Rate of order should be greater than 0
      </span>
    </div>
    <div
      style="margin-bottom: 0.8rem"
      *ngIf="
        config.bedsideOrder.present && formType === 'new' && showBedsideForm
      "
      style="margin-bottom: 1.2rem"
    >
      <button
        mat-flat-button
        tabindex="-1"
        type="button"
        onclick="this.blur()"
        (click)="onBedsideChange()"
        [ngClass]="
          form.get('bedsideOrder').value ? 'button-primary' : 'button-secondary'
        "
      >
        Bedside Order
      </button>
    </div>
  </div>

  <div class="order-frequecy-cont">
    <app-order-frequency
      [config]="config"
      [formType]="formType"
      (frequencyChange)="onFrequencyChange($event)"
    ></app-order-frequency>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>
