import { createAction, props } from "@ngrx/store";

export const patientAssignMeAction = createAction(
  "[Patient List] patient assign me",
  props<{ patientCPMRNs: string[] }>()
);

export const patientAssignMeFailureAction = createAction(
  "[Patient List] patient assign me failure",
  props<{ error: string }>()
);

export const patientAssignMeSuccessAction = createAction(
  "[Patient List] patient assign me success",
  props<{ message: string }>()
);
