import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../reactStore/reduxStore";

interface SearchDropdownProps {
  names?: Array<{ id: string | number; name: string }>;
  value?: string; 
  handleNameSelect: (item: any) => void;
  placeholder?: string;
  loading?: boolean;
  error?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  className?: string;
  showSelectContainer?: boolean;
  uniqueKey: string
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  names = [],
  value = "",
  handleNameSelect,
  placeholder = "Select an option",
  loading: externalLoading,
  error: externalError,
  onKeyDown,
  className,
  showSelectContainer = true,
  uniqueKey
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchQuery, setSearchQuery] = useState(value || "");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showUpwards, setShowUpwards] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    // const {name, value} = event.target
    // setSearchQuery({...searchQuery, [name]: value})
  };

  const handleNameSelection = (item: any) => {
    setSearchQuery(item.name);
    handleNameSelect(item);
    setDropdownVisible(false);
  };

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const filteredNames = searchQuery
    ? (names.length > 0
      ? names.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      : names.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )) || []
    : names.length > 0
      ? names
      : names || [];

  useEffect(() => {
    if (dropdownVisible && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const spaceAbove = rect.top;
      const spaceBelow = window.innerHeight - rect.bottom;

      const shouldShowUpwards = spaceBelow < 200 && spaceAbove > spaceBelow;
      setShowUpwards(shouldShowUpwards);
    }
  }, [dropdownVisible]);

  return (
    <div className="tw-relative" ref={dropdownRef}>
      {showSelectContainer && (
        <div
          className={`tw-bg-white tw-p-1 tw-border ${dropdownVisible ? "tw-border-primary-400" : "tw-border-fades-400"} tw-rounded tw-cursor-pointer ${className}`}
          onClick={() => setDropdownVisible((prev) => !prev)}
        >
          <span className={`${dropdownVisible ? "tw-text-primary-400" : "tw-text-others-100"} tw-text-xs tw-text-nowrap tw-pr-3`}>
            {placeholder}
          </span>
          <img
            src="assets/icons/dropdown.svg"
            alt="Dropdown Icon"
            className="tw-absolute tw-right-1 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none tw-cursor-pointer"
          />
        </div>
      )}

      {(dropdownVisible || !showSelectContainer) && (
        <div
          className="tw-absolute tw-left-0 tw-p-2 tw-bg-white tw-shadow tw-z-10 tw-w-[12rem] tw-h-[15rem] tw-overflow-hidden"
          style={{
            top: showUpwards ? undefined : showSelectContainer ? '2rem' : '0rem',
            bottom: showUpwards ? '100%' : undefined,
            marginBottom: showUpwards ? '0.5rem' : undefined,
          }}
          onClick={handleDropdownClick}
        >
          <input
            ref={inputRef}
            type="text"
            className="tw-border tw-p-1 tw-w-[10.5rem] tw-relative"
            value={searchQuery}
            onChange={handleSearchInputChange}
            onKeyDown={onKeyDown} // Pass onKeyDown to the input
            placeholder="Search..."
            name={uniqueKey}
          />

          <div className="tw-overflow-y-auto tw-h-[11.5rem] tw-my-[0.5rem] tw-text-left">
            {filteredNames.length === 0 ? (
              <div className="tw-p-2 tw-text-center tw-text-gray-500">No results found</div>
            ) : (
              filteredNames.map((item) => (
                <div
                  key={item.id }
                  role="option"
                  className="tw-p-2 tw-text-sm tw-cursor-pointer hover:tw-bg-fades-100"
                  onClick={() => handleNameSelection(item)}
                >
                  {item.name}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;