<section class="iris-modal-container form-container sbar">
  <div class="form-title">
    SBAR <span>(Situation, Background, Assessment, Recommendation)</span>
  </div>
  <div class="form-body">
    <form
      class="iris-form"
      [formGroup]="sbarForm"
      (ngSubmit)="onSBSubmit()"
      autocomplete="off"
    >
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <ng-container
              *ngIf="sbarType === 'new'; then newSBAR; else editSBAR"
            ></ng-container>
          </div>
          <ng-template #newSBAR>
            <mat-form-field>
              <mat-label>CPMRN</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="cpmrn"
                [matAutocomplete]="auto"
                *ngIf="sbarType !== 'Physician' && !editedIssue"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let searchData of typeAhead"
                  [value]="searchData.CPMRN"
                >
                  <span>{{ searchData.CPMRN }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-template>
          <ng-template #editSBAR>
            <mat-form-field [floatLabel]="'always'">
              <mat-label>CPMRN</mat-label>
              <input
                type="text"
                matInput
                class="iris-input"
                formControlName="cpmrn"
                *ngIf="sbarType === 'Physician' || editedIssue"
              />
            </mat-form-field>
          </ng-template>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Patient name</mat-label>
              <input
                matInput
                class="iris-input"
                type="text"
                formControlName="patientName"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field>
              <mat-label>Location</mat-label>
              <input matInput type="text" formControlName="location" />
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field>
              <mat-label>Bedside physician</mat-label>
              <input matInput type="text" formControlName="bedsidePhysician" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field *ngIf="sbarType !== 'Physician'">
              <mat-label>Urgency<span class="error-text">*</span></mat-label>
              <mat-select
                [ngClass]="sbarUrgency(sbarForm.controls['urgency'].value)"
                class="cp-input-field iris-input"
                formControlName="urgency"
              >
                <mat-option disabled>Select Urgency</mat-option>
                <mat-option
                  *ngFor="let urgency of urgencies"
                  [value]="urgency"
                  >{{ urgency }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="sbarType === 'Physician'">
              <mat-label>urgency<span class="error-text">*</span></mat-label>
              <input
                matInput
                [ngClass]="sbarUrgency(sbarForm.controls['urgency'].value)"
                formControlName="urgency"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [hideRequiredMarker]="true">
              <mat-label
                >Issues<span class="error-text" *ngIf="sbarType !== 'Physician'"
                  >*</span
                ></mat-label
              >
              <input matInput type="text" formControlName="issues" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div *ngIf="sbarType === 'Physician'" class="row-left">
          <div class="iris-form-row">
            <mat-form-field>
              <mat-label>Action</mat-label>
              <mat-select
                class="iris-input"
                formControlName="action"
                (selectionChange)="onActionChange()"
              >
                <mat-option disabled value="null">Select Action</mat-option>
                <mat-option *ngFor="let action of actions" [value]="action">{{
                  action
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="sbarType === 'Physician'" class="row-right">
          <div class="iris-form-row">
            <mat-form-field>
              <mat-label>
                Comments<span *ngIf="isActionOther" class="error-text">*</span>
              </mat-label>
              <input matInput type="text" formControlName="comments" />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field *ngIf="sbarType !== 'Physician'">
              <mat-label>Module<span class="error-text">*</span></mat-label>
              <mat-select class="iris-input" formControlName="module">
                <mat-option
                  *ngFor="let module of modules"
                  [value]="module.value"
                  >{{ module.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="sbarType === 'Physician'">
              <mat-label>Module<span class="error-text">*</span></mat-label>
              <input matInput type="text" formControlName="module" />
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <div class="check-box"><mat-label>Type</mat-label></div>
            <div>
              <mat-checkbox formControlName="type">Bedside </mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <span *ngIf="restError">Server down! Please try after some time.</span>
        <button
          type="button"
          mat-flat-button
          mat-dialog-close
          class="button-secondary mr-1"
        >
          Close
        </button>
        <button
          type="submit"
          mat-flat-btn
          class="button-primary"
          [disabled]="!sbarForm.valid || formLoading"
        >
          <fa-icon
            [icon]="['fas', 'spinner']"
            pulse="true"
            *ngIf="formLoading"
          ></fa-icon
          >Submit
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</section>
