import { Component, Input, SimpleChanges, inject } from "@angular/core";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "cp-tpn-message",
  template: "<span class='cp-mr-1'>{{ displayMessage }} ml/day</span>",
})
export class TpnMessageComponent {
  public utils = inject(UtilService);
  @Input() message: number;
  displayMessage: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["message"]?.currentValue != null) {
      this.displayMessage = this.utils.getDecimalsNum(
        changes["message"].currentValue
      );
    } else {
      this.displayMessage = 0; // Default if message is null/undefined
    }
  }
}
