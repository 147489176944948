import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { effects } from "./store";
import { NoteEditorComponent } from "./note-input-components/note-editor/note-editor.component";
import { NoteInputComponents } from "./note-input-components/note-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { IrisComponentsModule } from "@iris/iris-components.module";
import { AiraModule } from "../aira/aira.module";
import { NoteAddendumComponent } from "./note-input-components/note-addendum/note-addendum.component";
import Quill from "quill";
import PlainClipboard from "./utils/clipboard";
import { NoteActionComponent } from "./note-input-components/note-action/note-action.component";
import { NoteAttestComponent } from "./note-input-components/note-attest/note-attest.component";
import { LoaderModule } from "@iris/loader/loader.module";
import {
  CloseRequestAttestModal,
  RequestAttestComponent,
} from "./note-input-components/request-attest/request-attest.component";
import { AugnitoButtonDirective } from "./directives/augnito-button.directive";
import { QuillEditorModule } from "@shared-modules/components/quill-editor";
import { SaveTemplateComponent } from "./note-input-components/save-template/save-template.component";
import { TemplateFormComponent } from "./note-input-components/template-form/template-form.component";
import { DropdownModule } from "@iris/dropdown/dropdown.module";
import { MacrosDropdownPipe } from "./pipes/macros-dropdown.pipe";
Quill.register(PlainClipboard, true);
@NgModule({
  declarations: [
    NoteInputComponents,
    NoteEditorComponent,
    NoteAddendumComponent,
    NoteActionComponent,
    NoteAttestComponent,
    RequestAttestComponent,
    CloseRequestAttestModal,
    AugnitoButtonDirective,
    SaveTemplateComponent,
    TemplateFormComponent,
    MacrosDropdownPipe,
  ],
  imports: [
    CommonModule,
    AiraModule,
    SharedModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,
    IrisComponentsModule,
    QuillEditorModule,
    DropdownModule,
    EffectsModule.forFeature(effects),
  ],
  exports: [NoteInputComponents, NoteEditorComponent],
})
export class NoteInputModule {}
