import React, { useState, useEffect } from "react";

interface Option {
  label: string; 
  value?: string;
}

interface DropdownProps {
  options: Option[];
  selectedValue: string; // The currently selected value (backend value)
  onChange: (value: string) => void; // Sends backend value to parent
  placeholder?: string;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  name?: string;
  placeholdertext?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder = "Select an option",
  className = "",
  onKeyDown,
  name, 
  placeholdertext,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null); 

  const handleDropdownToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDropdownVisible((prev) => !prev);
  };

  const handleDocumentClick = () => {
    setIsDropdownVisible(false);
  };

  const handleOptionClick = (value: string) => {
    onChange(value); 
    setIsDropdownVisible(false);
  };

  const handleKeyDownInternal = (e: React.KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e); 

    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) =>
        prev === null ? 0 : Math.min(prev + 1, options.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prev) => (prev === null ? options.length - 1 : Math.max(prev - 1, 0)));
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      handleOptionClick(options[highlightedIndex].value || options[highlightedIndex].label);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const selectedLabel =
    options.find(
      (option) => option.value === selectedValue || option.label === selectedValue
    )?.label || placeholder;

  return (
    <div
      className={`tw-relative tw-bg-white tw-p-1 tw-border ${
        isDropdownVisible ? "tw-border-primary-400" : "tw-border-fades-400"
      } tw-rounded tw-cursor-pointer ${className}`}
      onClick={handleDropdownToggle}
      onKeyDown={handleKeyDownInternal} 
      tabIndex={0} 
    >
      <span
        className={`${
          isDropdownVisible ? "tw-text-primary-400" : "tw-text-others-100"
        } ${placeholdertext} tw-text-nowrap tw-pr-3`}
      >
        {selectedLabel}
      </span>
      <img
        src="assets/icons/dropdown.svg"
        alt="Dropdown Icon"
        className="tw-absolute tw-right-1 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none tw-cursor-pointer"
      />

      {isDropdownVisible && (
        <div className="tw-absolute tw-bg-white tw-left-0 tw-top-[2.2rem] tw-p-2 tw-w-full tw-h-auto tw-overflow-hidden tw-shadow-2xl">
          {options.map((option, index) => (
            <div
              key={option.label}
              className={`tw-p-1 tw-cursor-pointer hover:tw-bg-fades-100 tw-text-nowrap tw-font-normal tw-text-others-400 tw-text-sm ${
                highlightedIndex === index ? "tw-bg-primary-200" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(option.value || option.label); 
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
