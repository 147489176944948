import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";

import * as fromNoteStore from "@notes/store";
import { MacroInterface } from "@shared-modules/components/quill-editor";
import {
  TemplateFormInterface,
  TemplatePopupInterface,
} from "@notes/models/notes.model";
import { select, Store } from "@ngrx/store";

@Component({
  selector: "cp-template-form",
  templateUrl: "./template-form.component.html",
  styleUrls: ["./template-form.component.scss"],
})
export class TemplateFormComponent implements OnInit, OnDestroy {
  public htmlText = "";

  public templateControl = new FormControl("");

  public templates: MacroInterface[] = [];

  private unsubscribe$ = new Subject();

  public createLoader$ = this._noteStore.pipe(
    select(fromNoteStore.macrosCreateLoaderSelector)
  );
  public updateLoader$ = this._noteStore.pipe(
    select(fromNoteStore.macrosUpdateLoaderSelector)
  );
  public deleteLoader$ = this._noteStore.pipe(
    select(fromNoteStore.macrosDeleteLoaderSelector)
  );

  public createError$ = this._noteStore.pipe(
    select(fromNoteStore.macrosCreateErrorSelector)
  );

  public udpateOrDeleteError$ = this._noteStore.pipe(
    select(fromNoteStore.macrosDeleteOrUpdateErrorSelector)
  );

  public success$ = this._noteStore.pipe(
    select(fromNoteStore.macrosAPISuccess)
  );
  deletionId: string = null;

  public get key(): AbstractControl {
    return this.form.get("key");
  }

  public get value(): AbstractControl {
    return this.form.get("value");
  }

  readonly data = inject<TemplatePopupInterface>(MAT_DIALOG_DATA);
  isUpdate: boolean = false;
  templatesMap: Map<string, MacroInterface> = new Map<string, MacroInterface>();
  public getForm(
    data: MacroInterface = {
      key: "",
      value: "",
    }
  ) {
    return this._fb.group<TemplateFormInterface>({
      key: this._fb.control(data.key, [Validators.required]),
      value: this._fb.control(data.value, [Validators.required]),
    });
  }
  form = this.getForm();

  constructor(
    private _fb: FormBuilder,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _dialogRef: MatDialogRef<TemplateFormComponent>
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.setForm();
    this.intializeListeners();
  }

  intializeListeners() {
    if (this.isUpdate) {
      this.key.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((key: string) => {
          if (!key) return;
          this.value.setValue(this.templatesMap.get(key).value);
        });
    }

    this.success$
      .pipe(takeUntil(this.unsubscribe$), skip(1))
      .subscribe((val) => {
        if (val && !this.isUpdate) {
          this._dialogRef.close();
        }
        if (this.deletionId) {
          this.templates = this.templates.filter(
            (el) => el._id != this.deletionId
          );
          this.deletionId = null;
          this.form.setValue({
            key: "",
            value: "",
          });
          this.form.markAsUntouched();
        }
      });

    this.udpateOrDeleteError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.deletionId) {
          this.deletionId = null;
        }
      });
  }

  public avoidSpaces(input) {
    this.key.setValue(input.target.value.replace(" ", ""));
  }

  setForm() {
    const { isUpdate, macros } = this.data;
    this.templates = macros;
    this.isUpdate = isUpdate;
    this.templateFormMap().setInitialValues();
  }

  templateFormMap() {
    if (this.isUpdate) {
      return {
        setInitialValues: () => {
          this.templatesMap = this.templates.reduce((acc, el) => {
            acc.set(el.key, el);
            return acc;
          }, new Map<string, MacroInterface>());
        },
        saveForm: () => {
          const { key, value: template } = this.form.value;
          const { _id: id }: MacroInterface = this.templatesMap.get(key);
          this._noteStore.dispatch(
            fromNoteStore.updateNoteMacros({
              template,
              id,
            })
          );
        },
      };
    } else {
      return {
        setInitialValues: () => {
          this.form = this.getForm({
            key: "",
            value: this.data.template,
          });
        },
        saveForm: () => {
          const { key, value } = this.form.value;
          const payload = {
            accessType: "user",
            key,
            value,
          };
          this._noteStore.dispatch(
            fromNoteStore.createNoteMacros({
              payload,
            })
          );
        },
      };
    }
  }

  public save() {
    this.templateFormMap().saveForm();
  }

  public deleteTemplate() {
    const { key } = this.form.value;
    this._noteStore.dispatch(
      fromNoteStore.deleteNoteMacros({
        id: this.templatesMap.get(key)._id,
      })
    );
    this.deletionId = this.templatesMap.get(key)._id;
  }
}
