import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";

import * as fromMacrosActions from "../actions";
import { NoteService } from "@notes/services/note.service";
import { MacroInterface } from "@shared-modules/components/quill-editor";
import { AlertService } from "@iris/service/alert.service";

@Injectable()
export class MacrosEffects {
  constructor(
    private actions$: Actions,
    private _noteService: NoteService,
    private _alertService: AlertService
  ) {}

  public errorMessage = "Something went wrong!";

  /*
   * Get macros data
   * */
  loadMacros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMacrosActions.loadNoteMacros),
      switchMap(() =>
        this._noteService.getMacros().pipe(
          map((macros: MacroInterface[]) =>
            fromMacrosActions.loadNoteMacrosSuccess({ macros })
          ),
          catchError((error: any) =>
            of(
              fromMacrosActions.loadNoteMacrosError({
                error: error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Create macros data
   * */
  createMacros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMacrosActions.createNoteMacros),
      switchMap(({ payload }) =>
        this._noteService.createMacros(payload).pipe(
          map((macro: MacroInterface) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Macro created successfully",
            });
            return fromMacrosActions.createNoteMacrosSuccess({ macro });
          }),
          catchError((error: any) =>
            of(
              fromMacrosActions.createNoteMacrosError({
                error: error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Update macros data
   * */
  updateMacros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMacrosActions.updateNoteMacros),
      switchMap(({ template, id }) =>
        this._noteService.updateMacro(template, id).pipe(
          map((macro: MacroInterface) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Macro updated successfully",
            });
            return fromMacrosActions.updateNoteMacrosSuccess({ macro });
          }),
          catchError((error: any) =>
            of(
              fromMacrosActions.updateNoteMacrosError({
                error: error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Delete macros data
   * */
  deleteMacros$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMacrosActions.deleteNoteMacros),
      switchMap(({ id }) =>
        this._noteService.deleteMacro(id).pipe(
          map(() => {
            this._alertService.showNotification({
              type: "Success",
              message: "Macro deleted successfully",
            });
            return fromMacrosActions.deleteNoteMacrosSuccess({ id });
          }),
          catchError((error: any) =>
            of(
              fromMacrosActions.deleteNoteMacrosError({
                error: error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );
}
