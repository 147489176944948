import React, { useEffect, useState } from 'react';
import Tooltip from './sharedComponents/Tooltip';
import SearchDropdown from './sharedComponents/SearchDropdown';
import { fetchUsers } from './reactStore/reactSlice/usersSlice';
import useApi from './reactStore/Hooks/useApi';
import { appendTask } from './reactStore/reactSlice/taskSlice';
import { socket } from "src/app/config/socket.js";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from './reactStore/reduxStore';

type TaskData = {
  Task: string;
  Priority: string;
  "Task Type": string;
  "Patient Name": string;
  Patient: string;
  "Patient Severity": string;
  "Bed no"?: string;
  "Hospital-unit"?: string;
  "Created-At"?: string;
  "Due-Date": string;
  Assignee: string;
  AssigneeName: string;
  Creator?: string;
  State?: string;
}

interface AddTaskPopUpProps {
  onClose: () => void;
  patientHeader$?: any;
}

const AddTaskPopUp: React.FC<AddTaskPopUpProps> = ({ onClose, patientHeader$ }) => {
  const { apiFn } = useApi();
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: any) => state.users.data);
  const [newTaskData, setNewTaskData] = useState<TaskData>({
    Task: "",
    Priority: "",
    "Task Type": "",
    "Patient Name": "",
    Patient: "",
    "Bed no": "",
    "Patient Severity": "",
    "Hospital-unit": "",
    "Created-At": "",
    "Due-Date": "",
    Assignee: "",
    AssigneeName: "",
    Creator: "",
  });
  const [isAssignee, setIsAssignee] = useState(true);
  const [isDueDate, setIsDueDate] = useState(true);
  const [validationTask, setValidationTask] = useState({
    Task: false,
    Priority: false,
    "Task Type": false,
    "Due-Date": false,
    AssigneeName: false,
  });

  const priorityTypes = [
    { label: "High", value: "high" },
    { label: "Low", value: "low" },
  ];

  const taskTypes = [
    { label: "Follow up", value: "followUp" },
    { label: "Admission", value: "admission" },
    { label: "Round", value: "rounds" },
    { label: "Others", value: "other" },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = e.target.value;
    setNewTaskData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setValidationTask((prevValidation) => ({
      ...prevValidation,
      [fieldName]: false,
    }));
  };

  const handleTaskAssigneeSelect = (assignee) => {
    setNewTaskData((prevData) => ({
      ...prevData,
      Assignee: assignee._id,
      AssigneeName: assignee.name,
    }));
    setValidationTask((prevValidation) => ({
      ...prevValidation,
      AssigneeName: false,
    }));
    setIsAssignee(false);
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    onClose();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [newTaskData]);

  useEffect(() => {
    dispatch(fetchUsers(''));
  }, [dispatch]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      let isValid = true;
      const requiredFields = [
        "Task",
        "Priority",
        "Task Type",
        "Due-Date",
        "AssigneeName",
      ];

      requiredFields.forEach((field) => {
        if (!newTaskData[field] || newTaskData[field].trim() === "") {
          isValid = false;
          setValidationTask((prev) => ({
            ...prev,
            [field]: true,
          }));
        }
      });

      if (!isValid) {
        return;
      }
      handleCreateTask();
    }
  };

  const handleCreateTask = async () => {
    try {
      const payload = {
        title: newTaskData.Task,
        type: newTaskData["Task Type"],
        severity: newTaskData["Patient Severity"],
        priority: newTaskData.Priority,
        assignee: newTaskData.Assignee,
        dueDate: newTaskData["Due-Date"],
        patient: patientHeader$._id,
      };

      const { response, error } = await apiFn({
        url: `v1/tasks`,
        options: {
          method: "POST",
          body: payload,
        },
      });

      if (error) {
        console.error("Failed to create task:", error);
        return;
      }

      setNewTaskData({
        Task: "",
        Priority: "",
        "Task Type": "",
        "Patient Name": "",
        Patient: "",
        "Bed no": "",
        "Patient Severity": "",
        "Hospital-unit": "",
        "Created-At": "",
        "Due-Date": "",
        Assignee: "",
        AssigneeName: "",
        Creator: "",
      });
      setIsAssignee(true);
      setIsDueDate(true);
      onClose();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  useEffect(() => {
    socket.on('taskList-create', (data) => {
      dispatch(appendTask(data));
    });

    return () => {
      socket.off('taskList-create');
    };
  }, [dispatch, socket]);

  return (
    <div className='tw-fixed tw-top-[74%] tw-w-[33rem] tw-right-[6rem] tw-z-50 tw-p-[0.7rem] tw-bg-white tw-shadow-2xl' onKeyDown={handleKeyDown}>
      <div className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer tw-z-50" onClick={handleCloseModal}>
        <img src="assets/icons/close.svg" className='tw-w-4 tw-h-4' alt="CloseIcon" />
      </div>

      <div className='tw-mr-[2.8rem]'>
        <Tooltip title={validationTask.Task ? "Mandatory" : ""}>
          <input
            type="text"
            name="Task"
            className={`tw-w-[28rem] tw-mb-2 tw-p-1 tw-rounded tw-text-content-600 ${validationTask.Task ? "tw-border tw-border-red-400" : ""}`}
            placeholder="Add Task Details"
            value={newTaskData.Task}
            onChange={(e) => handleChange(e, "Task")}
          />
        </Tooltip>
      </div>

      <div className=' tw-pl-3'>
        {/* Priority Selector */}
        <div className="tw-flex tw-items-center tw-px-2">
          <strong className="tw-mr-[2.3rem] tw-text-content-600 tw-text-sm tw-py-2">
            Priority:
          </strong>
          {priorityTypes.map((priority) => (
            <span
              key={priority.value}
              onClick={() => {
                setNewTaskData((prevData) => ({
                  ...prevData,
                  Priority: priority.value,
                }));
                setValidationTask((prevValidation) => ({
                  ...prevValidation,
                  Priority: false,
                }));
              }}
              className={`tw-px-2 tw-text-[0.625rem] tw-border tw-mr-1 tw-cursor-pointer tw-rounded-sm tw-flex tw-text-center ${newTaskData.Priority === priority.value
                ? `${priority.value === "high"
                  ? "tw-bg-red-400 tw-border-red-400"
                  : "tw-bg-yellow-400 tw-border-yellow-400"
                } tw-text-white`
                : `${priority.value === "high"
                  ? "tw-text-red-400 tw-border-red-400"
                  : "tw-text-yellow-400 tw-border-yellow-400"
                }`
                }`}
            >
              {priority.label}
            </span>
          ))}
          {validationTask.Priority && (
            <span className="tw-text-red-400 tw-ml-1">Required*</span>
          )}
        </div>

        {/* Task Type Input */}
        <div className="tw-flex tw-items-center tw-pl-2 tw-gap-2">
          <strong className="tw-mr-3 tw-text-content-600 tw-text-sm tw-py-2">Task Type:</strong>
          {taskTypes.map((task) => (
            <span
              key={task.value}
              onClick={() => {
                setNewTaskData((prevData) => ({
                  ...prevData,
                  "Task Type": task.value,
                }));
                setValidationTask((prevValidation) => ({
                  ...prevValidation,
                  "Task Type": false,
                }));
              }}
              className={`tw-text-fades-600 tw-text-sm tw-font-medium tw-border tw-border-fades-400 tw-px-2 tw-pt-0.5 tw-rounded-sm tw-cursor-pointer ${newTaskData["Task Type"] === task.value
                ? "tw-bg-primary-100 tw-text-primary-400 tw-border tw-border-primary-400"
                : ""
                }`}
            >
              {task.label}
            </span>
          ))}
          {validationTask["Task Type"] && (
            <span className="tw-text-red-400 tw-ml-1">Required*</span>
          )}
        </div>

        {/* Assignee Selector */}
        <div className="tw-flex tw-items-center tw-pl-2 tw-gap-2">
          <strong className="tw-text-content-600 tw-text-sm tw-py-2 tw-mr-[0.1rem]">Assigned to:</strong>
          {isAssignee ? (
            <Tooltip title={validationTask.AssigneeName ? "Mandatory" : ""}>
              <SearchDropdown
                names={users}
                uniqueKey="assigneeNames"
                handleNameSelect={handleTaskAssigneeSelect}
                placeholder="Select Assignee"
                className={`tw-w-[7.7rem] tw-border ${validationTask.AssigneeName ? "tw-border-red-400" : "tw-border-fades-400"}`}
              />
            </Tooltip>
          ) : (
            <span
              onClick={() => setIsAssignee(true)}
              className="tw-cursor-pointer"
            >
              {newTaskData.AssigneeName}
            </span>
          )}
        </div>

        {/* Due Date Input */}
        <div className="tw-text-fades-600 tw-flex tw-items-center tw-gap-2 tw-pl-2 tw-mt-1 tw-mb-1">
          <strong className="tw-text-sm tw-mr-[10px] tw-text-content-600">Due Date:</strong>
          {isDueDate ? (
            <Tooltip title={validationTask["Due-Date"] ? "Mandatory" : ""}>
              <input
                type="date"
                name="Due-Date"
                value={newTaskData["Due-Date"]}
                onChange={(e) => {
                  setNewTaskData((prevData) => ({
                    ...prevData,
                    "Due-Date": e.target.value,
                  }));
                  setValidationTask((prevValidation) => ({
                    ...prevValidation,
                    "Due-Date": false,
                  }));
                  setIsDueDate(false);
                }}
                className={`tw-ml-2 tw-border tw-border-fades-400 tw-px-2 tw-py-1 tw-rounded ${validationTask["Due-Date"] ? "tw-border-red-400" : "tw-border-fades-400"}`}
              />
            </Tooltip>
          ) : (
            <span onClick={() => setIsDueDate(true)}>{newTaskData["Due-Date"]}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTaskPopUp;
