import Quill from "quill";

export type QuillType = Quill | undefined;

export interface OnSelectionChangeInterface {
  editor: Quill;
  oldRange: RangeInterface;
  range: RangeInterface;
  source: string;
}

export interface RangeInterface {
  index: number;
  length: number;
}
export interface AutocompleteDataInterface {
  offsetTop: number;
  offsetLeft: number;
  top: number;
  left: number;
  height: number;
  clientX: number;
  clientY: number;
  sug: string;
  index: number;
}

export interface MacroInterface {
  _id?: string;
  accessType?: string;
  key: string;
  value: string;
}
