import { createAction, props } from "@ngrx/store";
import {
  CreateMacroPayloadInterface,
  CreateMacroPayloadType,
} from "@notes/models/notes.model";
import { MacroInterface } from "@shared-modules/components/quill-editor";

export const LOAD_NOTE_MACROS = "[NOTE-INPUT-FORM-DATA] load note macros";
export const LOAD_NOTE_MACROS_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] load note macros success";
export const LOAD_NOTE_MACROS_ERROR =
  "[NOTE-INPUT-FORM-DATA] load note macros error";

export const CREATE_MACROS = "[NOTE-INPUT-FORM-DATA] create note macros";
export const CREATE_MACROS_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] create note macros success";
export const CREATE_MACROS_ERROR =
  "[NOTE-INPUT-FORM-DATA] create note macros error";

export const UPDATE_MACROS = "[NOTE-INPUT-FORM-DATA] udpate note macros";
export const UPDATE_MACROS_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] udpate note macros success";
export const UPDATE_MACROS_ERROR =
  "[NOTE-INPUT-FORM-DATA] udpate note macros error";

export const DELETE_MACROS = "[NOTE-INPUT-FORM-DATA] delete note macros";
export const DELETE_MACROS_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] delete note macros success";
export const DELETE_MACROS_ERROR =
  "[NOTE-INPUT-FORM-DATA] delete note macros error";

export const loadNoteMacros = createAction(LOAD_NOTE_MACROS);
export const loadNoteMacrosSuccess = createAction(
  LOAD_NOTE_MACROS_SUCCESS,
  props<{ macros: MacroInterface[] }>()
);
export const loadNoteMacrosError = createAction(
  LOAD_NOTE_MACROS_ERROR,
  props<{ error: string }>()
);

export const createNoteMacros = createAction(
  CREATE_MACROS,
  props<{ payload: CreateMacroPayloadType }>()
);
export const createNoteMacrosSuccess = createAction(
  CREATE_MACROS_SUCCESS,
  props<{ macro: MacroInterface }>()
);
export const createNoteMacrosError = createAction(
  CREATE_MACROS_ERROR,
  props<{ error: string }>()
);

export const updateNoteMacros = createAction(
  UPDATE_MACROS,
  props<{ template: string; id: string }>()
);
export const updateNoteMacrosSuccess = createAction(
  UPDATE_MACROS_SUCCESS,
  props<{ macro: MacroInterface }>()
);
export const updateNoteMacrosError = createAction(
  UPDATE_MACROS_ERROR,
  props<{ error: string }>()
);

export const deleteNoteMacros = createAction(
  DELETE_MACROS,
  props<{ id: string }>()
);
export const deleteNoteMacrosSuccess = createAction(
  DELETE_MACROS_SUCCESS,
  props<{ id: string }>()
);
export const deleteNoteMacrosError = createAction(
  DELETE_MACROS_ERROR,
  props<{ error: string }>()
);
