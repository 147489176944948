import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AutocompleteDataInterface,
  MacroInterface,
} from "../../models/quill-editor.model";
import { autocompleteInitialValue, maxMacros } from "../../utils";
import { AutocompleteService } from "../../services/autocomplete.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-autocomplete-selection",
  templateUrl: "./autocomplete-selection.component.html",
  styleUrls: ["./autocomplete-selection.component.scss"],
})
export class AutocompleteSelectionComponent implements OnChanges, OnInit {
  @Input("data") data: AutocompleteDataInterface = autocompleteInitialValue;

  @Output() setTemplate = new EventEmitter<MacroInterface>();

  @Input() macros: MacroInterface[] = [];

  unsubscribe$ = new Subject();

  _textEditor: HTMLElement;
  overlayRef: any;
  get textEditor(): HTMLElement {
    return this._textEditor;
  }
  @Input() set textEditor(value: HTMLElement) {
    this._textEditor = value;
  }

  public listItemHeight = 20;
  filteredList: MacroInterface[] = [];
  public get popupHeight() {
    return Math.min(this.macros.length, maxMacros) * this.listItemHeight;
  }

  public selection: number = 0;

  public popupWidth = 200;

  public top: string = "0px";
  public left: string = "0px";

  constructor(private _autocompleteService: AutocompleteService) {}
  ngOnInit(): void {
    this.initializeListeners();
  }

  initializeListeners() {
    this._autocompleteService.resetAutocomplete$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.resetFilteredList();
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.setPopup();
    }
  }

  setPopup() {
    const { clientX, clientY, offsetTop, offsetLeft, height, top, left } =
      this.data;

    const isHeightOut =
      clientY + offsetTop + top + height + this.popupHeight >
      window.innerHeight;
    const isWidthOut = clientX + left + this.popupWidth > window.innerWidth;

    let topPos = offsetTop + top + height;
    let leftPos = offsetLeft + left;

    if (isHeightOut) {
      topPos = top + offsetTop - this.popupHeight;
    }
    if (isWidthOut) {
      leftPos = offsetLeft + left - this.popupWidth;
    }

    this.filteredList = this._autocompleteService.filterAutocompleteSelection(
      this.macros,
      this.data.sug
    );
    if (!this.filteredList.length) {
      this.selection = 0;
    }
    const rect = this.textEditor.getBoundingClientRect();
    this.top = `${rect.top + topPos}px`;
    this.left = `${rect.left + leftPos}px`;
  }

  public resetFilteredList() {
    this.filteredList = [];
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    const { key } = event;
    if (!this.filteredList?.length) return;
    if (key === "ArrowUp" || key === "ArrowDown") {
      event.preventDefault();
    }
    if (key === "ArrowUp") {
      if (this.selection <= 0) return;
      this.selection--;
    }
    if (key === "ArrowDown") {
      if (this.selection >= this.filteredList.length - 1) return;
      this.selection++;
    }
    if (key === "Tab") {
      this.emitTemplate(this.filteredList[this.selection]);
    }
    if (key === "Enter") {
      this.resetFilteredList();
    }
  }

  public emitTemplate(macro: MacroInterface) {
    this.setTemplate.emit(macro);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.resetFilteredList();
  }
}
