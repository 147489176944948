import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  MacroAPIResponseInterface,
  CreateMacroPayloadType,
  NoteForm,
  NoteForms,
  NoteFormsDataModel,
  NotePermissions,
  NoteTypeCriteria,
} from "@notes/models/notes.model";
import { Observable } from "rxjs";
import { map, catchError, take, switchMap } from "rxjs/operators";
import { CoreHttpService } from "src/app/services/base-service/core-http.service";
import { User } from "src/app/models/user";
import * as fromUserReducer from "src/app/store/reducers/user/index";
import { Store, select } from "@ngrx/store";
import { FormBuilder } from "@angular/forms";
import { CustomRouterState } from "src/app/store/router/custom-serialize";
import { DraftNotes, FinalNotes } from "@notes-view/model/notes-model";
import * as fromNoteStore from "@notes/store";
import { ApiResponse } from "src/app/models/api-response.model";
import store from "src/app/react/reactStore/reduxStore";
import { MacroInterface } from "@shared-modules/components/quill-editor";
@Injectable({
  providedIn: "root",
})
export class NoteService extends CoreHttpService {
  public user: User;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  author: string;
  constructor(
    private http: HttpClient,
    private store: Store<{}>,
    private _fb: FormBuilder,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    _routeStore: Store<CustomRouterState>
  ) {
    super(_routeStore);

    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
        let author = "";

        if (data.title) {
          author += data.title + " ";
        }

        author += data.name;

        if (data["qualification"]) {
          author += " " + data["qualification"];
        }
        this.author = author;
      }
    });
  }

  public noteCTA$ = this._noteStore.pipe(
    select(fromNoteStore.getCTA),
    map((data) => data.cta)
  );

  getNoteTypes(notetypeCriteria: NoteTypeCriteria): Observable<{
    noteTypes: NoteFormsDataModel[];
    permissions: NotePermissions;
  }> {
    return this.http
      .post<
        ApiResponse<{
          templates: NoteFormsDataModel[];
          permissions: NotePermissions;
        }>
      >(`${this.env.apiUrl}notes/templates`, notetypeCriteria)
      .pipe(
        map((response) => ({
          noteTypes: response.data.templates, // Map `templates` to `noteTypes`
          permissions: response.data.permissions, // Directly assign `permissions`
        })),
        catchError(this.errorHandler)
      );
  }

  getNoteTemplates(noteTypeForms: NoteForms): Observable<FinalNotes> {
    return this.http
      .post<ApiResponse<FinalNotes>>(
        `${this.env.apiUrl}notes/templates`,
        noteTypeForms
      )
      .pipe(
        map((response: ApiResponse<FinalNotes>) => response.data["templates"]), // this will be removed after server side fixes
        catchError(this.errorHandler)
      );
  }

  updateDraftNote(note: NoteForm): Observable<DraftNotes> {
    const { patientCPMRN, patientEncounters } = this.params;
    const icdCodes = store.getState().icdCodes.icdCodes;
    if (icdCodes) {
      note["icdCodes"] = icdCodes;
    }
    return this.noteCTA$.pipe(
      take(1),
      switchMap((cta) =>
        this.http.put<{ data: DraftNotes; message: string; status: string }>(
          `${this.env.apiUrl}notes/notes/updatedraft/${patientCPMRN}/${patientEncounters}`,
          { note: { content: { ...note, noteAction: cta } } }
        )
      ),
      map(({ data }) => data),
      catchError(this.errorHandler)
    );
  }

  getCosignIntensivist(
    commandCenterID: string,
    hospitalID: string,
    authorName: string
  ): Observable<any> {
    return this.http
      .get<ApiResponse>(
        `${this.env.apiUrl}notes/cosignintesivist/${commandCenterID}/${hospitalID}/${authorName}`,
        { observe: "response" }
      )
      .pipe(catchError(this.errorHandler));
  }

  deleteDraft(refId: string): Observable<string> {
    const { patientCPMRN: CPMRN, patientEncounters: encounters } = this.params;
    return this.http
      .put<{ data: { refId: string }; message: string; status: string }>(
        `${this.env.apiUrl}notes/deletedraft/`,
        {
          CPMRN,
          encounters,
          refId,
        }
      )
      .pipe(
        map(({ data }) => data?.refId),
        catchError(this.errorHandler)
      );
  }

  createNote(note: FinalNotes): Observable<FinalNotes> {
    const { patientCPMRN, patientEncounters } = this.params;
    return this.http
      .post<{ data: FinalNotes; message: string; status: string }>(
        `${this.env.apiUrl}notes/${patientCPMRN}/${patientEncounters}`,
        { note: { content: note } }
      )
      .pipe(
        map(({ data }) => data),
        catchError(this.errorHandler)
      );
  }

  updateNote(note: FinalNotes): Observable<FinalNotes> {
    const { patientCPMRN, patientEncounters } = this.params;
    return this.http
      .put<{ data: FinalNotes; message: string; status: string }>(
        `${this.env.apiUrl}notes/${patientCPMRN}/${patientEncounters}/${note.refId}`,
        { note: { content: note } }
      )
      .pipe(
        map(({ data }) => data),
        catchError(this.errorHandler)
      );
  }

  deletePendedNote(refId: string): Observable<any> {
    const { patientCPMRN, patientEncounters } = this.params;
    return this.http
      .put<{ data: { refId: string }; message: string; status: string }>(
        `${this.env.apiUrl}notes/deletepended/`,
        {
          patientCPMRN,
          patientEncounters,
          refId,
        }
      )
      .pipe(
        map(({ data }) => data.refId),
        catchError(this.errorHandler)
      );
  }

  attestNote(note: FinalNotes): Observable<any> {
    const { patientCPMRN: CPMRN, patientEncounters: encounters } = this.params;
    return this.http
      .put<{ data: FinalNotes; message: string; status: string }>(
        `${this.env.apiUrl}notes/attestNote/`,
        { ...note, CPMRN, encounters }
      )
      .pipe(
        map(({ data }) => data),
        catchError(this.errorHandler)
      );
  }

  getMacros(): Observable<MacroInterface[]> {
    return this.http
      .get<ApiResponse<MacroInterface[]>>(`${this.env.apiUrl}macros`)
      .pipe(
        map((res) => res.data),
        catchError(this.errorHandler)
      );
  }

  createMacros(payload: CreateMacroPayloadType): Observable<MacroInterface> {
    return this.http
      .post<ApiResponse<MacroAPIResponseInterface>>(
        `${this.env.apiUrl}macros`,
        payload
      )
      .pipe(
        map((res) => res.data),
        catchError(this.errorHandler)
      );
  }

  updateMacro(value: string, id: string): Observable<MacroInterface> {
    return this.http
      .put<ApiResponse<MacroInterface>>(`${this.env.apiUrl}macros/${id}`, {
        value,
      })
      .pipe(
        map((res) => res.data),
        catchError(this.errorHandler)
      );
  }

  deleteMacro(id: string): Observable<{ msg: string }> {
    return this.http
      .delete<{ msg: string }>(`${this.env.apiUrl}macros/${id}`)
      .pipe(catchError(this.errorHandler));
  }
}
